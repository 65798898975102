import axios from 'axios';
import config from '../config';
// import moment from 'moment';
import moment from '../config/momentConfig';
import Parse from 'parse';
import User from './User';
import { log } from './Log';

var DEFAULT_CURRENCY = 'ILS';
var SALE_CALLBACK_ENDPOINT = config.SERVER_URL + '/api/payment-request/success';
var SALE_RETURN_ENDPOINT = config.APP_URL + '/#/pages/payment-request/success';
var DEFAULT_LANGUAGE = 'en';

var PAYME_POST_URL = config.PAYME_BASE_URL + 'generate-sale';
var PAYME_POST_SUB_URL = config.PAYME_BASE_URL + 'generate-subscription';

const PRODUCT_TYPES = {
  FREE_AMOUNT: 0,
  SESSION: 1,
  TICKET: 2,
  MEMBERSHIP: 3,
  PRODUCT: 4
}

var PaymentRequester = class PaymentRequester {
  constructor(props) {
    log("PaymentRequester constructor props", props);
    this.props = props;
  }

  getPrice(callback) {
    var { session, ticket, membership, product, isMedidateMemberhip, price, coupon } = this.props;
    log('membership price - ', price);

    // var price;
    if (!price) {
      if (session) {
        price = session.get('session_price');
      } else if (ticket) {
        price = ticket.get('price');
      } else if (membership) {
        price = membership.get('price');
      } else if (product) {
        price = product.get('price');
      }
    }

    if (isMedidateMemberhip) {
      log('total price', price);
      callback(price);
    } else {
      this.getTotalPriceIncludingCommission(price, (err, res) => {
        if (err) {
          callback(err);
          return;
        }
        callback(res);
      });
    }
  }

  getTotalPriceIncludingCommission(price, callback) {
    Parse.Cloud.run('getTotalPriceIncludingCommission', {
      price,
      teacherId: Parse.User.current().id
    }, {
      success: function (res) {
        log('success getTotalPriceIncludingCommission', res);
        callback(null, res);
      },
      error: function (error) {
        log(error);
        callback(error);
      }
    })
  }

  getCurrency() {
    var { session, ticket, membership, product, isMedidateMemberhip } = this.props;
    if (session) {
      var currency = session.get('currency');
    }

    if (ticket) {
      var currency = ticket.get('currency');
    }

    if (membership) {
      var currency = membership.get('currency');
    }

    if (product) {
      var currency = product.get('currency');
    }

    return currency || DEFAULT_CURRENCY;
  }

  getProductName() {
    var { session, ticket, membership, product } = this.props;
    if (session) {
      var name = session.get('title');
    }

    if (ticket) {
      var name = ticket.get('title');
    }

    if (membership) {
      var name = membership.get('title');
    }

    if (product) {
      var name = product.get('title');
    }

    return name || DEFAULT_CURRENCY;
  }

  getSaleUrl(urlType) {
    log("isMedidateMemberhip", isMedidateMemberhip);
    var { session, ticket, membership, product, isMedidateMemberhip, seller, buyer, userPaymentPlan, userPaymentProduct, coupon } = this.props;
    var base = (urlType === 'callback' ? SALE_CALLBACK_ENDPOINT : SALE_RETURN_ENDPOINT) + '?sellerId=' + seller.id + '&buyerId=' + buyer.id;

    if (session) {
      return base + '&sessionId=' + session.id;
    }

    if (ticket) {
      return base + '&userPaymentPlanId=' + userPaymentPlan.id + '&ticketId=' + ticket.id;
    }

    if (membership && !isMedidateMemberhip) {
      if (userPaymentPlan) {
        return base + '&userPaymentPlanId=' + userPaymentPlan.id + '&membershipId=' + membership.id + '&isMedidateMemberhip=false';
      } else {
        return base + '&membershipId=' + membership.id + '&isMedidateMemberhip=false';
      }
    }

    if (membership && isMedidateMemberhip) {
      if (coupon) {
        return base + '&membershipId=' + membership.id + '&isMedidateMemberhip=true' + '&couponId=' + coupon.id;
      } else {
        return base + '&membershipId=' + membership.id + '&isMedidateMemberhip=true';
      }
    }

    if (product) {
      return base + '&userPaymentProductId=' + userPaymentProduct.id + '&productId=' + product.id;
    }
  }

  getParams(callback) {
    this.getPrice((price) => {
      var userPreferences = this.props.seller.get('preferences');
      log('market_fee', userPreferences.get("merchant_fee_percent"));
      log('userPreferences', userPreferences);

      try {
        callback({
          "seller_payme_id": this.props.seller.get('payme_seller_id') || Parse.User.current().get('payme_seller_id'),
          "sale_price": price * 100, // normalize it to payme
          "currency": this.getCurrency(),
          "product_name": this.getProductName(),
          "sale_callback_url": this.getSaleUrl('callback'),
          "sale_return_url": this.getSaleUrl(),
          "market_fee": userPreferences.get("merchant_fee_percent") ? userPreferences.get("merchant_fee_percent") : config.MERCHANT_FEE_PERC,
          "discount": this.props.discount,
          "couponId": this.props.coupon ? this.props.coupon.id : undefined,
          "sale_send_notification": true,
          "sale_email": this.props.buyer.get('username'), // username is email at medidate
          "sale_mobile": this.props.note.get('phone_number') ? this.props.note.get('phone_number') : this.props.buyer.get('phone_number'), // username is email at medidate
          "sale_name": this.props.buyer.get('first_name') + ' ' + this.props.buyer.get('last_name') || '',
          "language": this.props.locale || User.getPreferences().language || DEFAULT_LANGUAGE
        });
      } catch (error) {
        log('getParams Error', error);
      }

    })
  }

  getProductType(product) {
    var { session, ticket, membership, product } = this.props;
    var priceKey = session ? 'session_price' : 'price';

    if (session) {
      return session[priceKey] === 0 ? PRODUCT_TYPES.FREE_AMOUNT : PRODUCT_TYPES.SESSION;
    }

    if (ticket) {
      return ticket[priceKey] === 0 ? PRODUCT_TYPES.FREE_AMOUNT : PRODUCT_TYPES.TICKET;
    }

    if (membership) {
      return membership[priceKey] === 0 ? PRODUCT_TYPES.FREE_AMOUNT : PRODUCT_TYPES.MEMBERSHIP;
    }

    if (product) {
      return product[priceKey] === 0 ? PRODUCT_TYPES.FREE_AMOUNT : PRODUCT_TYPES.PRODUCT;
    }
  }

  createPaymentRequestInDB() {
    var { session, ticket, membership, product, seller, buyer, discount } = this.props;

    var getProductId = function () {
      if (session) {
        return session.id;
      }

      if (ticket) {
        return ticket.id;
      }

      if (membership) {
        return membership.id;
      }

      if (product) {
        return product.id;
      }
    }

    Parse.Cloud.run('createPaymentRequest', {
      productType: this.getProductType(),
      productObjectId: getProductId(),
      sellerObjectId: seller.id,
      buyerObjectId: buyer.id,
      discount
    }, {
      success: function (res) {
        log('success creating pr', res);
      },
      error: function (error) {
        log(error);
      }
    })
  }

  generatePaymentRequest(callback) {
    log('generatePaymentRequest');
    var params = this.getProductParams();
    log('generatePaymentRequest params', params);
    Parse.Cloud.run('purchaseProduct', params, {
      success: response => {
        log(response)
        this.createPaymentRequestInDB();
        callback(null, response);
      },
      error: error => {
        log('purchaseProduct error', error);
        callback(error);
      }
    });
  }

  refundProduct(callback) {
    log('refundProduct');
    var params = this.getProductParams();
    log('refundProduct params', params);
    Parse.Cloud.run('refundProduct', params, {
      success: response => {
        log(response)
        callback(null, response);
      },
      error: error => {
        log('refundProduct error', error);
        callback(error);
      }
    });
  }

  getProductParams() {
    log('getProductParams');
    var params = { sendAsPaymentRequest: true, studentId: this.props.buyer.id, discount: this.props.discount, yogaAssociation: this.props.yogaAssociation };

    if (this.props.locale || User.getPreferences().language) {
      params["user_locale"] = this.props.locale || User.getPreferences().language
    }

    if (this.props.session) {
      return { ...params, productId: this.props.session.id, productType: 1 };
    }

    if (this.props.ticket) {

      return { ...params, productId: this.props.ticket.id, productType: 2, userPaymentPId: this.props.userPaymentPlan.id };
    }

    if (this.props.membership) {
      if (this.props.isMedidateMemberhip) {
        return { ...params, productId: this.props.membership.id, productType: 3, isMedidateMemberhip: this.props.isMedidateMemberhip,
           membershipPaymentType: 2, couponId: this.props.coupon ? this.props.coupon.id : undefined };
      } else {
        return {
          ...params,
          productId: this.props.membership.id,
          productType: 3,
          userPaymentPId: this.props.userPaymentPlan ? this.props.userPaymentPlan.id : undefined,
          ...(this.props.membershipPaymentType === 1 && {
            membershipIteration: this.props.membershipIteration,
          }),
          membershipPaymentType: this.props.membershipPaymentType,
        };
      }

    }

    if (this.props.product) {
      return { ...params, productId: this.props.product.id, productType: 4, userPaymentPId: this.props.userPaymentProduct.id };
    }
  }

  sendPaymentRequest(callback) {
    this.getParams((params) => {
      log('URL', PAYME_POST_URL);
      log('params', params);
      axios.post(PAYME_POST_URL, params)
        .then(response => {
          log(response)
          this.createPaymentRequestInDB();
          callback(null, response);
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            log(error.response.data);
            log(error.response.status);
            log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            log('Error', error.message);
          }
          log(error.config);
          callback(error);
        });
    })
  }

  // "sub_start_date": moment().format('DD/MM/YYYY'),
  getSubscriptionParams(callback) {
    log("getSubscriptionParams");
    // "market_fee": userPreferences.get("merchant_fee_percent") ? userPreferences.get("merchant_fee_percent") : config.MERCHANT_FEE_PERC,
    this.getPrice((price) => {
      var userPreferences = this.props.seller.get('preferences');
      log('market_fee', userPreferences.get("merchant_fee_percent"));
      var isInMonths = this.props.membership.get("expiration_type") == 1;
      var params = {
        "seller_payme_id": this.props.seller.get('payme_seller_id') || Parse.User.current().get('payme_seller_id'),
        "sub_price": price * 100, // normalize it to payme
        "sub_currency": this.getCurrency(),
        "sub_iterations": parseInt(this.props.membership.get('expiration_period') / (isInMonths ? 1 : 4)),// change from weeks to months
        "sub_iteration_type": 3, // monthly
        "sub_description": this.getProductName(),
        "sub_start_date": this.getSubscriptionStartDate(),

        "sub_callback_url": this.getSaleUrl('callback'),
        "sub_return_url": this.getSaleUrl(),

        "sub_send_notification": !this.props.membership.get('is_medidate_membership'),
        "sub_email_address": this.props.buyer.get('username'), // username is email at medidate,
        "sub_indicative_name": this.props.buyer.get('first_name').replace(/[0-9]/g, '') + ' ' + this.props.buyer.get('last_name').replace(/[0-9]/g, '') || '',
        "language": this.props.locale || User.getPreferences().language || DEFAULT_LANGUAGE
      }
      log(params);
      callback(params)
    });
  }

  getSubscriptionStartDate() {
    log('getSubscriptionStartDate');
    var now = moment();
    var day = parseInt(now.format('D'));
    log('Now day', day);
    if (day > 28) {
      now.add(1, 'months').startOf('month');
      log('sub_start_date', now.format('DD/MM/YYYY'));
      return now.format('DD/MM/YYYY');
    } else {
      log('sub_start_date', now.format('DD/MM/YYYY'));
      return now.format('DD/MM/YYYY');
    }
  }

  sendSubscriptionRequest(callback) {
    log("sendSubscriptionRequest");
    log("request url", PAYME_POST_SUB_URL);
    const querystring = require('querystring');

    this.getSubscriptionParams((params) => {
      log("request params", params);
      axios.post(PAYME_POST_SUB_URL, params)
        .then(response => {
          log(response)
          callback(undefined, response)
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            log(error.response.data);
            log(error.response.status);
            log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            log(error.request.data);
          } else {
            // Something happened in setting up the request that triggered an Error
            log('Error', error.message);
          }
          log(error.config);
        });
    })
  }
}


export default PaymentRequester;
