export default {
  errors: {
    LOGIN_MISSING_FIELDS: 'אנא בדקו את שם המשתמש והסיסמא שהוכנסו',
    MOBILE_PORTRAIT_ERROR: 'השימוש במדידייט במצב לנדסקייפ (לרוחב) הינו משמעותית טוב יותר',
    OPERATION_TOO_LONG: "פעולה ארוכה מידי. אנא נסו שנית עם טווח תאריכים קרוב יותר",
    "TITLE_REQUIRED": "נדרשת כותרת",
    "COUPON_CODE_REQUIRED": "נדרש קוד קופון",
    "INVALID_DISCOUNT_PERCENTAGE": "אחוז ההנחה חייב להיות בין 0 ל-100",
    "START_DATE_REQUIRED": "נדרש תאריך התחלה",
    "EXPIRATION_DATE_AFTER_START_DATE": "תאריך התפוגה חייב להיות אחרי תאריך ההתחלה",
    "COUPON_TYPE_REQUIRED": "נדרש סוג קופון",
    "INVALID_USAGE_LIMIT": "מגבלת שימוש חייבת להיות מספר שלם ולא שלילי",
    "INVALID_USER_USAGE_LIMIT": "מגבלת שימוש לפי משתמש חייבת להיות מספר שלם ולא שלילי",
    "INVALID_USER_USAGE_LIMIT_GREATER_THAN_USAGE_LIMIT": "מגבלת שימוש לפי משתמש חייבת להיות קטנה או שווה למגבלת השימוש"
  },
  dateStaticRanges: {
    TODAY: 'היום',
    YESTERDAY: 'אתמול',
    THIS_WEEK: 'השבוע',
    LAST_WEEK: 'שבעו שעבר',
    THIS_MONTH: 'החודש',
    LAST_MONTH: 'חודש שעבר'
  },
  subscription: {
    ACTIVE_SUB_MAIN_TITLE: 'מנוי נוכחי: <b>%{title}</b>',
    LABEL_SUBSCRIPTION_TYPE: 'סוג מנוי: <b>%{sub_type}</b>',
    LABEL_SUBSCRIPTION_CREATED: 'נוצר ב: <b>%{sub_created}</b>',
    LABEL_SUBSCRIPTION_EXPIRATION: 'תקף עד: <b>%{sub_expiration}</b>',
    LABEL_SUBSCRIPTION_RENEWS: 'מתחדש ב: <b>%{sub_expiration}</b>',
    LABEL_SUBSCRIPTION_PRICE: 'מחיר: <b>%{sub_price}</b>',
    LABEL_SUBSCRIPTION_MONTHLY_PRICE: 'מחיר: <b>%{sub_price}</b> לחודש',
    LABEL_SUBSCRIPTION_YEARLY_PRICE: 'מחיר: <b>%{sub_price}</b> לשנה',
    LABEL_SUBSCRIPTION_TRIAL_LEFT: '%{days} ימים נשארו',
    SUB_MAIN_TITLE: 'המנוי שלך במדידייט נגמר',
    SUB_TITLE1: 'רכישת מנוי למדידייט',
    SUB_TITLE2: 'רכישת המנוי למדידייט בוצעה בהצלחה',
    SUB_TITLE: 'רכישת מנוי למדידייט',
    SUB_SUBTITLE: 'שלום %{firstName},',
    TRIAL_SUB_DESCRIPTION1: 'תקופת הנסיון שלך במדידייט הסתיימה.',
    TRIAL_SUB_DESCRIPTION2: 'רכשו מנוי חדש בכדי ליהנות מכל היכולות והאפשרויות של מדידייט',
    MEMBERSHIP_SUB_DESCRIPTION1: 'המנוי שלך  &nbsp<b>"%{oldTitle}"</b>&nbsp במדידייט נגמר',
    MEMBERSHIP_SUB_DESCRIPTION2: 'רכשו מנוי חדש בכדי ליהנות מכל היכולות והאפשרויות של מדידייט',
    MEMBERSHIP_SUB_DESCRIPTION3: 'המנוי שלך במדידייט "%{oldTitle}" עומד להגמר',
    MEMBERSHIP_SUB_DESCRIPTION4: 'ניתן לרכוש מנוי חדש כבר עכשיו',
    MEMBERSHIP_TITLE1: 'רכשו %{newTitle1}',
    MEMBERSHIP_TITLE2: 'רכשו %{newTitle2}',
    MEMBERSHIP_DESCRIPTION1: 'על התכנית - %{newTitle1} תחויבו על סך %{price1} לחודש למשך %{months1} חודשים',
    MEMBERSHIP_DESCRIPTION2: 'על התכנית - %{newTitle2} תחויבו על סך %{price2} לחודש למשך %{months2} חודשים',
    MONTHLY_PRICE1: 'מחיר חודשי: <b>%{price1}</b>',
    MONTHLY_PRICE2: 'מחיר חודשי: <b>%{price2}</b>',
    YEARLY_PRICE: 'מחיר שנתי: <del>%{price1_old}</del> <b>%{price1}</b>',
    YEARLY_PRICE2: 'מחיר שנתי: <b>%{price1}</b>',
    MONTHLY_PRICE_WITH_VAT: 'מחיר חודשי כולל מע״מ: <b>%{price}</b>',
    YEARLY_PRICE_WITH_VAT: 'מחיר שנתי כולל מע״מ: <del>%{price_old}</del> <b>%{price}</b>',
    YEARLY_PRICE2_WITH_VAT: 'מחיר שנתי כולל מע״מ: <b>%{price}</b>',
    MONTHLY_PERIOD: '<b>תשלום חודשי</b>',
    MONTHLY_PERIOD1: 'חודשים: <b>%{months1}</b>',
    MONTHLY_PERIOD2: 'חודשים: <b>%{months2}</b>',
    YEARLY_PERIOD: '<b>תשלום שנתי</b>',
    YEARLY: 'שנתי',
    HALF_YEAR: 'חצי-שנתי',
    MONTHLY_TITLE: '<b>מנוי חודשי</b>',
    YEARLY_TITLE: '<b>מנוי שנתי</b>',
    YEARLY_TITLE_REGULAR: 'מנוי שנתי',
    SUB_TYPE_TRIAL: 'נסיון',
    SUB_TYPE_MONTHLY: 'חודשי',
    SUB_TYPE_YEARLY: 'שנתי',
    EQUALS_YEARLY: 'משתווה למחיר שנתי על סך:',
    THANK_YOU: 'תודה רבה!',
    TRIAL_LEFT: 'נשארו לתקופת הנסיון שלך %{days} ימים, לחצו כאן בכדי לרכוש מנוי חדש',
    MEMBERSHIP_LEFT: 'נשארו למנוי שלך %{days} ימים, לחצו כאן בכדי לרכוש מנוי חדש',
    SUBSCRIBED_SUCCESS: 'תודה שנרשמת %{firstName}!',
    SUBSCRIBED_SIDEBAR: 'מנוי למדידייט פעיל',
    DISCOUNTED: 'הנחה: %{discount_percent}',
    AFFILIATES_DISCOUNTED: 'הטבת אפיליאציה (מורה מביא מורה): חודש 1 נוסף חינם',
    SPECIAL_DISCOUNTED: 'הנחה לחברי ארגון מורי היוגה: %{discount_percent}',
    ADDITIONAL_DISCOUNTED: 'הנחה נוספת על ידי קוד קופון: %{discount_percent}',
    FAVOURITE_DISCOUNTED: 'מחיר מיוחד לאדם מיוחד :)',
    CANCELLABLE: 'ניתן לביטול',
    CANCEL_RENEWAL: 'ביטול חידוש מנוי אוטומטי',
    SUBSCRIPTION_YEARLY_SUGGEST: 'תשלום שנתי, חסכו %{discount}%',
    SUBSCRIPTION_RENEWS: '*כל התכניות יתחדשו אוטומטית, ניתן לבטל את החידוש בקלות בעמוד הגדרות בכל עת.'
  },
  common: {
    MONTHS: 'חודשים',
    WEEKS: 'שבועות',
    YES: 'כן',
    NO: 'לא',
    CANCEL: 'ביטול',
    CANCELED: 'השיעור בוטל',
    CLOSE: 'סגירה',
    TODAY: 'היום',
    THIS_WEEK: 'השבוע',
    PREVIOUS: 'הקודם',
    NEXT: 'הבא',
    FINISH: 'סיים',
    ACCEPT: 'אני מאשר/ת את תקנון של הסטודיו',
    ACCEPT_2: 'אני מאשר/ת את התנאים וההסכם',
    MARK: 'סמן',
    UPDATE: 'עדכון',
    OR: 'או',
    FROM: 'מתאריך',
    TO: 'עד',
    PLEASE_SELECT: 'בחר',
    SHARE_PROFILE: 'שתפ/י פרופיל',
    RESEND_INVITE: 'שלח/י הזמנה מחדש',
    RESEND_FORM: 'שלח/י טופס שוב',
    RESEND_TERMS: 'שלח/י תקנון מחדש',
    OPEN_TERMS: 'תקנון אושר',
    RESENDED_FORM: 'טופס נשלח מחדש',
    FORM_SENT: 'טופס נשלח',
    OF_USER: 'אצל %{teacherName}',
    BY: '<font size="0.5">%{teacherName}</font>',
    TOTAL: '<b>סה"כ</b>',
    TOTAL_REGULAR: 'סה"כ',
    NEVER: 'אף פעם',
    CHECK_ALL: 'בחר/י הכל',
    DOWNLOAD: 'הורדה',
    RECEIVED_BY: '<b>התקבל על ידי</b>',
    RECEIVED_BY_TOOLTIP: 'הגורם, סטודיו או המורה שהעבירו את השיעור, שגבו את הסכום על אותו מוצר (שיעור/כרטיסיה/מנוי וכו\׳)',
    EXPAND: 'הרחב',
    MINIMIZE: 'צמצם',
    INSTALLMENTS: 'תשלומים',
    INSTALLMENTS_TOOLTIP: 'רלוונטי לאשראי בלבד - אופציה זו מאפשרת לתלמיד/ה לבחור בין תשלום רגיל למספר תשלומים שהסטודיו מגדיר על מוצר/שיעור/סדנה זה',
    REGULAR_PAYMENT: 'רגיל',
    UP_TO_3_PAYMENT: 'עד 3 תשלומים',
    UP_TO_6_PAYMENT: 'עד 6 תשלומים',
    UP_TO_9_PAYMENT: 'עד 9 תשלומים',
    UP_TO_12_PAYMENT: 'עד 12 תשלומים',
    PLEASE_SAVE_FIRST: 'ישנם שינויים שעוד לא נשמרו, אנא שמרו את השינויים ואז תוכלו לעשות את הפעולה הזו',
    CREATED_AT: 'נוצר/ה בתראיך',
    UPDATED_ON: 'עודכנ/ה בתאריך',
    ADDED_ON: 'הוספ/ה בתאריך',
    ADDED_BY: 'ע״י',
    MANUALLY_PUNCHED: 'נוקבה ידנית: (%{manually_punched})',
    FROZEN_ON_DATE: 'הוקפא/ה ב',
    UNFREEZE_DATE: 'יופשר ב',
    NOT_SET: 'לא צויין',
    GOT_IT: 'הבנתי! תודה',
    CLICK_HERE: 'לחצו כאן',
    DATE: 'תאריך',
    PURCHASE: 'רכישה',
    BUY: 'רכישה',
    PRICE: 'סכום ששולם',
    PAY_TO: '<h2>מוכר/ת: <b>%{name}</b></h2>',
    CONNECTED_AS: 'משתמש נוכחי: <b>%{name}</b>',
    CONFIRM_PAYMENT: 'אישור תשלום',
    DASHBOARD_TITLE: 'Medidate',
    DASHBOARD_DESCRIPTION: 'Medidate Studio Management Software helps teachers and studio owners to manage their students, payments, classes, subscriptions, accounting automations and more..',
    DASHBOARD_URL: 'https://www.medidatewith.me/',
    EXTERNAL_LINKS: 'קישורים לשימוש חיצוני',
    MEDIDATE_ADMIN_OPTIONS: 'פעולות אדמין מדידייט',
    MEDIDATE_ADMIN_CONNECT_GROW_USER_TO_MEDIDATE: 'חיבור משתמש קיים של גרואו למדידייט',
    SIGNIN: 'התחברות',
    CONTINUE: 'המשך',
    CONTINUE_NEW_WINDOW: 'המשך בחלון חדש',
    OPEN_NEW_WINDOW: 'פתיחה בטאב חדש',
    CONTACT: 'יצירת קשר',
    CONTACT_TIP: 'השאירו הודעה ונחזור אליכם.ן במהרה :)',
    PHONE: 'שיחת טלפון',
    WHATSAPP: 'ווצאפ',
    SEARCH_COUNTRY: 'חפש מדינה',
    BANK_CODE: "מספר בנק",
    BANK_BRANCH: "מספר סניף",
    BANK_ACCOUNT: "מספר חשבון",
    CONTACT_WHATSAPP: "יצירת קשר דרך ווצאפ",
    SORT: "מיון",
    FILTER: "סינון",
    ASCENDING: "סדר עולה",
    DESCENDING: "סדר יורד",
    SERIAL: "מ.ס:",
    COMING_SOON: "בקרוב",
    REFRESH: "רענון",
    FULL_NAME: "שם מלא",
    COMPANY_ID: "ח.פ/ת.ז",
    PHONE_NUMBER: "מספר טלפון",
    MEDIDATE_OBJECT_ID: 'objectId של המשתמש במדידייט',
    GROW_USER_ID: 'userId של המשתמש ב-Grow (משולם)',
    SAVE: "שמור",
    LOADING: "טוען...",
    SAVED_SUCCESSFULLY: "הקופון נשמר בהצלחה",
    ERROR_OCCURRED: "אירעה שגיאה. אנא נסה שוב.",
  },
  paymentCoupon: {
    COUPON: "קופון",
    TITLE: "שם הקופון",
    ACTIVATE_COUPON: "הפעל קופון",
    ACTIVATE_COUPON_TOOLTIP: "הפעל זאת כדי להחיל קופון על המוצר",
    COUPON_CODE: "קוד קופון",
    COUPON_CODE_TOOLTIP: "הזן את קוד הקופון כאן",
    COUPON_TYPE: "מוצר",
    COUPON_TYPE_TOOLTIP: "בחר את סוג המוצר שברצונך ליצור",
    FINAL_PRICE: "מחיר לאחר הנחה",
    FINAL_PRICE_TOOLTIP: "מחיר לאחר הנחה למשתמשי הקופון",
    DISCOUNT_PERCENTAGE: "אחוז הנחה",
    DISCOUNT_PERCENTAGE_TOOLTIP: "הזן את אחוז ההנחה עבור קופון זה",
    COUPON_ACTIVATED: "קופון פועל",
    COUPON_DEACTIVATED: "קופון כבוי",
    COUPON_PAUSED: "קופון מושהה",
    START_DATE: 'תאריך התחלה (אופציונלי)',
    START_DATE_TOOLTIP: 'בחרו תאריך התחלה לקופון במידה ותרצו שיהיה לו תאריך התחלה, הסירו את תאריך ההתחלה בכדי לכבות ולהדליק את הקופון ידנית.',
    EXPIRATION_DATE: 'תאריך תפוגה (אופציונלי)',
    EXPIRATION_DATE_TOOLTIP: 'בחרו תאריך תפוגה לקופון במידה ותרצו שיהיה לו תאריך תפוגה, הסירו את תאריך התפגוה בכדי לכבות ולהדליק את הקופון ידנית.',
    SELECT_EXPIRATION_DATE: 'בחירת תאריך תפוגה',
    REMOVE_EXPIRATION_DATE: 'הסר תאריך תפוגה',
    SELECT_START_DATE: 'בחירת תאריך התחלה',
    REMOVE_START_DATE: 'הסר תאריך התחלה',
    TICKETS: 'כרטיסיות',
    MEMBERSHIPS: 'מנויים',
    PRODUCTS: 'מוצרים',
    SESSIONS: 'שיעורים',
    TICKET: 'כרטיסיה',
    MEMBERSHIP: 'מנוי',
    PRODUCT: 'מוצר',
    SESSION: 'שיעור',
    COUPONS_LIST: 'קופונים',
    CREATE_COUPON: 'יצירת קופון חדש',
    EDIT_COUPON: 'עריכת קופון',
    SAVE_COUPON: 'שמור קופון',
    SAVE_COUPON_SUCCESS: 'קופון נשמר בהצלחה',
    SELECT_COUPON_TYPE: 'בחר סוג קופון',
    MORE_COUPON: 'כל הקופונים',
    MORE_COUPON_TOOLTIP: 'ניהול כל הקופונים וההנחות.\nניתן לייצר מספר קופונים לכל מוצר ותכנית',
    DISCOUNTED: '(קופון הנחה %{discount_percent}%)',
    COUPON_USED: 'קופון היה בשימוש %{coupon_used} פעמים',
    COUPON_CHECKBOX_TOOLTIP: "יש ברשותי קוד הנחה",
    PLACEHOLDER: "קוד קופון",
    APPLY_BUTTON: "החל קוד קופון",
    VALID_CODE: "קוד קופון תקף הוחל!",
    INVALID_CODE: "קוד קופון לא תקף.",
    SUCCESS_ALERT: "קוד הקופון הוחל בהצלחה!",
    ERROR_ALERT: "קוד קופון לא תקף. אנא נסה שוב.",
    VALIDATION_ERROR: "אירעה שגיאה בעת אימות קוד הקופון.",
    COUPON_NOT_EXISTS: "קופון זה אינו קיים.",
    COUPON_NOT_ACTIVE: "קופון זה אינו פעיל.",
    COUPON_NOT_DISCOUNT: "קופון זה אינו מספק הנחה.",
    COUPON_ACTIVATED_SUCCESS: "הקופון הופעל בהצלחה!",
    COUPON_USAGE_LIMIT: "הגבלת שימוש (אופציונלי)",
    COUPON_USAGE_LIMIT_TOOLTIP: "מספר הפעמים שקופון זה יכול לשמש סך הכל",
    COUPON_USER_USAGE_LIMIT: "הגבלת שימוש למשתמש (אופציונלי)",
    COUPON_USER_USAGE_LIMIT_TOOLTIP: "מספר הפעמים שקופון זה יכול לשמש פר משתמש",
    COUPON_NO_LIMIT: "ללא הגבלה"
},
  instrcutionsTypes: {
    OVERVIEW: 'סקירה + דוגמא',
    SIGNUP: 'רישום ראשוני',
    SETTINGS: 'הגדרת אופי הסטודיו',
    PLANS_CREATION: 'יצירת כרטיסיות ומנויים',
    SCHED: 'יצירת מערכת השעות של הסטודיו',
    ADD_REMOVE_STUDENTS: 'הוספה/הסרה/חיפוש תלמידים',
    STUDENTS: 'ניהול תלמידים ושיעורים',
    STUDENT_INFO: 'עמוד מידע על תלמיד',
    PLANS: 'ניהול כרטיסיות ומנויים',
    ONBOARDING_STUDENTS: 'הקמה+תחילת עבודה עם מדידייט'
  },
  paymentTypes: {
    CASH: 'מזומן',
    CHECK: 'צ\'ק',
    BANK_TRANSFER: 'העברה בנקאית',
    OTHER: 'אחר',
    CREDIT_CARD: 'כרטיס אשראי',
    TICKET: 'כרטיסיה',
    MEMBERSHIP: 'מנוי',
    TRIAL: 'שיעור ניסיון',
    PARTIAL: 'שולם חלקית',
    PAYMENTS: 'בתשלומים ידניים',
    AUTO_PAYMENTS: 'בתשלומים אוטומטיים',
    PAYMENTS_DONE: 'תשלומים הסתיימו',
    BIT: 'ביט',
    PENDING_FIRST_PAYMENTS: 'מחכה לתשלום ראשון...',
    TRIAL_PRICE: 'מחיר שיעור ניסיון'
  },
  paymentPaidTypes: {
    CASH: 'מזומן',
    CHECK: 'צ\'ק',
    BANK_TRANSFER: 'העברה בנקאית',
    OTHER: 'אחר'
  },
  accountingTypes: {
    ICOUNT: 'אייקאונט',
    GROW_INVOICE: 'חשבוניות משולם (Grow)'
  },
  bankNames: {
    4: 'בנק יהב לעובדי המדינה',
    9: 'בנק הדואר',
    10: 'בנק לאומי',
    11: 'בנק דיסקונט',
    12: 'בנק הפועלים',
    13: 'בנק אגוד לישראל',
    14: 'בנק אוצר החייל',
    17: 'בנק מרכנתיל דסקונט',
    20: 'בנק מזרחי-טפחות',
    22: 'סיטיבנק',
    23: 'HSBC',
    26: 'UBANK',
    31: 'הבין לאומי הראשון',
    34: 'בנק ערבי ישראלי',
    39: 'בנק אוף אינדיה',
    46: 'בנק מסד',
    52: 'בנק פאגי',
    54: 'בנק ירושלים',
    68: 'דקסיה ישראל',
    77: 'בנק לאומי למשכנתאות',
    90: 'בנק דיסקונט למשכנתאות',
    91: 'משכן בנהפ למשכנתאות',
    92: 'הבין לאומי למשכנתאות'
  },
  sortOptions: {
    CREATED: "מיון לפי תאריך הוספה",
    UPDATED: "מיון לפי תאריך עדכון",
    NAME: "מיון לפי שם",
    TITLE: "מיון לפי כותרת",
    EMAIL: "מיון לפי אימייל",
    PHONE: "מיון לפי טלפון",
    DATE: "מיון לפי תאריך",
    EXPIRATION_DATE: "מיון לפי תאריך סיום",
    PAID: "מיון לפי שולם",
    HIDDEN: "מיון לפי מוסתר",
    PRICE: "מיון לפי מחיר",
    FROZEN: "מיין לפי מצב הקפאה",
    FREQUENT: "מיין לפי תלמידים קבועים"
  },
  filterOptions: {
    PAID: "שולם",
    NOT_PAID: "לא שולם",
    HAVE_MEMBERSHIP: "עם מנוי",
    NO_MEMBERSHIP: "ללא מנוי",
    HAVE_TICKET: "עם כרטיסיה",
    NO_TICKET: "ללא כרטיסיה",
    HAVE_PLAN: "עם תכנית פעילה",
    NO_PLAN: "ללא תכנית פעילה",
    ACTIVE: "פעיל",
    NOT_ACTIVE: "לא פעיל",
    NEEDS_RECEIPT: "שולם אך לא הוצאה קבלה / חשבונית מס",
    HAVE_RECEIPT: "הוצאה קבלה / חשבונית מס"
  },
  nav: {
    HOME: 'בית',
    PROFILE: 'הגדרות',
    STUDENTS: 'תלמידים ולקוחות',
    PLANS: 'כרטיסיות ומנויים',
    PRODUCTS: 'מוצרים',
    COUPONS: 'קופונים',
    INSIGHTS: 'תמונת מצב',
    SUMMARIES: 'דוחות וסיכומים',
    ACCOUNTING: 'הנהלת חשבונות',
    BILLING: 'תשלומים וסליקה',
    AFFILIATES: 'תכנית השותפים שלנו',
    LOGOUT: 'התנתק/י',
    MESSAGES: 'הודעות',
    SUPPORT: 'תמיכה',
    TUTORIALS: 'הדרכות',
    AUTOMATIONS: 'אוטומציות'
  },
  clientStatus: {
    NEW: 'חדש',
    INTERESTED: 'מעוניין',
    NOT_INTERESTED: 'לא מעוניין',
    ACTIVE: 'פעיל',
    NO_ACTIVE: 'לא פעיל',
    ON_HOLD: 'בהקפאה',
    REACH_OUT: 'ליצור קשר בהמשך',
    NO_STATUS: 'ללא סטטוס',
  },
  studentStatus: {
    NO_STATUS: 'ללא סטטוס',
    CANCELLED_REFUNDED: 'ביטל.ה + החזר',
    CANCELLED_NOT_REFUNDED: 'ביטל.ה ללא החזר',
    ARRIVED: 'הגיע.ה',
    DID_NOT_ARRIVE: 'לא הגיע.ה'
  },
  modal: {
    OK: 'אישור',
    CANCEL: 'ביטול'
  },
  breadcrumbs: {
    DASHBOARD: 'ניהול',
    HOME: 'בית',
    PROFILE: 'הגדרות',
    STUDENTS: 'תלמידים',
    MESSAGES: 'הודעות',
    PLANS: 'כרטיסיות ומנויים',
    PRODUCTS: 'מוצרים',
    COUPONS: 'קופונים',
    INSIGHTS: 'תמונת מצב',
    LIST: 'רשימה',
    SESSIONS: 'שיעורים',
    EDIT_SESSION: 'עריכת שיעור',
    BILLING: 'תשלומים וסליקה',
    BILLING_SETUP: 'פרטי חיוב',
    TICKET_EDIT: 'עריכת כרטיסיה',
    MEMBERSHIP_EDIT: 'עריכת מנוי',
    PRODUCT_EDIT: 'עריכת מוצר',
    COUPON_EDIT: 'עריכת קופון',
    AUTOMATIONS: 'אוטומציות',
    REPORTS: 'דוחות וסיכומים',
    TUTORIALS: 'הדרכות'
  },
  notifications: {
    NEW_NOTIFICATIONS: 'הודעות חדשות',
    NO_NEW_NOTIFICATIONS: 'אין הודעות חדשות',
    READ_NOTIFICATIONS: 'הודעות שנקראו',
    MARK_READ_NOTIFICATIONS: 'סמן הכל כנקרא',
    NOTIFICATIONS: 'נוטיפיקציות'
  },
  login: {
    TITLE: 'התחברות לבעלי סטודיו ומורים',
    SUBTITLE: 'התחבר/י למדידייט',
    AFFILIATED: 'הופנתי על ידי',
    LOGIN_BUTTON: 'התחבר/י',
    FORGOT_PASSWORD: 'שכחתי סיסמא',
    REGISTER_TITLE: 'הרשמה לבעלי סטודיו ומורים',
    REGISTER_STUDENT_TITLE: 'הרשמה לסטודיו',
    REGISTER_AFFILIATE_TITLE: 'הרשמה לתכנית שותפים של מדידייט',
    REGISTER_STUDENT_SUCCESS: 'נרשמת בהצלחה!',
    REGISTER_AFFILIATOR_SUCCESS_REGISTER_SUBTITLE: 'נשלח אליך אימייל עם פרטי ההתחברות שלך למדידייט',
    REGISTER_STUDENT_SUCCESS_REGISTER_SUBTITLE: 'נשלח אליך אימייל עם פרטי ההתחברות שלך ולינק להורדה לאפליקציית מדידייט, שם תוכל/י להרשם לשיעורים ולנהל את הפרופיל שלך',
    REGISTER_STUDENT_SUCCESS_REGISTER_NOTE: '*אם לא קיבלת מייל יש לבדוק תחת התוויות עדכונים \\ שיווק \\ פרסום או בתיקיית הספאם',
    REGISTER_SUBTITLE: 'חדש/ה במדידייט? הרשמו עכשיו',
    REGISTER_BUTTON: 'הרשמה',
    PLACEHOLDER_EMAIL: 'אימייל',
    PLACEHOLDER_PASSWORD: 'סיסמא',
    REGISTER_TEXT_SMALL: 'אינך רשומ/ה?',
    LANGUAGE_TOGGLE: 'English',
    MEDIDATE_PAID_NOTICE: '<p><span style="font-size:15px;color:white;"><strong>מדידייט ניתנת לשימוש חינם ומלא עד ה-%{subscriptionFirstDay} <a href="%{link}" style="color:blue" target="_blank">מחיר</a></strong></span></p>',
    CONTINUE_AFFILIATION: 'לחצו על המשך כדי להמשיך לעמוד השותפים של מדידייט',
    CONTINUE_PURCHASE: 'לחצו על המשך כדי להמשיך בהרשמה/רכישה',
    CONTINUE_REGISTER: 'נשלח אליך אימייל עם אישור ההרשמה ופרטי כניסה, המשיכו אחר כך בעזרת המייל או המשיכו בהרשמה כעת בחלון חדש'
  },
  forms: {
    FORM_LOGIN_TITLE: 'לצפיה בעמוד הבא יש להתחבר למדידייט עם פרטי הרישום',
    FORM_RESTRICTED_ACCESS: 'אין הרשאה לצפות בעמוד הבא',
    FORM_LOGIN_WITH_DIFFERENT_USER: 'החלפת משתמש',
    AFFILIATES_SUBTITLE: 'אנא קראו בעיון וחתמו על המסמך הנ"ל בכדי שנוכל להעביר לכם את הרווחים מתכנית השותפים של מדידייט',
    HEALTH_TITLE: 'הצהרת בריאות',
    HEALTH_SUBTITLE: 'שיעורי הסטודיו מתאימים לכל גיל ולכל רמה של כוח, כושר וגמישות. אולם, קיימים מספר מצבים רפואיים הדורשים אימון מעט שונה ותשומת לב מיוחדת מצד המורה והמתרגל. לכן, אנא ידע/י את המורה בהיסטוריה הרפואית הבסיסית שלך.',
    HEALTH_BLOOD: 'לחץ דם',
    HEALTH_SUGAR: 'סוכרת',
    HEALTH_HEAD: 'כאבי ראש, סחרחורות, חולשה',
    HEALTH_BREATH: 'אסטמה או בעיות נשימה',
    HEALTH_BALANCE: 'בעיות בשיווי המשקל',
    HEALTH_NECK: 'בעיות צוואר, עורף וכתפיים',
    HEALTH_JOINTS: 'בעיות במפרקים',
    HEALTH_BACK: 'בעיות בעמוד השידרה (פריצות דיסק, עקמת, וכדומה)',
    HEALTH_DIGESTION: 'בעיות עיכול',
    HEALTH_EARS: 'בעית אזנים',
    HEALTH_EYES: 'גלאוקומה או בעיות עיניים אחרות',
    HEALTH_CHRONIC: 'מחלה כרונית כלשהי (פעילה או רדומה)',
    HEALTH_SURGERY: 'ניתוחים כירורגיים',
    HEALTH_SMOKING: 'מעשן/מעשנת',
    HEALTH_PREGNANT: 'האם את בהריון?',
    HEALTH_PREGNANT_NUM: 'כמה לידות עברת?',
    HEALTH_MANS: 'האם יש בעיות במחזור החודשי?',
    HEALTH_BEFORE_TERMS: 'יש ליידע את המורה ביחס למצבים הנ"ל על מנת להתאים אימון טוב יותר, וכדי לבחון האם יש צורך באישור או בהמלצת רופא להשתתפותך בשיעורים. ** נשים בזמן וסת או הריון צריכות להימנע מתרגילים מסוימים. אנא היוועצי עם המורה לפני תחילת השיעור.',
    HEALTH_TERMS: 'הנני מצהיר/ה כי ברור לי שכל הפרטים הנ"ל חיוניים לצורך סוג ומהות הטיפול/תרגול ועניתי לשאלות ביושר ובתום לב. אני מצהיר/ה שהתרגול הוא על אחריותי האישית וכי אציית לנוהלי השיעור',
    HEALTH_SUBMIT: 'קראתי ומאשר/ת',
    HEALTH_WOMEN: 'לנשים:',
    HEALTH_GENERAL_COMMENT: 'הערה כללית',
    EDIT_STUDIO_TERMS: 'עריכת תקנון הסטודיו',
    EDIT_STUDIO_TERMS_HINT: 'תקנון הסטודיו שלך כאן',
    HEALTH_SEND_FORM: 'שלח/י טופס הצהרת בריאות',
    HEALTH_SEND_TO_STUDENT: 'לשלוח טופס הצהרת בריאות למייל התלמיד/ה?',
    HEALTH_SIGNATURE: 'חתימה',
    HEALTH_CLEAR_SIGNATURE: 'נקה חתימה',
    HEALTH_FIRST_NAME: 'שם',
    HEALTH_LAST_NAME: 'שם משפחה',
    HEALTH_SOCIAL_ID: 'תעודת זהות',
    HEALTH_ALERT_TERMS: 'אנא אשרו את התנאים ואת ההצהרה',
    HEALTH_ALERT_SGINATURE: 'אנא הוסיפו חתימה בקופסת החתימה למטה',
    HEALTH_ALERT_NAME: 'אנא הוסיפו שם ושם משפחה לטופס ההצהרה',
    HEALTH_ALERT_SOCIAL_ID: 'אנא הוסיפו ת.ז לטופס ההצהרה',
    HEALTH_ALERT_BIRTH_DATE: 'אנא הכניסו תאריך לידה לטופס ההצהרה',
    HEALTH_BIRTHDATE: 'תאריך לידה',
    PAY_FOR: '<h2>תשלום על %{className}: <b>%{name}</h2>',
    SESSION_FOR: '<h2>%{className}: <b>%{name}</h2>',
    CORONA_TITLE: 'הצהרת קורונה לשיעור',
    CORONA_COUGHING: 'אני מצהיר/ה על כך שאני לא משתעל/ת',
    CORONA_TEMPRATURE: 'אני מצהיר/ה על כך שחום גופי אינו עולה על 38 מעלות וגם לא היה לי חום העולה על 38 מעלות בשבוע האחרון',
    CORONA_CLOSE_CONTACT: 'אני מצהיר/ה על כך שלא הייתי במגע קרוב עם חולה קורונה בשבועיים האחרונים',
    CORONA_SEND_FORM: 'לשלוח הצהרת קורונה למייל התלמיד?',
    AGREE_TO_ALL_STATEMENTS: 'אני מאשר/ת את כל ההצהרות לעיל',
    SUBSCRIBE_STUDENT_STUDIO_EMAILS: 'ברשימת הדיוור של הסטודיו',
    UNSUBSCRIBE_STUDENT_STUDIO_EMAILS: 'לא ברשימת הדיוור של הסטודיו',
    UNSUBSCRIBED_STUDENT_STUDIO_EMAILS: 'מחוץ לרשימת הדיוור של הסטודיו',
    SUBSCRIBED_STUDENT_STUDIO_EMAILS: 'הוכנס לרשימת הדיוור של הסטודיו',
    AFFILIATES_TERMS_TITLE: 'הסכם תכנית שותפים מדידייט'
  },
  register: {
    TITLE: 'הרשמה למדידייט',
    SUBTITLE: 'יצירת חשבון במדידייט',
    AFFILIATES_SUBTITLE: 'יצירת חשבון בתכנית השותפים של מדידייט',
    PLACEHOLDER_PASSWORD: 'סיסמא',
    PLACEHOLDER_REPEAT_PASSWORD: 'סיסמא שוב',
    PLACEHOLDER_FIRSTNAME: 'שם פרטי',
    PLACEHOLDER_LASTNAME: 'שם משפחה',
    PLACEHOLDER_NAME: 'שם מלא',
    PLACEHOLDER_EMAIL: 'אימייל',
    SIGNUP_BUTTON: 'יצירת חשבון',
    AGREED_TO_TERMS: 'אני מאשר/ת שקראתי את <a href="%{termsLink}" target="_blank">תנאי השימוש</a> <a href="%{privacyLink}" target="_blank">ופרטיות</a>',
    PROMOTION_CONSENT: 'אני מעוניינ/ת לקבל חומר פרסומי בדואר אלקטרוני',
    LABEL_PHONE_NUMBER: 'מספר טלפון',
    LABEL_STUDENT_PHONE_NUMBER: 'מספר טלפון',
    ACCOUNT_EXISTS_ERROR_MESSAGE: 'מנוי כבר קיים, אנא הכנסו דרך עמוד התחברות הראשי ולא עמוד זה',
    BUSINESS_DETAILS_TITLE: 'פרטי העסק',
    BUSINESS_DETAILS: 'ספרו לנו עוד על העסק שלכם.ן',
    ALERT_ENTER_NAME: 'אנא הכניסו שם',
    ALERT_ENTER_FIRST_NAME: 'אנא הכניסו שם פרטי',
    ALERT_ENTER_LAST_NAME: 'אנא הכניסו שם משפחה',
    ALERT_ENTER_EMAIL: 'אנא הכניסו מייל תקין',
    ALERT_ENTER_PASSWORD: 'אנא בחרו סיסמא',
    ALERT_REENTER_PASSWORD: 'אנא הכניסו סיסמא שנית',
    ALERT_REENTERED_PASSWORD_NOT_SAME: 'הסיסמא שהוכנסה שנית אינה תואמת את הסיסמא הראשונה',
    ALERT_APPROVE_TERMS: 'אנא אשרו תנאי שימוש',
    ALERT_SELECT_OPTION: 'אנא בחרו אפשרות',
    ALERT_PHONE_NUMBER: 'אנא הכניסו מספר טלפון',
    ALERT_PHONE_COUNTRY: 'אנא בחרו קידומת טלפון ואז הכניסו את המספר שלכם.ן',
    ALERT_BANK_DETAILS: 'נא להכניס פרטי חשבון בנק',
    ALERT_BANK_NUMBER: 'נא להכניס מספר בנק',
    ALERT_BANK_BRANCH_NUMBER: 'נא להכניס מספר סניף בנק',
    ALERT_BANK_ACCCOUNT_NUMBER: 'נא להכניס מספר חשבון',
    ALERT_SUBJECT_PLACEHOLDER: 'אשמח לעזרה, אנא חזרו אלי.',
    ALERT_SUBJECT: 'נא לכתוב נושא',
    ALERT_MESSAGE: 'נא לכתוב הודעה',
  },
  wizard: {
    STEP_ONE_TITLE: 'היי, %{name}!',
    LABEL_QUALIFICATION: 'האם לסטודיו זה יש מספר מורים?',
    NEXT_STEP_BUTTON: 'הבא',
    LABEL_DESCRIPTION: 'קצת עלי ועל הסטודיו/עסק',
    LABEL_PROFILE_PHOTO: 'הוסיפו תמונה או לוגו',
    LABEL_TEACHING_STYLES: 'סגנון לימוד',
    LABEL_YOGA: 'יוגה',
    LABEL_MEDITATION: 'מדיטציה',
    PLACEHOLDER_YOGA: 'בחרו סגנון יוגה',
    PLACEHOLDER_MEDITATION: 'בחרו סגנון מדיטציה',
    ALL_SET_TITLE: 'לפני שנתחיל נעבור על כמה הגדרות חשובות',
    ALL_SET_TEXT: 'אל דאגה, תמיד תוכלו לערוך ולשנות את מה שנגדיר עכשיו',
    GO_TO_DASHBOARD: 'יאללה, התחלנו',
    LABEL_COUNTRY: 'מדינה',
    LABEL_CITY: 'כתובת הסטודיו/עסק',
    LABEL_BANK_COUNTRY: 'בחרו את המדינה בה הסטודיו/עסק פועל',
    ALERT_MUST_HAVE_STUDIO_NAME: 'אנא הכניסו את שם הסטודיו',
    ALERT_MUST_HAVE_BANK_COUNTRY: 'אנא בחרו את המדינה בה ממוקם העסק',
    PROFILE_STEP_1_TITLE: 'קצת על הסטודיו?',
    PROFILE_STEP_1_SUB_TITLE: 'זה המקום להוסיף תמונה או לוגו',
    PROFILE_STEP_2_TITLE: 'האם התלמידים ישתמשו באפליקציית מדידייט להירשם לשיעורים?',
    PROFILE_STEP_2_SUB_TITLE: '<p dir="rtl">במידה וכן, מומלץ להדליק את האפשרות שתלמידים יקבלו את מייל ההזמנה לאפליקציה.<br>עברו על שאר האפשרויות וסמנו את מה שרלוונטי לסטודיו.<br>בנוסף עברו על <a target="_blank" href="%{baseUrl}/#/pages/forms/health/%{userId}/%{userId}/%{locale}"><span style="color:#9141bc;">ההצהרת בריאות</span></a>&nbsp;שהמערכת מספקת ובדקו&nbsp;אם היא מתאימה לשימוש בסטודיו.</p>',
    PROFILE_STEP_3_TITLE: 'מה חשוב לכם לדעת שקורה בסטודיו?',
    PROFILE_STEP_3_SUB_TITLE: 'אלו התראות תרצו לקבל למייל?<br>האם תרצו לדעת אם תלמידים ניסו לבטל שיעור בטווח מדיניות הביטול (כלומר בטווח שכבר לא ניתן לבטל)?<br>האם תרצו לדעת אם תלמידים ניסו להירשם בטווח מדיניות רישום (כלומר בטווח שעוד לא ניתן להירשם לשיעור במידה ויש מדיניות כזו)?',
    PROFILE_STEP_4_TITLE: 'עוד משהו קטן...',
    PROFILE_STEP_4_SUB_TITLE: 'האם תרצו שמתרגלים יוכלו לראות את שמות ו/או כמות התלמידים בשיעור?<br>במידה ולא, סמנו בהתאם (כשמסומן בירוק זה מסתיר).',
    PROFILE_STEP_5_TITLE: 'מה מדיניות הרישום והביטול בסטודיו?',
    PROFILE_STEP_5_SUB_TITLE: 'האם תלמידים צריכים כרטיסיה או מנוי בתוקף כדי לתפוס מקום בשיעורים דרך האפליקציה?<br>האם תרצו שימלאו טופס הצהרת בריאות או טופס תקנון הסטודיו לפני שיוכלו להירשם?<br>כמה שעות לפני שיעור אפשר להירשם?<br>כמה שעות לפני שיעור ניתן לבטל?<br>אם יש תקנון לסטודיו תוכלו להוסיף אותו עכשיו ע״י לחיצה על כפתור התקנון למטה. אל דאגה תוכלו גם לחזור אליו מאוחר יותר.',
    MEDIDATE_TUTORIAL: 'הסברים ומדריכים',
  },
  teacherTypes: {
    NONE: 'אין הכשרה',
    PRACTITIONER: 'מתרגל/ת',
    INSTRUCTOR: 'מדריכ/ה',
    TEACHER: 'מורה',
    STUDIO: 'סטודיו'
  },
  answerTypes: {
    YES: 'כן',
    NO: 'לא'
  },
  repetitionOptions: {
    ONCE: 'חד פעמי',
    DAILY: 'יומי (כבר לא נתמך)',
    WEEKLY: 'שבועי (מתקיים פעם בשבוע)',
    MONTHLY: 'חודשי (מתקיים פעם בחודש)'
  },
  timeFormatOptions: {
    HOURS_24: '24 שעות',
    AM_PM: 'AM/PM'
  },
  sessionTypes: {
    MEDITATION: 'מדיטציה',
    YOGA: 'יוגה',
    TAI_CHI: 'טאי צ\'י',
    MUSIC: 'מעגלי מוסיקה',
    PILATES: 'פילאטיס'
  },
  priceTypes: {
    FREE: 'חינם',
    PRICE_RANGE: 'מחיר לכניסה חד פעמית',
    MEDIDATE_PRICING: 'מחיר לכניסה חד פעמית'
  },
  businessTypes: {
    STUDIO: 'סטודיו - מורים/ות | מתרגלים/ות | שיעורים',
    CENTER: 'מרכז - מורים | מתרגלים | שיעורים',
    CLINIC: 'קליניקה - מטפלים/ות | מתרגלים/ות | טיפולים',
    BUSINESS: 'עסק - מוכרים/ות | לקוחות | מוצרים',
    SPORTS_CLUB: 'מועדון ספורט - מאמנים/ות | מתאמנים/ות | אימונים'
  },
  home: {
    MY_STUDENTS_TITLE: 'התלמידים/ות בסטודיו <b>(%{count})</b>',
    MY_STUDENTS_TITLE_NO_COUNT: 'התלמידים/ות בסטודיו',
    MY_ARCHIVED_STUDENTS_TITLE: 'לקוחות פוטנציאליים <b>(%{archived_count})</b>',
    MY_ARCHIVED_STUDENTS_TITLE_NO_COUNT: 'לקוחות פוטנציאליים',
    MY_WEEKY_SCHEDULE_TITLE: 'מערכת השעות',
    STUDIO_WEEKY_SCHEDULE_TITLE: '<b>%{studio}</b>: מערכת שעות שבועית',
    SHOW_ALL_STUDENTS: 'הצג את כל התלמידים/ות בסטודיו',
    SHOW_ALL_ARCHIVED: 'הצג את כל הלקוחות הפוטנציאליים',
    STUDIO_MONTHLY_ACTIVITY: 'פעילות חודשית',
    POWERED_BY: 'מופעל על ידי מדידייט'
  },
  forgotPassword: {
    TEXT: 'רשמו פה את כתובת המייל שלכם.ן ותקבלו מייל עם הוראות לאיפוס סיסמא',
    SUBMIT_BUTTON: 'שלח/י',
    SUCCESS_MESSAGE: 'נשלח אליכם.ן אי-מייל לאיפוס סיסמא ,אנא בדקו את תיבת האי-מייל שלכם.ן'
  },
  form: {
    ON_LEAVE_PROMT: 'השינויים לא נשמרו עדיין, האם את/ה בטוח/ה שברצונך לצאת?',
    DATE: 'תאריך',
    MONTH: 'חודש',
    YEAR: 'שנה',
    OPTIONAL: 'אופציונלי'
  },
  product: {
    LABEL: 'מוצר',
    PRICE: 'מחיר',
    PRICE_VAT: 'מחיר (כולל מע"מ)',
    PAYMENT_METHOD: 'צורת תשלום',
    PAYMENT_ITERATIONS: 'x %{count} תשלומים חודשיים של %{monthlyPrice} לחודש',
    PAYMENT_ITERATIONS_TIP: '* המחיר המוצג מחושב לפי מכפלה של כמות החודשים שיש למנוי והמחיר החודשי של המנוי'
  },
  studentPlans: {
    PLAN_LABEL: 'תכנית',
    MODAL_TITLE: 'התכניות של %{name}',
    PLAN_NAME: 'שם התכנית',
    ENTRIES: 'כניסות',
    ENTRIES_USED: 'כניסות בשימוש',
    ENTRIES_LEFT: 'כניסות שנשארו',
    EXPIRATION_DATE: 'תקף עד',
    EXPIRED_AT: 'תקף עד ה',
    PAID: 'שולם',
    PLAN_PAID: 'תכנית שולמה',
    TICKETS: 'כרטיסיות פעילות',
    MEMBERSHIPS: 'מנויים פעילים',
    FROZEN_MEMBERSHIPS: 'מנויים שהוקפאו',
    FROZEN_TICKETS: 'כרטיסיות שהוקפאו',
    EXPIRED_MEMBERSHIPS: 'מנויים שעבר זמנם',
    EXPIRED_TICKETS: 'כרטיסיות שעבר זמנן',
    NO_PLANS: 'לא נמצאו תכניות לתלמיד',
    SHOW_ACTIVE_TICKETS: 'הצגת תכניות פעילות בלבד',
    FROZEN_STATUS: 'מצב הקפאה',
    REMOVE_STATUS_PAID_WARNING: 'אנא הסירו את המצב ״שולם״ על המנוי או כרטיסיה לפניה מחיקתה'
  },
  profile: {
    TITLE: 'הפרופיל שלי',
    OTHER_TITLE: 'פרטי הפרופיל של %{other_user}',
    SAVE_BUTTON: 'שמירת שינויים',
    FINISH_AND_SAVE: 'סיים ושמור',
    Hide: 'הסתר',
    RESET_PASSWORD: 'איפוס סיסמא',
    STUDIO_TERMS: 'תקנון הסטודיו',
    GENDER: 'מין',
    MALE: 'זכר',
    FEMALE: 'נקבה',
    LANGUAGE: 'שפה',
    TIME: 'תצוגה של זמן',
    PAYMENTS: 'תשלומים',
    PRIVACY: 'פרטיות',
    CLASSES_PRIVACY: 'פרטיות שיעורים',
    CLASSES_PREFERENCES: 'ההעדפות שיעור',
    SUSPEND_PAYME_PAYMENTS: 'השהה קבלת תשלומים באשראי פאיימי (PayMe)',
    SUSPEND_PAYME_PAYMENTS_TOOLTIP: 'השהה את האפשרות של קבלת תשלומים באשראי באפליקציה ובמערכת.',
    SUSPEND_GROW_PAYMENTS: 'השהה קבלת תשלומים באשראי משולם (Grow)',
    SUSPEND_GROW_PAYMENTS_TOOLTIP: 'השהה את האפשרות של קבלת תשלומים באשראי באפליקציה ובמערכת.',
    ALLOW_BIT_PAYMENTS: 'אפשרו תשלומים דרך האפליקציה עם ביט (רלוונטי רק לרשומים למערכת התשלומים)',
    ALLOW_BIT_PAYMENTS_TOOLTIP: 'במידה והסטודיו רשום למערכת הסליקה, אופציה זו מאפשרת לתלמידים לרכוש מוצרים דרך האפליקציה עם ביט',
    ALLOW_APPLE_PAYMENTS: 'אפשרו תשלומים דרך האפליקציה עם Apple Pay (רלוונטי רק לרשומים למערכת התשלומים)',
    ALLOW_APPLE_PAYMENTS_TOOLTIP: 'במידה והסטודיו רשום למערכת הסליקה, אופציה זו מאפשרת לתלמידים לרכוש מוצרים דרך האפליקציה עם Apple Pay',
    HIDE_FREE_AMOUNT_PAYMENTS: 'הסתר את כפתור "תשלום חופשי" בתוך האפליקציה',
    HIDE_FREE_AMOUNT_PAYMENTS_TOOLTIP: 'במידה והסטודיו רשום למערכת הסליקה, אופציה זו מונעת מתלמידים לשלם לך "סכום חופשי" דרך האפליקציה',
    HIDE_DROPIN_AMOUNT_PAYMENTS: 'הסתר את המחיר של שיעור בתוך האפליקציה',
    HIDE_DROPIN_AMOUNT_PAYMENTS_TOOLTIP: 'אופציה זו מונעת מתלמידים לראות את המחיר של שיעור דרך האפליקציה',
    BIRTH_DATE: 'תאריך לידה',
    SHOW_BIRTH_DATE: 'הצגת תאריך לידה',
    VAT_EXEMPT_DEALER: 'עוסק פטור',
    VAT_NOT_EXEMPT_DEALER: 'לא עוסק פטור',
    SEND_BIRTH_DATE: 'הודע לי ביומולדת',
    HAS_BIRTH_DAY: 'תלמיד זה חוגג יומולדת היום!',
    ELIGIBLE_TRIAL_SESSION: 'זכאי לשיעור ניסיון',
    FREE_MONTH: 'אתם תתוגמלו כספית על כל סטודיו שעובד עם מדידייט דרככם, ולסטודיו הנכנס יהיו עוד שבועיים מתנה על גבי ה-30 ימי ניסיון',
    QUALIFICATIONS: 'מורה או סטודיו?',
    SAVE_SUCCESS_ALERT: 'שינויים נשמרו!',
    INVITATION_SENT_ALERT: 'הזמנה נשלחה בהצלחה',
    TERMS_SENT_ALERT: 'תקנון נשלח בהצלחה',
    FORM_SENT_ALERT: 'טופס הצהרת בריאות נשמר',
    FORM_APPROVED: 'תודה שאישרתם/ן את התקנון',
    LABEL_BUSINESS_TYPE_TITLE: 'סוג העסק',
    STUDIO_EDIT_TITLE: 'המורים בסטודיו',
    LABEL_STUDIO_TITLE: 'שם הסטודיו/עסק',
    IS_STUDIO: 'סטודיו זה מנהל מספר מורים',
    ASSOCIATED_TEACHERS_TITLE: 'מורים/ות בסטודיו <b>(%{count})</b>',
    OWNER: 'בעל/ת הסטודיו',
    PLACEHOLDER_ADD_TEACHER: "רשמו/י כתובת אימייל בכדי לצרף תלמיד/ה חדש/ה",
    PLACEHOLDER_FILTER_USERS: "סינון תלמידים/ות",
    PLACEHOLDER_FIND_USERS: "חיפוש תלמידים/ות בסטודיו",
    FILTER_BY_STATUS: "סינון לפי סטטוס:",
    REMOVE_TEACHER: 'הסרה מהסטודיו שלי',
    LABEL_ADD_TEACHER: "מורה חדש/ה",
    YOUR_TEACHERS: "המורים/ות בסטודיו שלי",
    YOUR_TEACHERS_TOOLTIP: "הוסף מורים/ות שמלמדים/ות בסטודיו. לאחר מכן ניתן יהיה לייחס את המורים/ות הללו כמעבירי/ות השיעורים. בלוח שנה יופיע שם המורה שמעביר/ה את אותו שיעור",
    INVITE_USER: 'הזמן את %{email} למדידייט',
    ADD_USER: 'הוסף את %{email} לסטודיו',
    ADD_TEACHER_NO_RESULT_TEXT: 'לא נמצאו משתמשים/ות במדידייט, הכנס/י כתובת אימייל בכדי להזמין תלמיד/ה חדש/ה',
    USER_FILTER_NO_RESULT_TEXT: 'לא נמצאו תלמידים/ות',
    USER_ADDED_ALERT: "%{email} %{name} נוספ/ה בהצלחה למדידייט",
    USER_ADDED_INVITED_ALERT: "%{name} %{email} הוזמ/נה בהצלחה למדידייט",
    USER_REGISTERED_ALERT: "נרשמת בהצלחה, אנא בדקו/י את המייל שלך לפרטים נוספים",
    EMAIL_STUDIO_NOTIFICATION_TITLE: 'התראות שנשלחות לסטודיו',
    EMAIL_STUDENT_NOTIFICATION_TITLE: 'התראות שנשלחות לתלמידים/ות',
    CHAT_SETTINGS_TITLE: 'הגדרות צ׳טים והודעות',
    WHATSAPP_NOTIFICATION_TITLE: 'התראות שנשלחות בווצאפ',
    WHATSAPP_NOTIFICATION_TITLE_TOOLTIP: 'שליחת הודעת ווצאפ על כל הודעת פוש שנשלחת',
    SESSION_EMAIL_INVITE: 'שלח לי אימייל כאשר מזמינים אותי לשיעור',
    TOGETHER_EMAIL_INVITE: 'שלח לי אימייל כאשר מזמינים אותי לתרגל יחד',
    SESSION_EMAIL_JOINED: 'שלח לי אימייל כשמישהו/י מצטרפ/ת לשיעורים שלי',
    SESSION_EMAIL_LEFT: 'שלח לי אימייל כשמישהו/י עוזב/ת שיעורים שלי',
    SESSION_EMAIL_ENTERED_WAITING_LIST: 'שלח לי אימייל כשתלמיד/ה נכנס/ה לרשימת המתנה של שיעור',
    SESSION_EMAIL_JOIN_POLICY: 'שלח לי אימייל כשיש ניסיון להירשם מחוץ לטווח מדיניות הרישום',
    SESSION_EMAIL_LEAVE_POLICY: 'שלח לי אימייל כשיש ניסיון לביטול רישום בטווח מדיניות הביטול',
    SESSION_EMAIL_AUTO_PUNCH_FAILED: 'שלח לי אימייל כאשר לא היה ניתן לנקב אוטומאטית כרטיסיה או מנוי לתלמיד/ה קבוע/ה בשיעור',
    PLAN_EMAIL_ENDING: 'שלח מייל לתלמידים/ות במידה והכרטיסיה או מנוי הולך להסתיים',
    PLAN_EMAIL_RENEWING_STUDIO: 'שלח מייל לסטודיו במידה והמנוי החודשי (שמתחדש אוטומטית) עומד להתחדש אצל תלמידים/ות ',
    PLAN_EMAIL_RENEWED_STUDIO: 'שלח מייל לסטודיו לאחר שהמנוי החודשי (שמתחדש אוטומטית) התחדש אצל תלמידים/ות',
    PLAN_ENDED_STUDIO: 'שלח מייל לסטודיו במידה והכרטיסיה או מנוי של תלמידים מסתיימים. אפשרות זו תפעל רק אם היא דולקת גם בהתראות שנשלחות לתלמידים',
    PLAN_EMAIL_RENEWING: 'שלח מייל לתלמידים/ות במידה והמנוי החודשי (שמתחדש אוטומטית) עומד להתחדש',
    PLAN_EMAIL_RENEWED: 'שלח מייל לתלמידים/ות לאחר המנוי החודשי (שמתחדש אוטומטית) התחדש',
    SEND_EMAIL_INVITES: 'שלח מייל הזמנה לאפליקציה לתלמידים/ות שמתווספים/ות לסטודיו',
    SEND_EMAIL_INVITE_WITH_HEALTH_DECLARATION: 'שלח הצהרת בריאות יחד עם מייל ההזמנה',
    SEND_EMAIL_INVITE_WITH_TERMS: 'שלח את תקנון הסטודיו יחד עם מייל ההזמנה',
    ALLOW_STUDENTS_PRIVATE_CHAT: 'אפשרו צ׳טים פרטיים',
    ALLOW_STUDENTS_SESSION_CHAT: 'אפשרו צ׳טים לרשומים בשיעור מסויים',
    ALLOW_STUDENTS_GENERAL_CHAT: 'אפשרו צ׳ט כללי של כל הסטודיו עם כל התלמידים',
    ALLOW_STUDENTS_STUDIO_TEACHERS_CHAT: 'אפשרו למורים לשלוח הודעות בצ׳ט כללי של כל המורים הסטודיו',
    PUSH_SESSION_STARTING: 'שלח התראה לאפליקציה על שיעור שעומד להתחיל',
    PUSH_BIRTHDAY: 'שלח התראה עם הודעת מזל טוב לתלמיד ביום ההולדת שלו.ה',
    HIDE_BUSINESS_ACTIVITY: 'הסתר/י מתלמידים באפליקציה את שמות משתתפים/ות בשיעורים',
    HIDE_BUSINESS_ACTIVITY_TOOLTIP: 'הגדרה זו נועדה להסתיר מהתלמידים/ות באפליקציה את שמות התלמידים/ות המגיעים/ות לשיעור. יציג רק כמות',
    HIDE_BUSINESS_ACTIVITY_LABEL: 'הסתר/י פעילות הסטודיו ממשתמשים/ות במדידייט',
    HIDE_SESSION_ATTENDERS_COUNT_LABEL: 'הסתר/י מספר משתתפים/ות',
    HIDE_SESSION_ATTENDERS_COUNT: 'הסתר/י מתלמידים באפליקציה את מספר המשתתפים/ות בשיעור',
    HIDE_SESSION_ATTENDERS_COUNT_TOOLTIP: 'הגדרה זו נועדה להסתיר מהתלמידים/ות באפליקציה את כמות המתרגלים/ות הרשומים/ות לשיעורים',
    ONCE_A_DAY_SESSION_ENTRY: 'הגבילו הרשמה לשיעור אחד ביום, כלומר, שתלמיד/ה ביום מסויים לא תוכל להירשם ליותר משיעור אחד',
    ONCE_A_DAY_SESSION_ENTRY_TOOLTIP: 'הגדרה זו נועדה למנוע מהתלמידים/ות באפליקציה להצטרף למספר שיעורים באותו יום',
    OVERRIDE_PAYMENT: 'אפשרו לתלמידים/ות להירשם לשיעורים גם ללא כרטיסיה או מנוי בתוקף דרך האפליקציה',
    OVERRIDE_PAYMENT_TOOLTIP: 'אפשרות זו מאפשרת לתלמידים/ות להירשם לשיעור ללא צורך בכרטיסיה או מנוי בתוקף או בתשלום דרך האפליקציה ',
    REQUIRES_HEALTH: 'הרשמה לשיעורים דרך האפליקציה מותנית במילוי טופס הצהרת בריאות',
    REQUIRES_HEALTH_TOOLTIP: 'כאשר תלמידים ינסו להירשם לשיעור האפליקציה תודיע שיש צורך למלא טופס בריאות ויעביר ישירות לטופס למילוי',
    REQUIRES_TERMS_AGREEMENT: 'הרשמה לשיעורים דרך האפליקציה מותנית באישור טופס תקנון הסטודיו',
    REQUIRES_TERMS_AGREEMENT_TOOLTIP: 'כאשר תלמידים ינסו להירשם לשיעור האפליקציה תודיע שיש צורך לאשר את התקנון ויעביר ישירות לטופס לאישור',
    SIGN_FUTURE_FREQUENT_SESSIONS: 'תלמידים קבועים בשיעורים - נקב מנויים (ולא כרטיסיות) בשיעורים עתידיים חמישה שבועות קדימה',
    SIGN_FUTURE_FREQUENT_SESSIONS_TOOLTIP: 'המערכת מנקבת 6 ימים לפני ששיעור מתחיל לתלמידים קבועים גם כרטיסיה וגם מנוי.\nבמידה ורוצים שהמערכת תנקב חודש קדימה אנא הדליקו את אפשרות זו (רלוונטי למנויים בלבד ולא כרטיסיות)',
    REFUND_MIN_HOURS: 'יתאפשר לתלמיד/ה לבטל הרשמה עד <b><font size="4">%{hours}</font></b> שעות לפני תחילת שיעור',
    REFUND_POLICY_LABEL: 'מדיניות ביטול והרשמה לשיעורים',
    REFUND_POLICY_LABEL_TOOLTIP: 'הגדר/י כברירת מחדל את מספר השעות לפני תחילת שיעור בו יתאפשר לתלמיד/ה לבטל או להירשם לשיעור דרך האפליקציה. בתוך טווח שעות הביטול לא יתאפשר לתלמיד/ה לצאת מהשיעור',
    PAYMENTS_HISTORY_LABEL: 'חשבוניות מס/קבלה מדידייט',
    PAYMENTS_HISTORY_LABEL_TOOLTIP: 'היסטוריית הרכישות של המנוים של מדידייט, כולל אפשרות להוריד את החשבוניות מס/קבלה של כל רכישה',
    MY_ACCOUNT_LABEL: 'החשבון שלי במדידייט',
    MY_ACCOUNT_LABEL_TOOLTIP: 'פרטי המנוי הנוכחי שלי במדידייט והיסטוריית הרכישות של המנוים הקודמים שלי במדידייט',
    SUBSCRIPTION_LABEL: 'מנוי נוכחי',
    SUBSCRIPTION_LABEL_TOOLTIP: 'המנוי הנוכחי שלי במדידייט והפרטים עליו',
    PAYMENTS_HISTORY_SUB_TITLE: 'תשלומים למדידייט',
    REMOVE_CONFIRM: 'האם את/ה בטוח/ה שברצונך להסיר את %{name} מהסטודיו שלך?',
    USER_INVITE_MODAL_TITLE: "הזמנ/י תלמיד/ה חדש/ה למדידייט",
    USER_ADD_MODAL_TITLE: '+ הוספת תלמיד/ה חדש/ה לסטודיו',
    LEAD_ADD_MODAL_TITLE: 'הוספת לקוח פוטנציאלי לסטודיו',
    INVITE_USER_MODAL_BTN: "שלח/י הזמנה",
    ADD_LEAD_MODAL_BTN: 'הוספת לקוח פוטנציאלי',
    ADD_USER_MODAL_BTN: 'הוספ/י תלמיד/ה',
    ADD_USER_MODAL_ALT: 'להוספת תלמיד/ה שלא רשומ/ה עדיין לסטודיו שלכם.ן',
    ADD_USER_MODAL_ALT_2: 'פעולה זו תוסיף את התלמיד/ה החדש/ה גם %{product_type} בסוף ההוספה',
    SESSION: 'לשיעור',
    PLAN: 'לכרטיסיה/מנוי',
    PRODUCT: 'למוצר',
    GENERAL_PREFERENCES: "הגדרות כלליות",
    RECEIPTS_INVOICES: "הנהלת חשבונות",
    RECEIPTS_INVOICES: "הנהלת חשבונות",
    RECEIPTS_INVOICES_ICOUNT: "iCount",
    RECEIPTS_INVOICES_GREEN: "Green Invoice",
    RECEIPTS_INVOICES_CONNECT: "חבר את חשבון ה%{accounting} שלי",
    RECEIPTS_INVOICES_CONNECTED: "חשבון %{accounting} חובר בהצלחה - %{accountingUserId}",
    RECEIPTS_INVOICES_SYNC_STUDENTS: "סנכרן את התלמידים/ות שלי עם %{accounting}",
    RECEIPTS_INVOICES_SYNC_STUDENTS_TIP: "ייצא/י את התלמידים/ות שלך ופרטיהם אל %{accounting} בכדי לייצר לקוחות חדשים/ות (אם יש צורך) עם הפרטים שלהם/ן מתוך מערכת מדידייט",
    RECEIPTS_INVOICES_SYNC_LASTS: "סנכרון אחרון של התלידים/ות עם מערכת %{accounting} - %{syncDate}",
    RECEIPTS_INVOICES_SYNC_STUDENTS_COMPLETE: "התלמידים/ות שלך סונכרנו בהצלחה עם %{accounting}",
    RECEIPTS_INVOICES_CONNECT_CONFIRM: "התחבר ל%{accounting}",
    RECEIPTS_INVOICES_SYNC_STUDENTS_CONFIRM: "סנכרן תלמידים/ות",
    RECEIPTS_INVOICES: "הפקת מסמך",
    RECEIPTS_INVOICES_NEW_RECEIPT: "שלח/י קבלה",
    RECEIPTS_INVOICES_OPEN_RECEIPT: "פתח/י קבלה או חשבונית/מס קבלה",
    RECEIPTS_INVOICES_NEW_INVOICE: "שלח/י חשבונית מס קבלה",
    RECEIPTS_INVOICES_NEW_RECEIPT_PREVIEW: "שלח/י קבלה ל%{student}",
    RECEIPTS_INVOICES_NEW_INVOICE_PREVIEW: "שלח/י חשבונית מס קבלה ל%{student}!",
    RECEIPTS_INVOICES_USERNAME: "שם משתמש iCount (תחילת המייל ללא ה-@ ואילך)",
    RECEIPTS_INVOICES_COMPANY_ID: "מזהה חברה (אנגלית בלי רווחים)",
    RECEIPTS_INVOICES_PASSWORD: "סיסמא iCount",
    RECEIPTS_INVOICES_ADDITIONAL: "יש צורך במידע נוסף",
    RECEIPTS_INVOICES_ADDITIONAL_BANK: "מספר הבנק של הצ\'ק",
    RECEIPTS_INVOICES_ADDITIONAL_BRANCH: "סניף הבנק של הצ\'ק",
    RECEIPTS_INVOICES_ADDITIONAL_ACCOUNT: "מספר חשבון של הצ\'ק",
    RECEIPTS_INVOICES_NEW_RECEIPT_CREATED: "קבלה נוצרה ונשלחה ל%{student}!",
    RECEIPTS_INVOICES_NEW_INVOICE_CREATED: "חשבונית מס קבלה נוצרה ונשלחה ל%{student}!",
    VAT_EXEMPT_MESSAGE: "האם הנך עוסק.ת פטור.ה?",
    ACCOUNTING_LIMIT_REACHED_TITLE: 'לא ניתן להוציא מסמך עם מחיר הגבוה מ-20,000₪ דרך המערכת של מדידייט',
    ACCOUNTING_LIMIT_REACHED_MESSAGE: 'אנא כנסו למערכת Grow (משולם) והפיקו את המסמך באיזור הפקת מסמכים->הפקת מסמך',
    SHEET_DROPZONE_BOTTON: "ייבא תלמידים/ות",
    SHEET_DROPZONE_TITLE: "קובץ ייבוא תלמידים/ות מסוג (XLSX, CSV)",
    SHEET_DROPZONE_LOADING: "מייבא את התלמידים/ות למדידייט...",
    SHEET_DROPZONE_COMPLETE: "%{students} תלמידים/ות יובאו בהצלחה!",
    SHEET_DROPZONE_ERROR: "הייבוא נכשל..פורמט לא נכון או אין מספיק מידה במסמך..",
    SHEET_DROPZONE_EXAMPLE: "הורד קובץ ייבוא תלמידים/ת לדוגמא",
    POLICY_REFUND_MESSAGE_PART_1: '<b>ניתן לבטל עד</b>',
    POLICY_REFUND_MESSAGE_PART_2: '<b>שעות לפני תחילת השיעור באפליקציה (הערך 0 = אין מגבלה)</b>',
    POLICY_REGISTER_MESSAGE_PART_1: '<b><u>התחל</u> הרשמה מ-</b>',
    POLICY_REGISTER_MESSAGE_PART_2: '<b>שעות לפני תחילת השיעור באפליקציה (הערך 0 = אין מגבלה)</b>',
    POLICY_END_REGISTER_MESSAGE_PART_1: '<b><u>הפסק</u> הרשמה מ-</b>',
    POLICY_END_REGISTER_MESSAGE_PART_2: '<b>שעות לפני תחילת השיעור באפליקציה (הערך 0 = אין מגבלה)</b>',
    REGISTRATION_LINK: 'לינק הרשמה לתלמידים/ות:',
    REGISTRATION_LINK_TOOLTIP: "לינק זה מאפשר רישום עצמאי של התלמידים/ות לסטודיו שלך.<br/><br/>התלמידים/ות יופנו לעמוד רישום למערכת מדידייט, ומיד לאחר מכן יקבלו מייל המנחה להוריד את אפליקציית מדידייט עם פרטי כניסה אישיים. לאחר הכניסה לאפליקציה יוכלו לראות את השיעורים שלך ולהתחיל בפעילות מול הסטודיו.<br/><br/>את הלינק ניתן להעתיק ולהעביר לתלמידים/ות בכל דרך שתרצה/י: דרך הודעה, וואטסאפ, פייסבוק או אפילו כפתור באתר הבית שלך.",
    ERROR_SAVING: 'אירעה שגיאה בזמן שמירה',
    COPY_PAYMENT_LINK: 'העתק לינק לתשלום',
    MEDIDATE_SUBSCRIPTION_CREATED: 'מנוי למדידייט נוצר בהצלחה!',
    INVITE_STUDENT_TO_THE_APP: 'הזמנת תלמיד/ה לאפליקציה',
    DONT_ALLOW_CANCELATION_POLICY_RANGE: 'לא לאפשר לתלמידים שנמצאים בטווח מדיניות ביטול לצאת מהשיעור דרך האפליקציה למרות שהם לא מקבלים החזר על השיעור',
    DONT_ALLOW_CANCELATION_POLICY_RANGE_TOOLTIP: 'אפשרות זו נועדה למקרה והסטודיו אינו מעוניין לאפשר לתלמידים לעזוב שיעור בטווח מדיניות ביטול למרות שהתלמיד/ה אינם מקבלים החזר עליו ביציאה מהשיעור. זו לא האפשרות האידיאלית כשרוצים שתלמידים שיודעים שלא יגיעו לשיעור שיפנו מקום לתלמידים שכן יכולים להגיע.',
    ALLOW_TEACHER_EDIT: 'אפשר למורים שליטה על נוכחות תלמידים בשיעור',
    ALLOW_TEACHER_EDIT_TOOLTIP: 'כאשר מורה מלמד שיעור תחת הסטודיו הוא יוכל להכניס ולהוציא תלמידים מאותו שיעור',
    ALLOW_TEACHER_SENSITIVE_INFO: 'אפשר גישה למורים למידע על תלמידים',
    ALLOW_TEACHER_SENSITIVE_INFO_TOOLTIP: 'אפשר גישה למידע רגיש על התלמיד כמו הצהרת בריאות ומצב בריאותי.',
    CHOOSE_BUSINESS_COUNTRY: 'אנא ביחרו את המדינה בה העסק פועל',
    CANCELLED_MEMBERSHIP_SUCCESS: 'חידוש התשלומים בוטל בהצלחה',
    LEARN_MORE: 'למדו עוד',
    B2C_COMMISSION_SPLIT_TITLE: 'חלוקת עמלת הסליקה של מדידייט',
    B2C_COMMISSION_SPLIT_MESSAGE: 'מדידייט גובה עמלת שירות של 1%, לרוב העמלה נלקחת מלקוחות הקצה (הלקוחות שלך), במידת הצורך כוונו את החלוקה לפי צורת התמחור של העסק.',
    B2C_COMMISSION_SPLIT_DESC: 'עמלה של 1% נוספת למחיר הסופי בקופה מול הלקוח, לכן אם מחיר מוצר הוא 100, והחלטת, למשל, במקרה וחלוקת האחוזים הינה 0.75% על הלקוח ו-0.25% על חשבון העסק, מחיר המוצר בקופה יהיה סה"כ 100.75.',
    MY_BUSINESS: 'העסק שלי',
    MY_CLIENTS: 'התלמידים שלי',
    CONNECT_GROW_USER_TO_MEDIDATE_TITLE: 'חבר את המשתמש הקיים של גרואו למדידייט באמצעות הכנסת ה-objectId של המשתמש מדידייט וה-Grow userId',
    CONNECT_GROW_USER_TO_MEDIDATE_USER_TITLE: '1. צרו קשר עם Grow ובקשו להיות עסק דרך מדידייט.\n2. תקבלו מזהה משתמש.\n3. העתיקו את מזהה המשתמש.\n4. הדביקו בשדה למטה',
    SNOOZE_STUDENT_TITLE: 'תזכורת לתלמידים שלא הגיעו לשיעורים מספר שבועות מוגדר',
    SNOOZE_STUDENT_TOOLTIP: 'אפשרות לשליחת התראה לתלמיד שלא היה באינטראקציה עם הסטודיו (לא הגיע לשיעורים מספר שבועות) X זמן\n* אינטראקציה יכולה לכלול הצטרפות לשיעור בתשלום חד פעמי, שימוש בכרטיסיה או שימוש במנוי',
    SNOOZE_STUDENT_MESSAGE_PART_1: '<b>שליחת התראה לתלמיד לאחר</b>',
    SNOOZE_STUDENT_MESSAGE_PART_2: '<b>שבועות</b>',
    SNOOZE_STUDENT_MESSAGE_PART_3: '<b>אם לא נכח/ה בשיעורים בטווח זמנים שנבחר (ולא רשומ/ה לשיעורים עתידיים)</b>',
    NO_INTERACTION_MESSAGE_TITLE: 'כותרת ההודעה',
    NO_INTERACTION_MESSAGE_TITLE_PREFIX: 'היי @תלמיד',
    NO_INTERACTION_MESSAGE_TITLE_PLACEHOLDER: 'היי @תלמיד, מתגעגעים אליך כאן בסטודיו',
    NO_INTERACTION_MESSAGE_TITLE_ACTUAL: 'כותרת ההודעה בפועל:',
    NO_INTERACTION_MESSAGE_DESCRIPTION: 'תיאור ההודעה',
    NO_INTERACTION_MESSAGE_DESCRIPTION_PLACEHOLDER: 'ראינו שלא היית באינטראקציה עם הסטודיו במשך שבועות\nנשמח לראותך @סטודיו.\nהסטודיו שלנו מחכה לך!',
    NO_INTERACTION_MESSAGE_DESCRIPTION_ACTUAL: 'תיאור ההודעה בפועל:',
    NO_INTERACTION_MESSAGE_TAGS: 'הוסף תגיות להודעה כדי להכליל אותה לתלמידים בעלי תגיות דומות',
    NO_INTERACTION_MESSAGE_TAGS_TIP: 'תגיות אלו יהפכו לערכים אמתיים בהודעה (שם הסטודיו או שם התלמיד). לדוגמה: @תלמיד, @סטודיו',
    NO_INTERACTION_MESSAGE_TAGS_PLACEHOLDER: 'הוסף תגיות להודעה כדי להכליל אותה לתלמידים בעלי תגיות דומות',
  },
  whatsapp: {
    CONNECTED_NOTIFICATIONS_WHATSAPP: 'נוטיפיקציות ווצאפ פעילות',
    DISCONNECTED_NOTIFICATIONS_WHATSAPP: 'נוטיפיקציות ווצאפ כבויות',
    SCAN_QR: "סרקו את הקוד בעזרת אפליקציית ווצאפ",
    HOW_SCAN_QR: "איך לסרוק את הקוד בעזרת ווצאפ (הסבר)",
    CONNECTED_WHATSAPP: "התחברות לווצאפ עברה בהצלחה..",
    DISCONNECTED_WHATSAPP: "התנתקות מווצאפ בוצעה..",
    CONNECT_WHATSAPP: 'התחברות לווצאפ',
    DISCONNECT_WHATSAPP: 'התנתקות מווצאפ',
    CONNECT_WHATSAPP_TOOLTIP: 'התחברו לווצאפ שלכם בכדי להוסיף אוטומציות ויכולות של ווצאפ לתקשורת עם התלמידים',
    DISCONNECT_WHATSAPP_TOOLTIP: 'התנתקות מווצאפ בכדי להפסיק לשלוח תזכורות ונוטיפיקציות לתלמידים גם דרך הווצאפ',
    WA_SESSION_EMAIL_INVITE: 'שלח לי ווצאפ כאשר מזמינים אותי לשיעור',
    WA_TOGETHER_EMAIL_INVITE: 'שלח לי ווצאפ כאשר מזמינים אותי לתרגל יחד',
    WA_SESSION_EMAIL_JOINED: 'שלח לי ווצאפ כשמישהו/י מצטרפ/ת לשיעורים שלי',
    WA_SESSION_EMAIL_LEFT: 'שלח לי ווצאפ כשמישהו/י עוזב/ת שיעורים שלי',
    WA_SESSION_EMAIL_ENTERED_WAITING_LIST: 'שלח לי ווצאפ כשתלמיד/ה נכנס/ה לרשימת המתנה של שיעור',
    WA_SESSION_EMAIL_JOIN_POLICY: 'שלח לי ווצאפ כשיש ניסיון להירשם מחוץ לטווח מדיניות הרישום',
    WA_SESSION_EMAIL_LEAVE_POLICY: 'שלח לי ווצאפ כשיש ניסיון לביטול רישום בטווח מדיניות הביטול',
    WA_SESSION_EMAIL_AUTO_PUNCH_FAILED: 'שלח לי ווצאפ כאשר לא היה ניתן לנקב אוטומאטית כרטיסיה או מנוי לתלמיד/ה קבוע/ה בשיעור',
    WA_PLAN_EMAIL_ENDING: 'שלח ווצאפ לתלמידים/ות במידה והכרטיסיה או מנוי הולך להסתיים',
    WA_PLAN_EMAIL_RENEWING_STUDIO: 'שלח לי ווצאפ במידה והמנוי החודשי (שמתחדש אוטומטית) עומד להתחדש אצל תלמידים/ות ',
    WA_PLAN_EMAIL_RENEWED_STUDIO: 'שלח לי ווצאפ לאחר שהמנוי החודשי (שמתחדש אוטומטית) התחדש אצל תלמידים/ות',
    WA_PLAN_EMAIL_RENEWING: 'שלח ווצאפ לתלמידים/ות במידה והמנוי החודשי (שמתחדש אוטומטית) עומד להתחדש',
    WA_PLAN_EMAIL_RENEWED: 'שלח ווצאפ לתלמידים/ות לאחר המנוי החודשי (שמתחדש אוטומטית) התחדש',
    WA_SEND_EMAIL_INVITES: 'שלח ווצאפ הזמנה לאפליקציה לתלמידים/ות שמתווספים/ות לסטודיו',
    WA_SEND_EMAIL_INVITE_WITH_HEALTH_DECLARATION: 'שלח הצהרת בריאות יחד עם ווצאפ ההזמנה',
    WA_SEND_EMAIL_INVITE_WITH_TERMS: 'שלח את תקנון הסטודיו יחד עם ווצאפ ההזמנה',
    WA_PUSH_SESSION_STARTING: 'שלח ווצאפ על שיעור שעומד להתחיל',
    WA_PUSH_BIRTHDAY: 'שלח ווצאפ על יום שני של תלמיד/ה',
  },
  userPage: {
    TITLE: 'פרטי תלמיד/ה',
    USER_PAGE_TITLE: 'העמוד של %{name}',
    ATTENDED_SESSIONS: 'שיעורים שהשתתפ/ה',
    SESSIONS_ATTENDED: '%{name} השתתפ/ה ב- <b>(%{sessionsCount})</b> שיעורים בטווח זמנים שנבחר',
    SESSIONS_UNREFUNDED: '%{name} יצא/ה מ- <b>(%{sessionsCount})</b> שיעורים בטווח מדיניות הביטול - בטווח זמנים שנבחר',
    SESSIONS_TAUGHT: '%{name} העביר/ה <b>(%{sessionsCount})</b> שיעורים בסטודיו שלך בטווח זמנים שנבחר',
    HEALTH_CONDITION: 'מצב בריאותי',
    HEALTH_STATEMENT: 'הצהרת בריאות',
    RESEND_INVITATION_CONFIRM: 'פעולה זו תשלח עוד הזמנה למייל התלמיד/ה להצטרף למדידייט. להמשיך?',
    RESEND_TERMS_CONFIRM: 'פעולה זו תבצע שליחה מחדש של תקנון הסטודיו למייל התלמיד/ה, להמשיך?',
    ATTENDING_SESSIONS_CARD_TITLE: 'שיעורים %{name} נכח/ה בהם',
    UNREFUNDED_SESSIONS_CARD_TITLE: 'שיעורים ש-%{name} יצא/ה מהשיעור באיחור',
    TAUGHT_SESSIONS_CARD_TITLE: 'שיעורים שהעביר/ה %{name} בסטודיו שלי',
    STUDENT_PLANS_CARD_TITLE: 'הכרטיסיות ומנויים של %{name}',
    SAVE_STARTING_DATE_FOR_STUDENT: 'שמירת תאריך התחלה לתלמיד/ה זו',
    SAVE_STARTING_DATE_FOR_STUDENT_TOOLTIP: 'בכל פעם שתפתחו את העמוד של התלמיד/ה תאריך זה יישמר לפעמים הבאות'
  },
  affiliation: {
    AFFILIATE_LINK_TITLE: 'Medidate Affilates Program',
    AFFILIATE_LINK: 'Invite your colleagues and other business owners to use Medidate and get rewarded on each new subscriber',
    AFFILIATE_LINK_TOOLTIP: 'Invite your fellow teachers/studio owners to use Medidate using your signup affiliate link.<br/><br/>For each studio or teacher that starts a Medidate subscription through your affiliation link, you will receive <b>200₪</b> (for a monthly Medidate Membership) or <b>300₪</b> (for a yearly Medidate Membership) and <b>the new subscriber</b> that just signedup through your link gets <b>extra 14 days</b> free of charge to his 30 days trial already granted.',
    AFFILIATE_LINK_TITLE: 'תכנית השותפים של מדידייט',
    AFFILIATE_LINK: 'הזמינו חברים למקצוע ובעלי עסקים להשתמש במדידייט, על כל מנוי שהם יעשו אתם.ן תרוויחו כסף',
    AFFILIATE_LINK_LONG: '<br/> מה מקבלים: <br/><br/> <b>200₪</b> (על מנוי חודשי למדידייט) <br/><br/> <b>300₪</b> (על מנוי שנתי למדידייט) <br/><br/>וגם למנוי מדידייט של הסטודיו שנכנס יתווספו 14 ימי נסיון בנוסף על ל-30 יום הקיימים כבר עכשיו!<br/><br/>את הלינק ניתן להעתיק ולהשתמש בכל דרך שתרצה/י: דרך הודעה, וואטסאפ, פייסבוק או אפילו כפתור באתר הבית שלך',
    AFFILIATE_LINK_TOOLTIP: '<br/>הזמינו חברים למקצוע ובעלי עסקים להשתמש במדידייט ועל כל אחד או אחת שיתחיל לשלם לנו על מנוי תקבלו <b>200₪</b> (על מנוי חודשי למדידייט) או <b>300₪</b> (על מנוי שנתי למדידייט) וגם למנוי מדידייט של הסטודיו שנכנס יתווספו 14 ימי נסיון בנוסף על ל-30 יום הקיימים כבר עכשיו!',
    AFFILIATE_POPUP: 'הזמינו חברים למקצוע ובעלי עסקים וקבלו תקבלו <b>200₪</b> (על מנוי חודשי למדידייט) או <b>300₪</b> (על מנוי שנתי למדידייט) וגם למנוי מדידייט של הסטודיו שנכנס יתווספו 14 ימי נסיון בנוסף על ל-30 יום הקיימים כבר עכשיו! (תוכלו למצוא את לינק השיתוף גם בעמוד הפרופיל)',
    AFFILIATE_TOTAL_FINISHED: 'הזמנות שותפים שהושלמו',
    AFFILIATE_TOTAL_FINISHED_TIP: 'לינקים להזמנת שותפים היו בשימוש על ידי משתמשים ואותם משתמשים גם נרשמו למנוי במדידייט',
    AFFILIATE_TOTAL_PENDING: 'הזמנות שותפים ממתינות',
    AFFILIATE_TOTAL_PENDING_TIP: 'לינקים להזמנת שותפים היו בשימוש על ידי משתמשים ואותם משתמשים עדיין לא נרשמו למנוי במדידייט',
    AFFILIATE_TOTAL: 'סה"כ הזמנות שותפים',
    AFFILIATE_SUMMARY_TITLE: 'סיכום הזמנות שותפים',
    AFFILIATE_VERIFY: 'אישור תנאי תכנית השותפים',
    AFFILIATE_WARNING: 'שימו לב: לא נוכל להעביר את הרווחים שלכם.ן עד שתחתמו על תנאי השימוש של תכנית השותפים של מדידייט',
    AFFILIATE_WARNING_SUBTITLE: '* ההזמנות שלכם.ן ימשיכו להצטבר ויחכו עד לאחר אישור תנאי השימוש',
    AFFILIATE_BANK_TITLE: "שלחו את הרווחים שלי לחשבון בנק:",
    AFFILIATE_USER_REGISTERED: "אני כבר רשום.ה למדידייט, התחבר ",
    AFFILIATE_USER_REGISTERING_FROM_INVITE_NOTE: "*אין צורך בכרטיס אשראי, תקבלו 30 יום נסיון חינם (עם כל היכולות של מדידייט) + 14 יום נסיון נוספים בזכות %{studio_name} ",
    AFFILIATE_USER_REGISTERING_FROM_INVITE_NO_REWARD_NOTE: "*אין צורך בכרטיס אשראי, תקבלו 30 יום נסיון חינם (עם כל היכולות של מדידייט) %{studio_name} ",
    AFFILIATE_STEPS_TITLE: "ככה זה עובד:",
    AFFILIATE_STEPS_1: "הירשמו לתוכנית השותפים שלנו.",
    AFFILIATE_STEPS_2: "קבלו קישור הפניה ייחודי לשיתוף.",
    AFFILIATE_STEPS_3: "הזמינו אחרים להירשם באמצעות הקישור שלך.",
    AFFILIATE_STEPS_4: "הרוויחו תגמולים ברגע שהאדם המופנה נרשם לתוכניות בתשלום שלנו.",
    AFFILIATE_STEPS_TERMS_SHORT: "סכום התגמול תלוי בתוכנית המנוי:",
    AFFILIATE_STEPS_FOOTER_1: "אם המשתמש המופנה נרשם לתוכנית חודשית, תקבלו תגמול סטנדרטי.",
    AFFILIATE_STEPS_FOOTER_2: "עבור מנויים שנתיים, התגמול גדול יותר, ומציע לך הטבות נוספות.",
  },
  session: {
    SESSION_EDIT_TITLE: 'עריכת %{title}',
    SESSION_ADD_TITLE: '+ הוספ/י שיעור',
    ADD_NEW_BUTTON: '+ הוספ/י שיעור חדש',
    CHECK_UNPAID_SESSIONS: 'סמנ/י שיעורים שלא הוסדרו עד הסוף',
    CHECK_UNPAID_SESSIONS_TOOLTIP: 'אפשרות זו תציג דגל אדום על גבי שיעורים שלא הוסדרו עד הסוף. כלומר, שיעורים שיש בהם תלמידים רשומים אך מתחת ל"שולם" רשום עדיין המילה "לא" אצל תלמיד.ה אחד.ת או יותר',
    MARK_UNREFUNDED_SESSIONS: 'סמנ/י שיעורים עם תלמידים/ות שיצאו באיחור',
    MARK_UNREFUNDED_SESSIONS_TOOLTIP: 'אפשרות זו תציג דגל אדום על גבי שיעורים בהם יש תלמידים/ות שיצאו משיעורים באיחור ולא קיבלו החזר על השיעור',
    LABEL_TITLE: 'שם השיעור',
    LABEL_TITLE_TOOLTIP: 'שם או כותרת שהכי מתאים לאופי השיעור או סדנה או רטריט שאתם/ן יוצרים במערכת. לדוגמא: \"שיעור יוגה ויניאסה למתחילים\"',
    LABEL_DESCRIPTION: 'תיאור השיעור',
    LABEL_DESCRIPTION_TOOLTIP: 'מאפשר הוספה של מידע הכרחי על השיעור או סדנה',
    LABEL_ADDRESS: 'כתובת השיעור',
    LABEL_ADDRESS_TOOLTIP: 'הכניסו את הכתובת שבו השיעור הולך להתקיים. התחילו בלרשום את הכתובת ולבחור מרשימת הכתובות המוצעת כדי שהסמן במפה יציג את המיקום המדוייק',
    LABEL_DATE: 'תאריך השיעור',
    LABEL_DATE_TOOLTIP: 'תאריך בו השיעור מתקיים',
    LABEL_TIME: 'שעת תחילת השיעור',
    LABEL_TIME_TOOLTIP: 'השעה שבה השיעור מתחיל.',
    LABEL_END_TIME: 'תאריך סיום השיעור (אופציונאלי)',
    LABEL_END_TIME_TOOLTIP: 'התאריך שבו השיעור מסתיים.',
    LABEL_END_TIME_PLACEHOLDER: 'מסתיים בתאריך?',
    LABEL_REPETITION: 'חזרתיות של השיעור',
    LABEL_REPETITION_TOOLTIP: 'כאן מגדירים אם השיעור הינו חד פעמי/שבועי/חודשי והשיעור ישתכפל או לא ישתכפל הלאה לפי מה שבחרתם/ן.המערכת מציגה רק 4 שבועות קדימה בכל פעם. הערה: מרגע ייצור השיעור לוקח כ20 דק\' עד שהוא משתכפל לשבוע הבא',
    LABEL_OCCURANCE_DAYS: 'ימים',
    HOUR: '%{number} שעה',
    HOURS: '%{number} שעות',
    WEEK: '%{number} שבוע',
    WEEKS: '%{number} שבועות',
    MONTH: '%{number} חודש',
    MONTHS: '%{number} חודשים',
    ADD_PICTURE: 'הוספ/י תמונה לשיעור',
    LABEL_TEACHER: 'מורה שמנהל/ת את השיעור',
    SAVE_BUTTON: 'שמירת השיעור',
    IN_WAITING: 'ברשימת המתנה',
    EXIT_WAITING: 'צא מרשימת המתנה',
    SEND_INFO_TO_TEACHER: 'שלח/י מידע אודות השיעור למורה',
    SEND_INFO_TO_TEACHER_TOOLTIP: 'שולח מייל עם כל המידע על התלמידים למורה שמעביר את השיעור',
    LABEL_SESSION_TYPE: 'סוג שיעור',
    LABEL_SESSION_TYPE_TOOLTIP: 'מה סוג השיעור שמועבר: יוגה, מדיטציה, טאי צ\'י, פילאטיס וכו\'',
    LABEL_STYLE: 'סגנון',
    LABEL_STYLE_TOOLTIP: 'סגנון השיעור הנלמד בשיעור לפי סוג השיעור: אשטנגה, ויניאסה, נשים וכו\'',
    LABEL_SESSION_COLOR: 'צבע השיעור',
    LABEL_LIMIT: 'מגבלת משתתפים/ות',
    LABEL_LIMIT_TOOLTIP: 'במידה ולסטודיו יש מגבלת מקום יש להכניס את המגבלה כאן. במידה וכמות המשתתפים/ות הגיעה למגבלה הזו יתאפשר לתלמיד/ה להירשם דרך האפליקציה לרשימת המתנה. במידה ומקום מתפנה התלמיד/ה יקבלו ידיעה על כך ויוכלו למלא את המקום החסר',
    LABEL_TRIAL_PRICING: 'מחיר לשיעור ניסיון',
    LABEL_TRIAL_PRICING_TOOLTIP: 'מחיר השיעור כפי שיופיע לתלמידים אשר זכאים לשיעור ניסיון בתוך האפליקציה (0 = כניסה בחינם לשיעור)',
    LABEL_PRICING: 'תמחור',
    LABEL_PRICING_TOOLTIP: 'מחיר השיעור כפי שיופיע בפרטי השיעור באפליקציה. מחולק ל-חינם ומחיר.<br/><br/>חינם: במידה והשיעור הוא חינמי.<br/><br/>מחיר: מחיר לכניסה חד פעמית (לא ניסיון)',
    LABEL_PAID: 'סומן כשולם',
    STUDENT_STATUS: 'סטטוס הגעה',
    LABEL_UNPAID: 'סמן כלא שולם',
    LABEL_PAID_ADDITIONAL: 'מידע נוסף על התשלום',
    LABEL_PARTIAL_PAID_AMOUNT: 'סכום ששולם',
    LABEL_OPTIONAL_PAID_AMOUNT: 'סכום ששולם (אופציונלי)',
    LABEL_CHECKS_NUMBER: 'מספר צ\'קים',
    LABEL_SINGLE_CHECK_NUMBER: 'מספר צ\'ק',
    LABEL_SINGLE_CHECK_DATE: 'תאריך',
    LABEL_ATTENDING_STUDENTS: 'תלמידי/ות רשומים/ות <b>(%{count})</b>',
    LABEL_ATTENDING_STUDENTS_TOOLTIP: 'תלמידים/ות הרשומים/ות לשיעור הנוכחי. בכל שורת תלמיד/ה שרשום/ה יש:<br/><br/><b>הערות</b> - הערות הקשורות לאותו תלמיד/ה ו-לשיעור זה בלבד. לדוגמא: הגיעה עם גב תפוס.<br/><br/><b>שולם</b> - האם התלמיד/ה סגר/ה את התשלום על השיעור הנוכחי. תשלום בודד, או נרשם/ה עם כרטיסיה או מנוי.<br/><br/><b>קבוע/ה בשיעור</b> - מסמן את התלמיד/ה כקבוע/ה לשיעור הזה ובאופן אוטומטי תתווסף לכל השיעורים העתידיים של השיעור הזה ומקום יישמר לו/ה מראש',
    LABEL_UNREFUNDED_STUDENTS: 'תלמידי/ות שיצאו מהשיעור ללא החזר <b>(%{unrefunded_count})</b>',
    LABEL_UNREFUNDED_STUDENTS_NO_COUNT: 'תלמידי/ות שיצאו מהשיעור ללא החזר ',
    LABEL_UNREFUNDED_STUDENTS_TOOLTIP: 'תלמידי/ות שיצאו מהשיעור בטווח מדיניות הביטול ללא החזר על השיעור',
    LABEL_WAITING_STUDENTS: 'תלמידים/ות ברשימת המתנה <b>(%{waiting_count})</b>',
    LABEL_STATUS_STUDENTS: 'מעקב נוכחות<b>(%{student_status_count})</b>',
    LABEL_STATUS_STUDENTS_NO_COUNT: 'מעקב נוכחות',
    LABEL_STATUS_STUDENTS_TOOLTIP: 'תלמידים שהיו באינטראקציה כלשהי עם השיעור, אם הגיעו או לא הגיעו לשיעור, ואם קיבלו החזר על כך או לא.\nכל הסטטוסים נקבעים אוטומטית על ידי המערכת וניתנים לשינוי ידני.\nלא ניתן להוציא או להוסיף תלמידים דרך טבלה זו, זוהי טבלה למעקב וסטטיסטיקה בלבד', LABEL_PRICE: 'מחיר',
    LABEL_MAX_PRICE: 'מקסימום',
    LABEL_MIN_PRICE: 'מינימום',
    LABEL_ALLOW_DROPINS: 'אפשר לתלמידים/ת לרכוש באשראי מקום בשיעור',
    LABEL_ALLOW_DROPINS_TOOLTIP: 'במידה ותרצו לאפשר לתלמיד/ה לתפוס מקום בשיעור דרך האפליקציה ולשלם באשראי.<br/>* רלוונטי עבור המורה/סטודיו שרשום/ה למערכת התשלומים ומאפשר סליקה באשראי דרך המערכת',
    LABEL_NEEDS_TEACHER_APPROVAL: 'תלמיד/ה חדש/ה צריך/ה אישור לרכוש את השיעור',
    LABEL_NEEDS_TEACHER_APPROVAL_TOOLTIP: 'תלמיד/ה חדש/ה צריך/ה אישור מהמורה לרכוש את השיעור דרך האפליקציה',
    LABEL_PRIVATE_SESSION: 'שיעור פרטי - הצג למשתתפי/ות השיעור בלבד',
    LABEL_PRIVATE_SESSION_TOOLTIP: 'הגדר/י שיעור זה כשיעור פרטי. כלומר, התלמידים/ת שבאפליקציה לא יחשפו לשיעורים הללו ולא יוכלו לרשום את עצמם/ן לשיעור זה. רק התלמידים/ות שרשומים/ות לשיעור הזה יהיו חשופים/ות אליו',
    LABEL_MINIMUM_REQ: 'תנאי קיום לשיעור',
    LABEL_MINIMUM_REQ_TOOLTIP: 'הגדר/י שיעור זה עם תנאי קיום מינימליים כדי שהשיעור יתקיים בפועל.<br/>למשל: המערכת תעלה התראה למורה/סטודיו 3 שעות לפני ששיעור מתחיל אם אין לפחות 3 תלמידים רשומים. במידה והתראה זו קופצת זאת ההחלטה הבלעדית של המורה/סטודיו האם לקיים בכל זאת את השיעור או לא. אם לא אז המורה/סטודיו צריך/ה לבטל את השיעור בתוך השיעור עצמו (פעולה זו תשלח הודעה לאפליקציה של התלמידים/ות על ביטול השיעור). *המערכת לא תבטל על דעת עצמה את השיעור',
    LABEL_MINIMUM_REQ_MESSAGE_PART_1: '<b>שלח לי התראה</b>',
    LABEL_MINIMUM_REQ_MESSAGE_PART_2: '<b>שעות לפני שיעור אם יש פחות מ-</b>',
    LABEL_MINIMUM_REQ_MESSAGE_PART_3: '<b>תלמידים/ת רשומים/ות לשיעור</b>',
    LABEL_MINIMUM_REQ_ATTENDERS: 'מינימום תלמידים/ות בשיעור',
    LABEL_ZOOM_SESSION: ' שיעור אונליין (לדוגמא: זום)',
    LABEL_ZOOM_SESSION_TOOLTIP: 'הגדיר/י שיעור זה כשיעור אונליין והדביקו את הלינק אל השיעור אונליין בקופסת הטקסט. התלמידים/ות יקבלו ידיעה רבע שעה לפני תחילת השיעור עם הלינק. בנוסף התלמידים/ות יהיו חשופים ללינק באפליקציה בעמוד השיעור',
    LABEL_ZOOM_SESSION_LINK: '<b>הכניסו את הלינק לשיעור אונליין</b>',
    LABEL_ZOOM_SESSION_LINK_PLACEHOLDER: 'https://zoom.us/j/76558XXXX',
    LABEL_ZOOM_SESSION_LINK_PASSWORD: '<b>סיסמא לשיעור אונליין (אם יש)</b>',
    LABEL_ZOOM_SESSION_LINK_ALL: 'לינק זהה לכל המופעים העתידיים של שיעור זה',
    LABEL_ZOOM_SESSION_LINK_ALL_TOOLTIP: 'אפשרות זו תעתיק את הלינק והסיסמא לשיעורי האונליין הבאים של שיעור זה',
    LABEL_POLICY: 'הגדרת מדיניות ביטול ורישום לשיעור הספציפי הזה',
    LABEL_POLICY_TOOLTIP: 'קבעו את מדיניות הביטול והרישום לשיעור הספציפי הזה. מספר שעות לפני שיעור שניתן לבטל וגם להירשם בהן.',
    LABEL_TRIAL_SESSION: 'אפשר כניסה עם שיעור ניסיון',
    LABEL_TRIAL_SESSION_TOOLTIP: 'אפשר לתלמידים חדשים אשר זכאים לשיעור ניסיון להכנס לשיעור זה בהנחה שהגדרת',
    LABEL_PHONE_NUMBER: 'מספר טלפון',
    PLACEHOLDER_FILTER_SESSIONS: "סנן שיעורים",
    FILTER_UNPAID: "הצג תלמידים/ות שלא שילמו בלבד",
    FILTER_PAID_WITHOUT_RECEIPT: "הצג שולמו שלא נשלחה להם קבלה או חשבונית מס/קבלה",
    PAYMENT_OPTIONS_TITLE1: "התלמידה/ה צריך/ה לשלם?",
    PAYMENT_OPTIONS_TITLE2: "התלמיד/ה כבר שילם/ה?",
    PAYMENT_CASH_BODY: "שלח/י בקשת תשלום בכרטיס אשראי למייל התלמיד/ה. בגוף המייל יופיע פרטי המוצר וכפתור ״שלם״ כאשר בלחיצה עליו ייפתח טופס הכנסת פרטי תשלום.<br/>",
    PAYMENT_REQUEST_PRODUCT_PRICE: "מחיר <b>%{price}%{currency}</b>",
    PAYMENT_REQUEST_TEACHER_TOTAL: "הסטודיו מקבל <b>%{teacherPrice}%{currency}</b>",
    PAYMENT_REQUEST_STUDENT_TOTAL: "סה״כ כולל עמלת מדידייט <b>%{studentPrice}%{currency}</b>",

    PAYMENT_REQUEST_CTA: "שלח/י לתלמיד/ה בקשת תשלום על סך %{price}%{currency}",
    PAYMENT_CASH_CTA: "סמן/י שהתלמיד/ה שילם/ה במזומן על סך %{price}%{currency}",
    LABEL_TRIAL: 'סמן/י את <b>%{productName}</b> כ-שיעור ניסיון בשביל <b>%{studentName}</b>',
    LABEL_PARTIAL_PAID_HOW: 'סמן/י את<b>%{productName}</b> כשולם חלקית על ידי <b>%{studentName}</b> באמצעות:',
    LABEL_PAID_HOW: 'סמן/י את <b>%{productName}</b> כשולם על ידי <b>%{studentName}</b> באמצעות:',
    LABEL_NOT_PAID: 'שלח/י ל<b>%{studentName}</b> בקשת תשלום על <b>%{productName}</b>',
    PAID_MODAL_TITLE: 'מצב תשלום של %{name}',

    PAYMENT_PAID_ALREADY: "<b>התלמידה/ה כבר שילם/ה</b> <span style=color:purple;>%{price}%{currency}</span> <b>באמצעות</b> <span style=color:purple;>%{paymenMethod}</span>",
    PAYMENT_CASH_CANCEL_BODY: 'האם לסמן את התלמיד/ה כ\"לא שולם/ה\"?',
    PAYMENT_TICKET_BODY: 'ניקוב השיעור עם הכרטיסיה <b>%{planName}</b> של התלמיד/ה?',
    PAYMENT_TICKET_CANCEL_BODY: 'האם לבטל את הניקוב לשיעור עם הכרטיסיה הבאה? - <b>%{planName}</b>?',
    PAYMENT_MEMBERSHIP_BODY: 'ניקוב השיעור עם המנוי - <b>%{planName}</b> של התלמיד/ה?',
    PAYMENT_MEMBERSHIP_CANCEL_BODY: 'האם לבטל את הניקוב לשיעור עם המנוי הבא? - <b>%{planName}</b>?',
    PUNCH: 'ניקוב',
    UNPUNCH: 'ביטול ניקוב',
    SIGN: 'רישום עם מנוי',
    UNSIGN: 'ביטול רישום',
    PAY: 'תשלום',
    JOIN_SIMPLE: 'הצטרפות',
    SIGNIN_SIMPLE: 'כניסה',
    JOIN: 'הרשמה לשיעור',
    JOIN_DISCOUNT: 'הצטרף לשיעור (בהנחה)',
    LEAVE: 'יציאה מהשיעור',
    ATTENDERS: 'תלמידים/ות %{number}',
    PENDING_PAYMENTS_TOOLTIP: 'תשלומים בהמתנה',
    ATTENDERS_TOOLTIP: '%{count} תלמידים/ות',
    WAITING_TOOLTIP: '%{count} המתנה',
    FUKYOU: '%{count} המ',
    JOINED: 'הצטרפת לשיעור בהצלחה!',
    SESSION_CANCEL_CONFIRM: 'בטוח שברצונך לבטל את השיעור?\nחשוב:\n1. אנא ודאו שברשימת הנוכחות בשיעור לכולם מופיע “לא” בשולם, במידה וצריך להחזיר ניקובים וכו׳.\n2.התלמידים שרשומים בנוכחות השיעור יקבלו התראה על הביטול במידה והתראות וואצאפ פעילות. \n3.אם תרצו לבטל את השיעור כליל אז וודאו אותו דבר גם לשיעורים העתידיים',
    CANCEL_SESSION_BUTTON: 'ביטול שיעור',
    NO_SESSIONS: "רשימת השיעורים ריקה בטווח הזמנים הנבחר",
    NO_SESSIONS_DISABLED: "עדיין לא הצטרפת לשום שיעור",
    SESSION_DATE_OTHER_TZ: '* זמן עולמי שונה',
    ASSOCIATED_PLANS_TITLE: 'כרטיסיות ומנויים המאפשרים כניסה לשיעור',
    ASSOCIATED_PLANS_TITLE_TOOLTIP: 'כאן ניתן להגדיר אילו מנויים וכרטיסיות תקפות לשיעור זה. כלומר, כרטיסיה או מנוי שדולקים (הסוויצ\' ירוק) מאפשרים לתלמידים/ות להירשם דרך האפליקציה. בנוסף כאשר תסמנו את התלמיד/ה כ-\"שילם או לא\" אז במידה ולתלמיד ברשותו כרטיסיה המאפשרת כניסה תוכלו לנקב את הכרטיסיה או המנוי למטה ברשימת הנוכחות',
    ASSOCIATED_TICKETS: 'כרטיסיות',
    ASSOCIATED_MEMBERSHIPS: 'מנויים',
    SAVE_SESSION_CONFIRM: 'לשיעור זה ישנם מופעים עתידיים, אילו שיעורים ברצונך לעדכן?',
    THIS_SESSION: 'השיעור הנוכחי בלבד',
    ALL_FUTURE_SESSIONS: 'שיעור זה וכל השיעורים העתידיים',
    DELETE_SESSION_CONFIRM: 'לשיעור זה ישנם מופעים עתידיים, אילו שיעורים ברצונך למחוק?',
    REACTIVATE_SESSION: 'החזר/י שיעור לפעיל',
    SHOW_WAITING_LIST: 'הצג/י רשימת המתנה',
    PRIVACY_FOR_FUTURE_SESSIONS: 'מדידייט עומד לעדכן את הפרטיות לשאר המופעים העתידיים של שיעור זה, אילו שיעורים ברצונך לעדכן?',
    DUPLICATE_EXISTING_SESSION: 'שכפל שיעור',
    CONFIRM_DUPLICATE_SESSION: 'האם לייצר שיעור חדש מהשיעור הנוכחי?',
    DUPLICATED_SESSION_TITLE: 'שיעור זה שוכפל מ- ',
    ALERT_RELATED_PLANS: 'במידה ויש צורך בלעדכן ״כרטיסיות ומנויים המאפשרים כניסה לשיעור״, לאחר שהשיעור נשמר היכנסו אליו שנית ועדכנו לפי הצורך',
    CALENDAR_LINK: 'לינק לצפייה במערכת השעות של הסטודיו (הטמיעו את מערכת השעות באתר של הסטודיו, שיעורים פרטיים לא יוצגו כאן):',
    CALENDAR_LINK_TOOLTIP: "לינק זה מאפשר צפייה במערכת השעות של הסטודיו שלך על ידי התלמידים/ות פוטנציאליים או קיימים.<br/><br/>התלמידים/ות יופנו לעמוד רישום למערכת מדידייט, ומיד לאחר מכן יקבלו מייל המנחה להוריד את אפליקציית מדידייט עם פרטי כניסה אישיים. לאחר הכניסה לאפליקציה יוכלו לראות את השיעורים שלך ולהתחיל בפעילות מול הסטודיו.<br/><br/>את הלינק ניתן להעתיק ולהשתמש בכל דרך שתרצה/י: דרך הודעה, וואטסאפ, פייסבוק או אפילו כפתור באתר הבית שלך.",
    EXTERNAL_PAYMENT_LINK: 'לינק לתשלום חיצוני',
    EXTERNAL_SESSION_LINK: 'לינק הרשמה חיצוני לשיעור',
    EXTERNAL_PAYMENT_LINK_TOOLTIP: 'השתמשו בלינק הזה בכדי לאפשר לתלמידים פוטנציאליים וקיימים להרשם ולשלם ללא צורך באפליקציית מדידייט.<br/><br/>את הלינק ניתן להעתיק ולהשתמש בכל דרך שתרצה/י: דרך הודעה, וואטסאפ, פייסבוק או אפילו כפתור באתר הבית שלך',
    EXTERNAL_ENTER_CLASSES: 'הרשמה דרך מערכת השעות',
    EXTERNAL_ENTER_CLASSES_TOOLTIP: 'אפשרו לתלמידים להרשם לשיעורים דרך מערכת השעות החיצונית.',
    NOT_IN_CLASS: 'תלמיד/ה כבר לא רשומ/ה לשיעור',
    END_AFTER_START_TIME: 'שעת סיום צריכה להיות לאחר זמן התחלה של השיעור',
    WEEKLY_RECURRING_MESSAGE: 'תזכורת: חזרתיות שבועית יכולה לקחת בערך 20 דקות עד שכל המופעים העתידיים (4 שבועות קדימה) של השיעור הזה יופיעו בלוח שיעורים מהרגע שבו השיעור נשמר',
    CHANGE_START_DATE_WARNING: 'התאריך וזמן שנבחר לסוף השיעור לפני תחילת השיעור, האם לעדכן בהתאם את זמן תחילת השיעור?',
    CHANGE_END_DATE_WARNING: 'התאריך וזמן שנבחר לתחילת השיעור אחרי סוף השיעור, האם לעדכן בהתאם את זמן סוף השיעור?',
    ENTERED_WITH_PLAN: 'כניסה בוצעה עם ניקוב כרטיסיה או המנוי שברשות התלמיד/ה',
    SEND_ALERT_SESSION_CHANGED: 'שלח התראה על השינוי לתלמידים הרשומים בשיעור',
    SEND_ALERT_SESSION_CHANGED_TOOLTIP: 'שולח התראה לתלמידים שרשומים בשיעור הנוכחי שפרטי השיעור השתנו. שימו לב, זה מעדכן רק את התלמידים שבשיעור הזה הספציפי, ולא את התלמידים שנוכחים במופעים העתידיים של שיעור זה.',    LABEL_MINIMUM_REQ: 'תנאי קיום לשיעור',
    SEND_EMAIL_WITH_INVOICE: 'שלח מייל עם המסמך',
    SEND_EMAIL_WITH_INVOICE_TOOLTIP: 'שלח עותק של המסמך ישירות לכתובת האימייל של המקבל. הוא יקבל אותו מייד עם יצירת המסמך.',
    FREE_SESSION_PLANS_NOTICE: 'לא ניתן לבחור כרטיסיות ומנויים לשיעור "חינם"'
  },
  paidModal: {
    PAID_MODAL_TITLE: 'איך %{name} שילם/ה?',
    SESSION_PAID_TITLE: 'תשלום על שיעור חד פעמי',
    SESSION_ENTER_WITH_PLAN_TITLE: 'ניקוב שיעור עם התוכנית מספר סידורי',
    SESSION_ENTERED_WITH_PLAN_TITLE: 'התלמיד/ה רשומ/ה לשיעור עם התוכנית מספר סידורי',
    PLAN_PAID_TITLE: 'תשלום על כרטיסיה או מנוי',
    TICKET_PAID_TITLE: 'תשלום על כרטיסיה',
    MEMBERSHIP_PAID_TITLE: 'תשלום על מנוי',
    MEMBERSHIP_ONE_TIME_PAID_TITLE: 'תשלום חד פעמי על כל חדשי מנוי',
    MEMBERSHIP_MONTHLY_PAID_TITLE: 'תשלום על מנוי חודש מספר %{index}',
    MEMBERSHIP_AUTO_MONTHLY_PAID_TITLE: 'תשלום אוטמאטי על חודש אחד',
    MARK_FULLY_PAID: "<b>שולם כל הסכום</b>",
    MARK_FULLY_PAID_TOOLTIP: "מקרה בו התלמיד/ה שילם/ה את מלוא סכום על המוצר",
    MARK_PARTIALLY_PAID: "<b>שולם חלקית</b>",
    MARK_PARTIALLY_PAID_TOOLTIP: "מקרה בו התלמיד/ה שילם/ה רק חלק מהסכום של המוצר ואז נרשם חוב על הסכום שנשאר. מומלץ להשאיר הערה בנושא כדי להזכיר את המקרה. <b>כאשר התלמיד/ה חוזר/ת עם היתרה יש לעדכן ל-שולם כל הסכום</b>",
    MARK_TRIAL_SESSION: "<b>שיעור ניסיון</b>",
    MARK_TRIAL_SESSION_TOOLTIP: "סמן שהתלמיד/ה הגיע/ה לשיעור זה כ-שיעור ניסיון. אופציה זו מאפשרת להכניס סכום בצורה ידנית",
    SEND_PAYMENT_REQUEST_BOLD: "<b>שלח/י בקשת תשלום באשראי</b>",
    SEND_PAYMENT_REQUEST: "שלח/י בקשת תשלום באשראי",
    GENERAL_STUDENT_NOTE: "הערה כללית על התלמיד/ה",
    REFUND_CREDIT_CARD_PAYMENT: "האם לבצע זיכוי מלא על התשלום שנעשה בכרטיס אשראי? **במידה ופיימי מחובר ל-iCount יווצר מסמך זיכוי אוטומאטית",
    PAYMENT_REQUEST_SENT: "בקשת תשלום נשלחה<br/><br/>הלינק שנשלח: %{link}",
    PAYMENT_REQUEST_URL: "לינק לתשלום: %{link}",
    PAYMENT_REQUEST_URL_TOOLTIP: "ניתן להעתיק ולשלוח את <b>הלינק לתשלום</b> אל התלמיד/ה",
    COPIED_LINK: 'לינק הועתק',
    PARTIAL_AMOUNT_RECEIVED: 'הסכום החלקי שהתקבל עד כה',
    PAYMENT_RECEIVED_USING: 'הסכום התקבל באמצעות',
    SAVE_CC: 'שמור כרטיס אשראי לחיובים עתידיים.',
    SELECT_HOW_STUDENT_PAID: 'יש לבחור איך שילם התלמיד',
    PAYMENT_STATUS_CHANGED: 'סטטוס התשלום השתנה, יש לנסות שוב',
    CHECK_ERROR: 'יש להכניס פרטי צ\'ק',
    REFUNDED: 'תשלום הוחזר',
    PUNCHED_FOR_STUDENT: 'התוכנית נוקבה לתלמיד',
    UNPUNCHED_FOR_STUDENT: 'הוחזר ניקוב לתלמיד',
    MEMBERSHIP_MONTHLY_EXCEEDED: 'נגמרו הכניסות למנוי זה לחודש הקרוב',
    MEMBERSHIP_WEEKLY_EXCEEDED: 'נגמרו הכניסות למנוי זה לשבוע הקרוב',
    MEMBERSHIP_MONTHLY_FREQUENT_EXCEEDED: 'לא ניתן לנקב את ההמנוי עקב שיעורים קבועים שהתלמיד/ה רשום/ה בהם בחודש הנוכחי, נא להוסיף תוכנית אחרת או הסירו את התלמיד/ה משיעור קבוע עתידי',
    MEMBERSHIP_WEEKLY_FREQUENT_EXCEEDED: 'לא ניתן לנקב את ההמנוי עקב שיעורים קבועים שהתלמיד/ה רשום/ה בהם בשבוע הקרוב, נא להוסיף תוכנית אחרת או הסירו את התלמיד/ה משיעור קבוע עתידי',
    UNKNOWN_ERROR: 'שגיאה לא צפויה',
    OPEN_LINK_IN_NEW_WINDOW: 'מילוי פרטי אשראי ידנית',
    SAVE: 'שמור',
    PHONE_NUMBER_NEEDS_FIXING: 'מספר הטלפון של הלקוח אינו קיים או לא תקין.\nהכניסו את המספר טלפון של הלקוח ונסו שוב.'
  },
  paidModalMembership: {
    PAID_MODAL_TITLE: 'באיזה אופן המנוי משולם?',
    ONE_TIME_FULL_PAYMENT: 'תשלום חד פעמי עבור כל חודשי המנוי',
    MANUALLY_PAYMENTS: 'בתשלומים חודשיים וינוהל ידנית ע״י הסטודיו',
    AUTO_PAYMENTS: 'תשלומים חודשיים אוטומטיים באמצעות כרטיס אשראי',
    MARK_FULLY_PAID: "סמן מנוי כשולם במלואו בתשלום אחד",
    PAYMENT_FOR_MONTH: 'תשלום עבור חודש מספר %{index} : ',

    MARK_ONE_TIME_FULL_PAYMENT_TOOLTIP: "התלמיד/ה משלמ/ת תשלום מלא חד פעמי על כל חדשי המנוי.",
    MARK_MANUALLY_PAYMENTS_TOOLTIP: "גביה חדשית על המנוי החדשי. פעם בחודש יתקבל סכום מהתלמיד/ה והסטודיו ימלא זאת ידנית.",
    MARK_AUTO_PAYMENTS_TOOLTIP: "שליחת בקשת תשלום לתשלומים אוטומטיים. פעם בחודש הכרטיס אשראי יחוייב אוטומטית עד סוף תקופת המנוי.",

    SEND_PAYMENT_REQUEST_BOLD: "<b>שלח/י בקשת תשלום באשראי</b>",
    SEND_PAYMENT_REQUEST: "שלח/י בקשת תשלום באשראי",
    GENERAL_STUDENT_NOTE: "הערה כללית על התלמיד/ה"
  },
  studentsTable: {
    LEGEND: 'מקרא:',
    FREQUENT: 'קבוע בשיעור',
    FREQUENT_WITH_PLAN: 'תלמיד/ה קבוע/ה עם תכנית בתוקף',
    FREQUENT_NO_PLAN: 'תלמיד/ה קבוע/ה ללא תכנית בתוקף',
    FROZEN_TIP: 'תכנית מוקפאת',
    FREEZE: 'הקפא/י',
    UNFREEZE: 'בטל/י הקפאה',
    FROZEN: 'מוקפא',
    ADD_BUTTON: 'הוספ/י תלמידה/ה',
    ADD_BUTTON_FROM_FOLLOWERS: 'הוסף תלמיד/ה מרשימת התלמידים שלי',
    ADD_TO_SESSION: 'הוסף תלמיד/ה לשיעור',
    ADD_TO_PLAN: 'הוסף תלמיד/ה לתוכנית',
    ADD_TO_PRODUCT: 'הוסף תלמיד/ה למוצר',
    REGULAR_REMOVE_STUDENT: 'הוצא עם החזר',
    NO_REFUND_REMOVE_STUDENT: 'הוצא ללא החזר',
    LABEL_NOTE: 'הערות כלליות',
    LABEL_NOTE_DESC: '<b>הערה על תלמיד/ה:</b> %{note}',
    LABEL_STUDENT_NOTE: 'הערה ל%{name}',
    LABEL_STUDENT_NAME: 'שם התלמיד/ה',
    NEW_STUDENT_LABEL: '+ הוספת תלמיד/ה',
    NEW_TEACHER_LABEL: '+ הוספת מורה',
    NEW_TEACHER_STUDIO_LABEL: 'הוספת מורה לסטודיו',
    CLOSE_NEW_STUDENT_LABEL: 'סגור הוספה',
    NEW_STUDENT_PLACEHOLDER: 'הכניסו כתובת אימייל להוספת תלמיד/ה חדש/ה',
    ADD_STUDENT_PLACEHOLDER: 'שם + שם משפחה או מייל',
    NO_STUDENTS_SESSION: "אף תלמיד/ה עוד לא הצטרפ/ה לשיעור זה עדיין",
    NO_STUDENTS_IN_WAITING_LIST: "אין תלמידים/ות ברשימת ההמתנה עדיין",
    NO_STUDENTS_PLAN: "תכנית זו לא נרכשה על ידי תלמידים/ות עדיין",
    NO_STUDENTS_PRODUCT: "מוצר זו לא נרכש על ידי תלמידים/ות עדיין",
    NO_STUDENTS: "אין לך תלמידים/ות שעוקבים/ות אחריך",
    SEARCH_FOR_STUDENTS: "השתמשו ישירות בשורת החיפוש לחפש תלמידים/ות בסטודיו, כבר אין צורך לטעון את רשימת כל תלמידים/ות בכל פעם",
    EXPIRATION_DATE: 'תאריך תפוגה',
    ENTRIES: 'כניסות',
    ENTRIES_USED: 'כניסות בשימוש',
    ENTRIES_LEFT: 'כניסות שנשארו',
    LABEL_PAID: 'שולם',
    STUDENT_REMOVE_CONFIRM_MSG: 'בטוח/ה שברצונך להסיר את %{name}?',
    REINSTATE_STUDENT_FROM_ARCHIVE_CONFIRM_MSG: 'בטוח/ה שברצונך להפוך את %{name} לתלמיד פעיל בסטודיו?',
    FREQUENT_STUDENT_ADD_CONFIRM_MSG: 'להוסיף את %{name} לרשימת התלמידים/ות הקבועים?',
    FREQUENT_STUDENT_REMOVE_CONFIRM_MSG: 'האם להסיר את %{name} מרשימת התלמידים/ות הקבועים לשיעור זה? פעולה זו תסיר את התלמיד/ה מהשיעורים העתידיים נא לשים לב שאין ניקוב באחד השיעורים העתידיים לאותו תלמיד/ה.',
    STUDENT_STATUS_CHANGE_CONFIRM_MSG: 'שינוי הסטטוס של %{name} לא ישנה את המצב האמיתי של התלמיד בשיעור זה מבחינת הנוכחות בפועל ונועד בכדי לסמן סטטוס הגעה בלבד.',
    STUDENT_STATUS_CHANGE_DISABLED_CONFIRM_MSG: 'שינוי הסטטוס של %{name} אינו ניתן כיוון שהתלמיד כבר עם סטטוס לפי מצב ההגעה בפועל לשיעור זה.',
    REMOVE_STUDENT_IN_POLICY_RANGE: '<b>שימו לב!</b> זמן זה אינו בטווח מדיניות הביטול המותר. האם להוציא את <b>%{name}</b> <b><u>ללא החזר</u></b> על השיעור של ניקוב/מנוי/תשלום? או להוציא את התלמיד/ה עם החזר?',
    STUDENT_ALREADY_EXISTS: 'תלמיד/ה כבר קיים/ת',
    STUDENT_CANT_ADD_YOURSELF: 'לא ניתן להוסיף את עצמך',
    SHOW_NO_ACTIVE_PLAN_STUDENTS: 'הצג רק תלמידים/ות ללא תכנית (כרטיסיה או מנוי) פעילה בלבד',
    NOTES: 'הערות: ',
    INFO: 'פתח/י עמוד מידע על תלמיד/ה',
    INFO_TEACHER: 'פתח/י עמוד מידע על המורה',
    TICKET: 'הצג תוכניות של תלמיד/ה (כרטיסיות או מנויים)',
    CHAT: 'פתח עמוד הודעות וצ׳ט',
    REMOVE: 'הסר מהרשימה',
    REMOVE_AND_ADD: 'הסרה מהרשימה והוספה לרשימת התלמידים',
    HEALTH: 'טופס הצהרת בריאות',
    ADDED_STUDENT: 'תלמיד נוסף בהצלחה',
    ADDED_LEAD: 'לקוח פונציאלי נוסף בהצלחה',
    SENT_ALERT: 'נשלחה התראה על מקום שהתפנה',
    LEAD_STATUS: 'סטטוס %{status}',
    CONTACT_LEAD: 'יצירת קשר עם %{name}',
    CANCELLED: 'מנוי תשלומים בוטל',
    PLAN_EXPIRED: 'מנוי פג תוקף',
    SAVE_FILTER: 'שמירת סינון לשימושים הבאים',
    SAVE_FILTER_TIP: 'שמירת הסינון שבחרת לשימושים הבאים (משפיע על כל הטבלהות מאותו סוג ברחבי המערכת)',
  },
  sessionsTable: {
    ADD_BUTTON: 'הוסף תלמיד/ה',
    LABEL_NOTE: 'הערות לשיעור',
    LABEL_STUDENT_NAME: 'שם התלמיד/ה',
    LABEL_SESSION_NAME: 'שיעור',
    NEW_STUDENT_LABEL: 'הוספת תלמיד/ה',
    CLOSE_NEW_STUDENT_LABEL: 'סגור הוספה',
    NEW_STUDENT_PLACEHOLDER: 'הכניסו כתובת אימייל להוספת תלמיד/ה חדש/ה',
    NO_STUDENTS_SESSION: "אף תלמיד/ה עוד לא הצטרפ/ה לשיעור הזה עדיין",
    NO_STUDENTS_PLAN: "תכנית זו לא נרכשה על ידי תלמידים/ות עדיין",
    NO_STUDENTS: "אין לך תלמידים/ות שעוקבים אחריך",
    EXPIRATION_DATE: 'תאריך תפוגה',
    ENTRIES: 'כניסות',
    ENTRIES_USED: 'כניסות בשימוש',
    ENTRIES_LEFT: 'כניסות שנשארו',
    LABEL_PAID: 'שולם',
    STUDENT_REMOVE_CONFIRM_MSG: 'את/ה בטוח/ה שברצונך להסיר את %{name}?',
    STUDENT_REMOVE_UNREFUNDED_CONFIRM_MSG: 'את/ה בטוח/ה שברצונך להסיר את %{name} משיעור זה כתלמיד/ה שלא קיבלה החזר עקב מדיניות הביטול?',
    FIRST_REMOVE_PAID_STATE: 'אנא הסירו את %{name} ממצב שולם על השיעור לפני שניתן להוציא את התלמיד/ה ממצב זה'
  },
  billing: {
    BILLING_TITLE: 'תשלומים וסליקה',
    ALREADY_REGISTERED_TO_PAYMENT: '<b>הנכם.ן כבר רשומים למערכת התשלומים</b>',
    PAYMENTS_REGISRTATION_TITLE: '<b>קבלת תשלומים והנהלת חשבונות</b>',
    PAYMENTS_REGISRTATION_INFO: '<p style="text-align:right;">חיבור למערכת התשלומים והסליקה שלנו מאפשר לתלמידים/ות לרכוש כרטיסיות, מנויים ומוצרים <strong>באשראי או בביט</strong> דרך האפליקציה כמו כן תוכלו לשלוח להם/ן בקשות תשלום באשראי על מנוי/ כרטיסיה/ מוצר למייל עם קישור לקניה (את הקישור ניתן גם להעתיק לוואצאפ).</p><p style="text-align:right;">&nbsp;</p><p style="text-align:right;"><strong>העמלות של מערכת הסליקה הן:</strong></p><p style="text-align:right;">1.&nbsp;<u>עמלת סליקה</u> - 1%&nbsp;+ 1₪ על כל סליקה (עמלת&nbsp; הסטודיו)</p><p style="text-align:right;" dir="RTL">2.&nbsp;<u>עמלת מדידייט</u> - 1% על גבי המחיר&nbsp;של המוצר (עמלה מהתלמיד/ה), כלומר מוצר שעולה 100₪&nbsp;התלמיד ישלם&nbsp;101₪.</p><p style="text-align:right;" dir="RTL">',
    PAYMENTS_REGISRTATION_INFO_COMMISION: '*ניתן לשנות את החלוקה של עמלת מדידייט (1%) כך שתחולק בין הסטודיו לתלמיד, לדוגמה: 0.5% על התלמיד ו-0.5% על העסק שלכם.',
    PAYMENTS_REGISRTATION_INFO_COMMISION2: '*ניתן להגדיר את כל ה-1% על חשבון העסק ולגלם את העמלה הזו בתמחור של המוצר, לדוגמה: אם מוצר עולה 100₪ והעסק לוקח את כל ה-1% של התלמיד עליו, ניתן להעלות את מחיר המוצר ל-101₪ ובכך לשמור על רווח כמעט זהה למחיר המוצר ללא העמלה.</p><p style="text-align:right;">&nbsp;</p><p style="text-align:right;"><span></span></p>',
    PAYMENTS_REGISRTATION_INFO_VAT: '* המחירים המוצגים הינם לפני מע״מ',
    PAYMENTS_REGISRTATION_USA_INFO: '<p>Accept credit card payments, this allows students to purchase studio Tickets/Memberships/Products through the app and you can also send payment requests via Medidate to your students.</p><p><strong>Payment Fees:</strong></p><p>1. Consumer Transaction Fee is&nbsp;(Business fee) - 2.5% + $0.3 per transaction (The Studio\'s Fee)</p><p>2. OR Corporate Transaction Fee is&nbsp;(Business fee)- 2.9% + $0.3 per transaction (The Studio\'s Fee)</p><p>3. Medidate Fee (Your client fee)- 1.2% on top of the product\'s price, so for example a Ticket costs $100 the student will pay $101.2 (This Fee is from your client and they are aware of it at checkout). In the Settings Page you can adjust a \"split\" between you and your client, for example: 0.5% from your client and 0.5% from your business.</p>',
    MUST_REGISTER_MESSAGE: '<p><span style="font-size:20px;"><strong>הירשמו למערכת התשלומים וקבלו תשלומים בכרטיסי אשראי</strong></span></p><p>על מנת לקבל תשלומים בכרטיסי אשראי יש להירשם למערכת התשלומים של מדידייט.</p><p>עמלת סליקה היא 1%+1₪ לטרנזקציה. על גבי מחיר המוצר יש תוספת של 1% שאותו התלמיד/ה משלם/ת.</p>',
    REGISTER_BUTTON: 'הרשם/י לקבלת תשלומים',
    ICOUNT_TUTORIAL: 'מדריך רישום ל-iCount',
    PAYME_TUTORIAL: 'מדריך רישום ל-PayMe',
    PAYME_AGREEMENT: 'טופס הסכם בית העסק',
    PAYME_REGISTER: 'אני מוכנ/ה, קחו אותי להירשם למערכת התשלומים',
    iCOUNT_REGISTER: 'אני מוכנ/ה, קחו אותי להירשם למערכת הנהלת החשבונות של iCount',
    OPEN_PAYME: 'פתח/י את PayMe (מערכת ניהול התשלומים)',
    REGISTER_TO_GROW_PAYMENTS: 'הרשמה למערכת הסליקה Grow(משולם)',
    PHONE_INPUT_ERROR: 'מספר הטלפון חייב להתחיל ב-05 ולהכיל בדיוק 10 ספרות.',
    FULLNAME_INPUT_ERROR: 'השם המלא חייב לכלול לפחות שתי מילים, כל אחת בת לפחות שתי אותיות, מופרדות ברווח אחד.',
    COMPANYID_INPUT_ERROR: 'מספר החברה חייב להיות בדיוק 9 ספרות.',
    USERID_INPUT_ERROR: 'מזהה משתמש צריך להיות ממספרים ואותיות באנגלית בלבד',
    IS_REQUIRED: 'שדה חובה',
    PROS: 'יתרונות',
    CONS: 'חסרונות',
    GROW_TITLE: 'Grow (משולם)',
    GROW_DESCRIPTION: 'סליקה של כרטיסי אשראי ישראליים בלבד, הנהלת חשבונות מובנית וכלולה במחיר',
    GROW_PROS_1: 'מערכת אינטואיטיבית ופשוטה לשימוש',
    GROW_PROS_2: 'כוללת הנהלת חשבונות מובנית, אין צורך בתשלום נוסף',
    GROW_PROS_3: 'שירות לקוחות נגיש, גם דרך וואצאפ',
    GROW_CONS_1: 'לא ניתן לסלוק תיירים, אזרחי ישראל בלבד',
    GROW_EXISTING_USER: 'התחבר עם משתמש קיים',
    GROW_TUTORIAL: 'מדריך חיבור',
    PAYME_TITLE: 'PayMe (פאיימי)',
    PAYME_DESCRIPTION: 'סליקה של כרטיסי אשראי בינלאומיים, הנהלת חשבונות חיצונית בתוספת מחיר (קודם פיתחו את המדריכים, והתחילו ברישום ל-iCount ורק לאחר מכן הירשמו לפיימי)',
    PAYME_PROS_1: 'ניתן לסלוק תיירים מחו״ל',
    PAYME_CONS_1: 'מצריך התחברות למערכת נוספת iCount להנהלת חשבונות',
    PAYME_CONS_2: 'מצריך תשלום חודשי נוסף למערכת iCount',
    PAYME_CONS_3: 'שירות לקוחות דרך מייל בלבד.',
    CONNECT: 'התחבר',
    OPEN: 'פתח',
    REGISTER_TO_PAYME_POPUP_MESSAGE: 'אנו ממליצים בחום קודם להירשם למערכת הנהלת החשבונות של אייקאונט, ורק לאחר מכן להתחיל את הרישום לפיימי. פיתחו בינתיים את המדריכים ועקבו אחר ההוראות.',
    REGISTER_TO_ICOUNT: 'הרשם ל-iCount',
    REGISTER_TO_PAYME: 'התחבר ל-PayMe',
    CONNECTED: 'מחובר',
    PHONE_NUMBER_NEEDS_FIXING: 'מספר הטלפון שברשותינו אינו תקין, אנא וודאו שמספר הטלפון שהזנתן.ם במערכת תקין.'
  },
  splashMerchantTypes: {
    SOLE_PROP: 'עוסק פטור',
    CORPORATION: 'תאגיד',
    LLC: 'חא\"מ',
    PARTNERSHIP: 'שותפות',
    ASSOCIATION: 'ארגון',
    NON_PROFIT: 'מלכ\"ר',
    GOVERNMENTAL: 'ממשלתי'
  },
  billingRegister: {
    TITLE: 'פרטים לקבלת תשלומים',
    REDIRECTING: 'הנך מועבר.ת לעמוד חיצוני..',
    REDIRECTING_INVOICE: 'מכין את המסמך...',
    EXPLAIN_COMMISSION_PAYME: '<p><span style="font-size:20px;"><strong>קבלת תשלום: ב-9 לכל חודש יועבר אליכם הסכום שצברתם/ן בחודש הקודם לו</strong></span></p>',
    LABEL_SOCIAL_ID: 'תעודת זהות',
    LABEL_SOCIAL_ID_ISSUED: 'תאריך הוצאת ת.ז',
    PLACEHOLDER_PHONE: 'מספר טלפון',
    PLACEHOLDER_COUNTRY: 'מדינה',
    PLACEHOLDER_CITY: 'כתובת הסטודיו/עסק',
    PLACEHOLDER_STREET: 'רחוב',
    PLACEHOLDER_STREET_NUMBER: 'מספר',
    LABEL_WEBSITE: 'אתר',
    LABEL_DESCRIPTION: 'תיאור העסק',
    LABLE_SELLECR_INC: 'סוג העסק',
    LABEL_SELLER_INC_CODE: 'מספר עוסק\\ח.פ',
    LABLE_SELLECR_BANK_CODE: 'שם בנק',
    LABEL_SELLER_BANK_BRANCH: 'מספר סניף בנק',
    LABEL_SELLER_BANK_ACCOUNT_NR: 'מספר חשבון בנק',
    NEXT_STEP: 'הבא',
    PAYME_PAPERS_EXPLANATION: 'לפי החוק במדינת ישראל אנו צריכים לוודא שאת/ה זכאי/ת לקבלת תשלומים מלקוחות. בכדי לעשות כך אנו מבקשים 3 מסמכים אשר מאששים את זהותך.',
    LABEL_FILE_SOCIAL_ID: 'צילום תעודת זהות',
    LABEL_FILE_CHEQUE: 'הוכחת חשבון בנק (צ\'ק מבוטל למשל)',
    LABEL_FILE_CORPORATE: 'צילום תעודת עוסק\\התאגדות',
    SEND_DOCUMENTS_BTN: 'שלח טפסים',
    LABEL_BUSINESS_NAME: 'שם העסק',
    SUBMIT_BTN: 'שליחה',
    LABEL_ADDRESS: 'כתובת',
    LABEL_CITY: 'כתובת הסטודיו/עסק',
    LABLE_STATE: 'State',
    LABEL_ZIP: 'מיקוד',
    LABEL_EIN: 'EIN (Federal tax id number)',
    LABEL_MERCHANT_TYPE: 'סוג עסק',
    LABEL_ROUTING: 'Bank Account Routing Nr.',
    LABEL_SPLASH_USERNAME: 'שם משתמש במערכת SPLASH',
    LABEL_SOCIAL_SECURITY_NUMBER: 'מספר תעודת זהות',
    PAYMENT_DASHBOARD_LABEL: 'לניהול התשלומים שלי',
    PAYMENT_DETAILS: 'פרטי תשלום:',
    PAYMENT_REGISTERED_LABEL: 'ההרשמה למערכת התשלומים הושלמה בהצלחה!',
    PAYMENT_REGISTERED: 'נרשמתם בהצלחה למערכת התשלומים של מדידייט, המאפשרת קבלת תשלומים בכרטיסי אשראי מהתלמידים/ות שלכם/ן.<br>בקרוב ישלח אליכם מייל עם פרטי הרשמה למערכת התשלומים (שם המשתמש והסיסמא). <br>דרך מערכת התשלומים תוכלו לעקוב אחר התשלומים שנעשו דרך מדידייט. <br>בפעם הבאה שתלחצו על "תשלומים וסליקה (PayMe)" מהתפריט צד תועברו לאתר מערכת התשלומים. <br>תודה שבחרתם לנהל את העסק שלכם דרך מדידייט, מקווים שתמצאו אותה נוחה, יעילה ופשוטה לשימוש! <br><br>בברכה, <br>צוות מדידייט',
    SPLASH_USERNAME_EXPLANATION: 'בכדי לקבל תשלומים דרך מדידייט יש צורך בהרשמה למערכת התשלומים שלנו, אימייל עם יותר פרטים ישלח אליך לאחר הרשמתך.',
    TOC_TOGGLE: 'אני מקבל/ת את התנאים בשימוש במערכת התשלומים <a target="_blank" href="https://portal.splashpayments.com/terms">תנאי שימוש</a>'
  },
  paymeIncTypes: {
    PERSON: 'עצמאי/ת',
    BUSINESS: 'חברה\\עסק'
  },
  plans: {
    MY_PLANS: 'התכניות שלי',
    TICKETS_TABLE_TITLE: 'כרטיסיות',
    TICKETS_ARCHIVED_TABLE_TITLE: 'כרטיסיות בארכיון',
    MEMBERSHIPS_TABLE_TITLE: 'מנויים',
    MEMBERSHIPS_ARCHIVED_TABLE_TITLE: 'מנויים בארכיון',
    TICKET_NO_RESULTS: "אין לך כרטיסיות עדיין.",
    MEMBERSHIP_NO_RESULTS: "אין לך מנויים עדיין.",
    NO_RESULTS_CTA: "צור כרטיסיה או מנוי",
    PLAN_NAME: 'כותרת',
    PLAN_ENTRY_NR: 'מספר כניסות',
    PLAN_ENTRY_NR_TOT: 'מספר כניסות אפשרי',
    PLAN_ENTRY_PUNCHED: 'נוקבו',
    PLAN_ASSOCIATED_SESSIONS: 'שיעורים',
    PLAN_STUDENTS: 'תלמידים/ות',
    PLAN_EXPIRATION_PREIOD: 'תקופת שימוש',
    PLAN_EXPIRATION_PREIOD_VALUE: '%{value} שבועות',
    PLAN_EXPIRATION_PREIOD_MONTH_VALUE: '%{value} חודשים',
    PLAN_PRICE: 'מחיר',
    PLAN_COLOR: 'צבע',
    UNNAMED_PLAN: 'תכנית ללא שם',
    PLAN_ACTIVATE: 'הפעלת תכנית',
    PLAN_DEACTIVATE: 'הסתר מתלמידים/ות',
    PLAN_DEACTIVATED: 'מוסתר מתלמידים/ות',
    PLAN_ACTIVATED: 'נראה לתלמידים/ות',
    PLAN_ARCHIVE: 'העבר/י לארכיון',
    PLAN_UNARCHIVE: 'החזר/י מארכיון',
    PLAN_ARCHIVED: 'הועבר לארכיון',
    PLAN_UNARCHIVED: 'הוחזר מארכיון',
    EDIT_PLAN_BTN: 'עריכת תכנית',
    MORE_BTN: ' עוד ',
    STUDENTS_MODAL_TITLE: 'תלמידים/ות ב %{title} <b>%{count}</b>',
    MUST_REGISTER_MESSAGE: '<p><span style="font-size:20px;"><strong>נהלו כרטיסיות ומנויים</strong></span></p><p>בכדי לייצר כרטיסיות ומנויים יש להרשם למערכת התשלומים.</p><p>עמלת סליקה היא 1%+1₪ לטרנזקציה. על גבי מחיר המוצר יש תוספת של 1% שאותו התלמיד משלם.</p>',
    RUNNING_TICKETS: 'כרטיסיות פעילות',
    FROZEN_TICKETS: 'כרטיסיות שהוקפאו',
    EXPIRED_TICKETS: 'כרטיסיות פגות תוקף',
    TICKETS_ISSUED: 'כרטיסיות שנוצרו',
    REMOVE_PLAN_CONFIRM_MSG: 'האם להסיר את %{planName}?',
    REMOVE_PLAN: 'מחק/י תוכנית',
    CANCEL_PLAN_PAYMENTS: 'הפסק תשלומים עתידיים',
    STUDENTS: 'תלמידים/ות',
    SUMMARY: 'סיכום תכניות',
    SUMMARY_FINISHED_TICKETS_PUNCHES_LEFT: 'כרטיסיות שהסתיימו ונשארו בהן ניקובים <b>(%{count})</b>',
    SUMMARY_FINISHED_TICKETS_NO_PUNCHES_LEFT: 'כרטיסיות שהסתיימו ולא נשארו בהן ניקובים <b>(%{count})</b>',
    SUMMARY_FROZEN_TICKETS: 'כרטיסיות שהוקפא <b>(%{count})</b>ו',
    SUMMARY_FROZEN_MEMBERSHIPS: 'מנויים שהוקפא <b>(%{count})</b>ו',
    SUMMARY_UNPAID_TICKETS: 'כרטיסיות שלא שולמ <b>(%{count})</b>ו',
    SUMMARY_UNPAID_MEMBERSHIPS: 'מנויים שלא שולמו <b>(%{count})</b>',
    SET_STUDENT_PLAN_TITLE: 'הוספ/י את <b>%{name}</b> לתכנית:',
    SET_STUDENT_PLAN: 'בחר/י תכנית',
    SET_STUDENT_PLAN_FINISH: 'התלמיד/ה נוספ/ה לתכנית בהצלחה',
    UNREFUNDED_SESSIONS: 'שיעורים ללא החזר עקב מדיניות ביטול',
    REFUND: 'החזר על השיעור',
    CONFIRM_REFUND: 'תלמיד זה יצא מהשיעור בטווח מדיניות הביטול, האם להחזיר את התשלום או ניקוב לכרטיסיה בכל זאת?',
    SEND_PAYMENT_REQUEST_TITLE: "מנוי חודשי מתחדש אוטומטית מחייב כרטיס אשראי של התלמיד",
    SEND_PAYMENT_REQUEST: "שלח/י בקשת תשלום בכרטיס אשראי למייל התלמיד/ה. בגוף המייל יופיע פרטי המוצר וכפתור ״שלם״ כאשר בלחיצה עליו ייפתח טופס הכנסת פרטי תשלום.<br/><br/>*לאחר התשלום התלמיד יתווסף אוטומטית לרשימת התלמידים של תכנית זו",
    CANCEL_PAYMENTS: 'אפשרות זו תבטל כל תשלום עתידי אוטומטי על מנוי זה ויעדכן את תוקף המנוי לפי מספר התשלומים שכבר התקבלו עד כה.<br/>להמשיך ולבצע את הפעולה?'
  },
  planEdit: {
    TITLE_EDIT: 'עריכת %{planType}: %{title}',
    TITLE_ADD: '%{planType} חדש',
    LABEL_TITLE: 'כותרת',
    LABEL_TITLE_TOOLTIP: 'שם הכרטיסיה המסביר במשפט קצר מהו אופי הכרטיסיה למשל: \"כרטיסיה 10 נקובים תקפה לחודשיים\"',
    LABEL_MEMBERSHIP_TITLE_TOOLTIP: 'שם המנוי המסביר במשפט קצר מהו אופי המנוי למשל: \"מנוי 2 כניסות בשבוע למשך 3 חודשים\"',
    LABEL_ENTRIES_NUMBER: 'מספר כניסות',
    LABEL_ENTRIES_NUMBER_TOOLTIP: 'מספר הכניסות לשיעורים שהכרטיסיה מקנה לתלמיד/ה',
    LABEL_PURCHASE_LIMIT: 'מגבלת רכישה',
    LABEL_PURCHASE_LIMIT_TOOLTIP: 'ניתן להגדיר מגבלה למספר הפעמים שתלמיד יחיד מסוגל לרכוש את המוצר הספציפי הזה',
    LABEL_PURCHASE_LIMIT_PLACEHOLDER: 'ללא הגבלה',
    LABEL_EXPIRATION_PERIOD: 'תקופת שימוש (** שימו לב אם בשבועות או חודשים)',
    LABEL_EXPIRATION_PERIOD_TOOLTIP: 'תוקף הכרטיסיה בשבועות או בחודשים. כלומר, הכרטיסיה תאפשר כניסה לשיעורים כל עוד הכרטיסיה בתוקף ולא נוצלו כל הניקובים. ברגע פתיחת כרטיסיה לתלמיד/ה, לכרטיסיה יהיה תוקף של מספר השבועות/חודשים שנבחר. כמובן שתמיד ניתן יהיה לערוך את התוקף של הכרטיסיה של תלמיד/ה מסוים במידת הצורך',
    LABEL_MEMBERSHIP_EXPIRATION_PERIOD_TOOLTIP: 'תוקף המנוי בשבועות או בחודשים. כלומר, המנוי יאפשר כניסה לשיעורים כל עוד המנוי בתוקף. ברגע פתיחת המנוי לתלמיד/ה, למנוי יהיה תוקף של מספר השבועות שנבחר, תקופת השימוש היא בקפיצות של חודשים או 4 שבועות (מפאת נוחות המערכת). כמובן שתמיד ניתן יהיה לערוך את תוקף המנוי של תלמיד/ה מסוים/ת במידת הצורך',
    ENTRIES: 'כניסות',
    LABEL_PRICE: 'מחיר',
    LABEL_PRICE_TOOLTIP: 'מחיר המלא של הכרטיסיה',
    LABEL_MEMBERSHIP_PRICE_TOOLTIP: 'המחיר החודשי של המנוי. לדוגמא: אם מנוי של שלושה חודשים עולה 600 ש"ח סה"כ, אז יש להכניס בסכום 200 ש"ח',
    LABEL_MONTHLY_PRICE: 'מחיר חודשי',
    LABEL_WEEKLY_ENTRIES: 'מגבלת מספר כניסות שבועיות',
    LABEL_WEEKLY_ENTRIES_TOOLTIP: 'הגבלת המנוי למספר כניסות בשבוע. כלומר, אם מוגדר כ-2, אז התלמיד/ה יוכל להירשם עם המנוי ל-2 שיעורים באותו שבוע ולא יוכל להירשם לשיעור שלישי',
    LABEL_WEEKLY_ENTRIES_PLACEHOLDER: 'חופשי שבועי',
    LABEL_MONTHLY_ENTRIES: 'מגבלת מספר כניסות חודשיות',
    LABEL_MONTHLY_ENTRIES_TOOLTIP: 'הגבלת המנוי למספר כניסות בחודש. כלומר, אם מוגדר כ-4, אז התלמיד/ה יוכל להירשם עם המנוי ל-4 שיעורים בחודש. ״חודש״ מוגדר לפי אם המנוי בחודשים או שבועות, אם בחודשים אז קפיצות של 30 יום ואם בשבועות בקפיצות של 4 שבועות.<br><b>כברירת מחדל, המגבלה היא לפי הרגע שנרכש המנוי, כלומר, נניח ורכשתי מנוי ב-15 לפברואר ותוקפו עד ה-15 למרץ יהיו לי 4 כניסות בטווח הזה. במידה ורוצים שהמגבלה תהיה לפי חודש קלנדרי, כלומר, במקרה שתואר קודם יפתחו לי 4 כניסות אפשריות בפברואר ועוד 4 במרץ תסמנו את האפשרות למטה ״מגבלה לפי חודש קלנדרי״</b>',
    LABEL_MONTHLY_ENTRIES_PLACEHOLDER: 'חופשי חודשי',
    LABEL_MONTHLY_ENTRIES_CALENDAR: 'מגבלה לפי חודש קלנדרי',
    LABEL_MONTHLY_ENTRIES_CALENDAR_TOOLTIP: 'אפשרות זו מגדירה את מגבלת המספר כניסות החודשיות לפי חודש קלנדרי. לדוגמא, נניח והגדרתי מגבלה של 4 כניסות בחודש ותלמיד רכש מנוי ב-15 לפבראור ותוקפו עד ה-15 למרץ. אז עד ה-28 לפברואר יהיו 4 כניסות אפשריות ובתחילת מרץ יפתחו לי עוד 4 כניסות.<br><b>לעומת זאת, אם אפשרות זו כבוייה</b>, בין ה-15 לפברואר עד ה-15 למרץ יהיו לתלמיד/ה 4 כניסות אפשריות סה״כ',
    SAVE_BTN: 'שמור',
    LABEL_COLOR: 'צבע',
    NO_SESSION: "אין לך שיעורים עתידיים",
    SESSION_IN_PLAN: 'הוספה לתכנית',
    SESSION_DATE: 'תאריך שיעור',
    SESSION_TITLE: 'כותרת שיעור',
    PLAN_SESSIONS_TITLE: 'שיעורים שתכנית זו מאפשרת כניסה אליהם',
    PLAN_SESSIONS_TITLE_TOOLTIP: 'השיעורים אליהם המנוי או כרטיסיה מאפשרים כניסה. לדוגמא אם הכפתור ליד כרטיסיה או מנוי כבויים לא תתאפשר כניסה לשיעור מסוים ולא יהיה ניתן לרשום את התלמיד/ה עם הכרטיסיה או מנוי הנ"ל',
    PLAN_NOT_EDITABLE_NOTE: 'אין אפשרות לערוך תכנית זו מכיוון שיש תלמידים/ות שרכשו אותה בעבר. ניתן לעדכן רק ״תשלומים״',
    PLAN_NO_NEED_FOR_DROPIN: 'אין צורך לייצר כרטיסיה או מנוי למקרה של כניסה חד פעמית, את זה ניתן לסמן פשוט על התלמיד/ה בתוך השיעור עצמו',
    LABEL_FREEZ_WEEK_LIMIT: 'הקפא/י את התכנית של התלמיד/ה והפשר/י הקפאה לאחר:',
    LABEL_UNFREEZE_MANUALLY: 'האם לבטל את ההקפאה עכשיו?',
    LABEL_UNFROZEN_AUTO: 'הקפאת התוכנית תבוטל אוטמטית ב <b>%{unfrozen_date}</b>',
    ALLOW_PAYMENTS: 'אפשר/י תשלומים',
    AUTO_RENEWS_MANAGE_ERROR: 'תשלומים אוטומטיים לא יכולים להיות מנוהלים ידנית',
    AUTO_RENEWS: 'תשלום חודשי מתחדש אוטומטי',
    AUTO_RENEWS_TOGGLE: 'מתחדש אוטומטית',
    AUTO_RENEWS_TOOLTIP: 'התכנית מתחדשת אוטומטית בסיום תקופת השימוש (התלמיד יחויב בהתאם)',
    AUTO_RENEWS_STUDIO_PAYMENTS: 'זמין רק כשרשומים למערכת התשלומים',
    AUTO_RENEWS_EXPIRATION_PERIOD: '4 שבועות מתחדש עם כל תשלום',
    START_DATE: 'תאריך תחילת מנוי',
    ALLOW_PAYMENTS_TOOLTIP: 'אופציה זו מאפשרת לתלמיד רכישה של המוצר בתשלומים. התלמיד יוכל לבחור מתשלום רגיל עד לכמות המוגדרת.',
    PAYMENTS_IN_MEMBERSHIP: '** אפשור רכישה בתשלומים הינו אך ורק למקרה של רכישת המנוי כולו בתשלום אחד באשראי'
  },
  products: {
    PRODUCTS_TABLE_TITLE: 'המוצרים שהסטודיו מציע/ה',
    PRODUCT_ACTIVATED: 'הוספ/י על המדף',
    PRODUCT_DEACTIVATED: 'הוסר מהמדף',
    REMOVE_PRODUCT_CONFIRM_MSG: 'האם למחוק מוצר זה?',
    PRODUCT_NAME: 'שם המוצר',
    PRODUCT_PRICE: 'מחיר המוצר',
    EDIT_PRODUCT_BTN: 'עריכת מוצר',
    REMOVE_PRODUCT: 'מחיקת מוצר',
    PRODUCT_ACTIVATE: 'הוסיפ/י על המדף',
    PRODUCT_DEACTIVATE: 'הסר/י מוצר מהמדף',
    PRODUCT_NO_RESULTS: 'עוד לא הוספו מוצרים',
    NO_RESULTS_CTA: 'צרו את המוצר הראשון',
    STUDENTS: 'תלמידים/ות',
    MORE_BTN: ' עוד ',
    STUDENTS_MODAL_TITLE: 'מספר התלמידים/ות שקנו את המוצר %{title} <b>(%{count})</b>',
    PRODUCT_SOLD_COUNT: 'כמות שנמכרה',
    NEED_TO_BE_A_SELLER: 'יש להירשם למערכת התשלומים כדי לנהל תלמידים ומוצרים',
    NEED_TO_BE_A_SELLER_URL: 'כדי לייחס מוצרים לתלמידים/ות ראשית יש להירשם למערכת התשלומים. ניתן להירשם ב-״תשלומים וסליקה (PayMe)״ מהתפריט '

  },
  productEdit: {
    TITLE_EDIT: 'עריכת %{productType}: %{title}',
    TITLE_ADD: '%{productType} חדש',
    LABEL_TITLE: 'כותרת',
    LABEL_TITLE_TOOLTIP: 'שם המוצר המסביר במשפט קצר מהו אופי המוצר למשל',
    LABEL_PRICE: 'מחיר',
    LABEL_PRICE_TOOLTIP: 'מחיר המלא של הכרטיסיה',
    PRODUCT_NOT_EDITABLE_NOTE: 'אין אפשרות לערוך מוצר זה מכיוון שכבר רכשו אותו בעבר',
    SAVE_BTN: 'שמור',
    LABEL_COLOR: 'צבע',
    PRODUCY_NOT_EDITABLE_NOTE: 'אין אפשרות לערוך תכנית זו מכיוון שיש תלמידים/ות שרכשו אותה בעבר',
    ALERT_ADD_IMAGE: 'אנא הוסיפו תמונה למוצר',
    ALERT_ADD_TITLE: 'אנא הוסיפו כותרת למוצר',
    ALERT_ADD_PRICE: 'אנא הכניסו מחיר למוצר'

  },
  messages: {
    TITLE: 'הודעות',
    CHAT_YOU: 'אני',
    NO_SESSION_RESULTS: 'אין לך שיעורים עתידיים',
    NAV_ALL_STUDENTS: 'התלמידים שלי',
    NAV_PRIVATE_CHATS: 'צ\'ט פרטי',
    NAV_SESSION_CHATS: 'צ\'אט של שיעורים',
    NAV_STUDIO_TEACHERS: 'מורים בסטודיו',
    PLACEHOLDER_MESSAGE: 'הקלד הודעה',
    CHAT_USER_NO_REUSLTS: 'לא נמצאו תלמידים/ות',
    USERS_SEARCH_PLACEHOLDER: 'כתובת דואר אלקטרוני',
    USERS_SEARCH_NO_RESULTS: 'לא נמצאו תלמידים/ות',
    FOLLOWERS_SUFFIX: 'תלמידים/ות',
    SUBJECT: 'נושא',
    MESSAGE: 'הודעה',
    MESSAGE_SENT: 'תודה על פנייתך! ניצור עמך קשר בהקדם..',
  },
  reports: {
    TOTAL_STUDENTS: 'מספר תלמידים',
    TOTAL_STUDENTS_TOOLTIP: 'סך כל התלמידים בסטודיו',
    TOTAL_CLASSES: 'סה״כ שיעורים שהועברו',
    TOTAL_CLASSES_TOOLTIP: 'סך כל כמות השיעורים שהתקיימו והועברו באותו חודש לא כולל שיעורים מבוטלים',
    TOTAL_CANCELED_CLASSES: 'סה״כ שיעורים שבוטלו',
    TOTAL_CANCELED_CLASSES_TOOLTIP: 'סך כל השיעורים שבוטלו באותו חודש',
    TOTAL_ACTIVE_CLASSES: 'סה"כ שיעורים פעילים',
    TOTAL_ACTIVE_CLASSES_TOOLTIP: 'סך כל השיעורים שמתקיימים.התקיימו באותו חודש',
    TOTAL_ATTENDERS: 'סה״כ נוכחות',
    TOTAL_ATTENDERS_TOOLTIP: 'סך כל הנוכחות שהייתה בכל השיעורים שהועברו באותו חודש, לא כולל נוכחות של שיעורים מבוטלים.',
    TOTAL_UNIQUE_ATTENDERS: 'סה״כ תלמידים/ות שתרגלו',
    TOTAL_UNIQUE_ATTENDERS_TOOLTIP: 'סך כל התלמידים/ות שתרגלו באותו חודש',
    SESSION_ATTENDERS: 'נוכחות לפי שיעורים',
    SESSION_ATTENDERS_TOOLTIP: 'מציג את רשימת השיעורים שהועברו באותו חודש והנוכחות שהייתה באותו שיעור',
    SESSION: 'שיעור',
    SHOW: 'הצג/י',
    STUDIO_ACTIVITY: 'פעילות הסטודיו',
    PAYMENTS_ACTIVITY: 'פעילות תשלומים',
    TEACHERS_ACTIVITY: 'נוכחות לפי מורים/ות',
    TEACHERS_ACTIVITY_TOOLTIP: 'מציג חתך של נוכחות לפי המורים/ות שלימדו באותו חודש. שימו לב שזה לפי נוכחות ולא תלמידים/ות',
    TEACHERS_ACTIVITY_NUMBERS: '<b>%{name}</b> לימד/ה <b>(%{sessionsCount})</b> שיעורים עם סה״כ נוכחות של <b>(%{attendersCount})</b? מתרגלים',
    STUDENTS_ACTIVITY: 'פעילות התלמידים/ות',
    STUDENTS_ACTIVITY_TOOLTIP: 'מציג את כל הפעילות של כל התלמידים/ות בסטודיו באותו חודש',
    STUDENTS_ACTIVITY_MAINTENANCE: 'אפשרות של פעילות תלמידים/ות תחזור בקרוב',
    STUDENTS_ACTIVITY_MAINTENANCE_TOOLTIP: 'אופציה זאת דורשת שיפורים ותיקונים ובקרוב יחזור לשימוש :)',
    RECIEVED_BY: 'סכום התקבל ע״י',
    RECIEVED_BY_TOOLTIP: 'חלוקה המציגה את הסכום שהתקבל לפי המורים/ות השונים בסטודיו באותו חודש',
    TOTAL_AMOUNT_RECEIVED: 'סה״כ הסכום שהתקבל',
    TOTAL_AMOUNT_RECEIVED_TOOLTIP: 'סך כל הסכום שהתקבל באותו חודש (כרגע סכום זה אינו מחושב למנויים חודשיים ששולמו ע"י כרטיס אשראי דרך מערכת התשלומים של מדידייט- יעודכן בהמשך)',
    TOTAL_TRIAL_SESSIONS: 'מספר שיעורי ניסיון',
    TOTAL_TRIAL_SESSIONS_TOOLTIP: 'מספר התלמידים/ות שהיו בשיעור ניסיון באותו חודש',
    AMOUNT_RECIEVED_FOR_SESSIONS: 'סכום שהתקבל משיעורים בודדים',
    AMOUNT_RECIEVED_FOR_SESSIONS_TOOLTIP: 'סך כל הסכומים שהתקבלו משיעורים בודדים בלבד. כלומר, מתשלום חד פעמי על שיעור מסויים',
    AMOUNT_RECIEVED_FOR_TRIAL_SESSIONS: 'סכום שהתקבל משיעורי ניסיון',
    AMOUNT_RECIEVED_FOR_TRIAL_SESSIONS_TOOLTIP: 'הסכום שהתקבל משיעורי ניסיון בלבד באותו חודש',
    AMOUNT_RECIEVED_FOR_TICKETS: 'סכום שהתקבל מכרטיסיות',
    AMOUNT_RECIEVED_FOR_TICKETS_TOOLTIP: 'הסכום שהתקבל ממכירת כרטיסיות באותו החודש. גם אם כרטיסיה נפתחה בחודש קודם אבל סומן ששולם בחודש הזה הסכום יכלל בחודש שבו הסכום התקבל בפועל. לכן יכול להיות פער בין מספר כרטיסיות שנפתחו באותו חודש לעומת הסכום הכולל שהתקבל.',
    AMOUNT_RECIEVED_FOR_MEMBERSHIPS: 'סכום שהתקבל ממנויים',
    AMOUNT_RECIEVED_FOR_MEMBERSHIPS_TOOLTIP: 'הסכום שהתקבל ממנויים חודשיים באותו החודש. גם אם מנוי נפתח בחודש קודם אבל סומן ששולם בחודש הזה הסכום יכלל בחודש שבו הסכום התקבל בפועל. לכן יכול להיות פער בין מספר מנויים שנפתחו באותו חודש לעומת הסכום הכולל שהתקבל.<br/>הסכום גם יכלול תשלומים ממנויים חודשיים שנפתחו בחודשים קודמים ושעדיין יש עליהם תשלומים בחודש הזה.',
    AMOUNT_RECIEVED_FOR_PRODUCTS: 'סכום שהתקבל ממוצרים',
    AMOUNT_RECIEVED_FOR_PRODUCTS_TOOLTIP: 'הסכום שהתקבל ממכירת מוצרים באותו חודש',
    AMOUNT_RECIEVED_FOR_FREE_AMOUNT: 'סכום שהתקבל מתרומות או סכומים שרירותיים',
    AMOUNT_RECIEVED_FOR_FREE_AMOUNT_TOOLTIP: 'סכום שהתקבל מתרומות או מסכומים שרירותיים שתלמידים העבירו באותו חודש',
    NUMBER_OF_NEW_TICKETS: 'מספר כרטיסיות שנפתחו',
    NUMBER_OF_NEW_TICKETS_TOOLTIP: 'מספר הכרטיסיות החדשות שנפתחו באותו חודש',
    NUMBER_OF_NEW_MEMBERSHIPS: 'מספר מנויים שנפתחו',
    NUMBER_OF_NEW_MEMBERSHIPS_TOOLTIP: 'מספר המנויים החדשים שנפתחו באותו חודש',
    EXPIRES_ON: 'תקף עד',
    BEST_PERFORMING_SESSIONS: 'שיעורים עם נוכחות גבוהה',
    BEST_PERFORMING_SESSIONS_TOOLTIP: 'שיעורים בהם הנוכחות גבוהה יחסית לשיעורים שהועברו באותו חודש. מחושב לפי שיעור הגעה של 80% ומעלה מהנוכחות הגבוהה ביותר שהייתה באותו חודש',
    LEAST_PERFORMING_SESSIONS: 'שיעורים עם נוכחות נמוכה',
    LEAST_PERFORMING_SESSIONS_TOOLTIP: 'שיעורים בהם הנוכחות נמוכה יחסית לשיעורים שהועברו באותו חודש. מחושב לפי שיעור הגעה של 20% ומטה מהנוכחות הגבוהה ביותר שהייתה באותו חודש',
    LABEL_PAID: 'שולם',
    PRINT: 'הדפסה',
    ACCURACY_DISCLAIMER: 'אפשרות הדוחות נועדה לתת תמונת מצב כללית של הסטודיו בחודש הנבחר ויכולים להופיע אי דיוקים משלל סיבות',

    SHOW_DEBT_STUDENTS: 'הצג תלמידים/ות שנמצאים בחוב (לשיעור או כרטיסיה/מנוי)',
    SHOW_DEBT_STUDENTS_TOOLTIP: 'מציג את רשימת התלמידים/ות הנמצאים בחוב באותו החודש לשיעור, כרטיסיה או מנוי',
    SHOW_TRIAL_STUDENTS: 'הצג תלמידים/ות שהגיעו לשיעור ניסיון',
    SHOW_TRIAL_STUDENTS_TOOLTIP: 'מציג את רשימת התלמידים/ות שהגיעו לשיעור ניסיון בטווח תאריכים שנבחר',
    SHOW_UNREFUNDED_STUDENTS: 'הצג תלמידים/ות שעזבו שיעור בטווח מדיניות הביטול ללא החזר',
    SHOW_UNREFUNDED_STUDENTS_TOOLTIP: 'מציג רשימת תלמידים/ות שיצאו משיעור בטווח מדיניות ביטול ולא קיבלו החזר על השיעור',
    SHOW_DORMANT_STUDENTS: 'הצג תלמידים/ות לא פעילים שלא הגיעו',
    SHOW_DORMANT_STUDENTS_TOOLTIP: 'מציג רשימה של התלמידים/ות באותו החודש, שלא נכחו מספר שבועות, לפי מספר השבועות שנבחר על ידכם בסינון',
    SHOW_NEW_DORMANT_STUDENTS: 'הצג תלמידים/ות לא פעילים חדשים שלא הגיעו',
    SHOW_NEW_DORMANT_STUDENTS_TOOLTIP: 'מציג את רשימת התלמידים/ות שהיו בשיעור ניסיון באותו החודש ושלא נכחו מספר שבועות לפי מספר השבועות שנבחר על ידכם בסינון',
    STUDENT_ACTIVITY_ROW_TITLE: '<b>%{name}</b> השתתפ/ה ב <b>(%{number})</b> שיעורים <%{email}>',
    UNREFUNDED_SESSION: 'שיעור ללא החזר עקב מדיניות ביטול',
    DATE_RANGE: 'טווח תאריכים',
    DATE_RANGE_ALERT: 'טווח התאריכים צריך להיות פחות מ-90 ימים',
    INSIGHTS_PROMOTION: '"תמונת המצב"  שלנו מציגה נתונים עסקיים חיוניים באמצעות ממשק ידידותי למשתמש.\nהיא מתמצתת מדדי מפתח, מאפשרת החלטות מושכלות ותפעול יעיל בסטודיו שלך.\nניתן להדפיס ולשלוח לעצמך באימייל כל תובנה בסיום הטעינה שלה.',
    INSIGHTS_BUTTON: 'קחו אותי לתמונת המצב שלי',
    INSIGHTS_INSIGHTS: 'תובנות',
    INSIGHTS_SUGGESTIONS: 'הצעות',
    INSIGHTS_SUGGESTIONS_TOOLTIP: 'הצעות לשיפור תמונת מצב, תוכלו להשתמש בסימן @ בכדי להשלים אוטומטית את המידע על ההצעה',
    INSIGHTS_SUMMARY: 'תמונת מצב',
    INSIGHTS_SUMMARY_TYPE: 'סוג תמונת מצב',
    INSIGHTS_SUMMARY_CRITERIA: 'קריטריון תמונת מצב',
    INSIGHTS_SUGGESTION_SUBTITLE: 'תמונת מצב הינה יכולת חשובה שנוצרה בכדי להקל על הבנת ביצועי הסטודיו ומצבו העסקי והפיננסי.\nהגרסה הנוכחית הינה גרסת בטא ואנו נשמח לשמוע הצעות לשיפור\n\nאז נסו להציע תובנה ואולי תופתעו כמה מהר היא תתווסף אם תהיה רלוונטית :)',
    SUGGESTION_SUBJECT: 'כותרת ההצעה לתמונת מצב',
    SUGGESTION_SUBJECT_TOOLTIP: 'כותרת ההצעה לתמונת מצב, תוכלו להשתמש בסימן @ בכדי להשלים אוטומטית את נושא ההצעה',
    SUGGESTION_SUBJECT_PLACEHOLDER: 'הצעה לשיפור תמונת מצב בנושא "שיעורים"',
    SUGGESTION_MESSAGE: 'תיאור ההצעה לתמונת מצב',
    SUGGESTION_MESSAGE_TOOLTIP: 'תיאור ההצעה לתמונת מצב, תוכלו להשתמש בסימן @ בכדי להשלים אוטומטית את תיאור ההצעה',
    SUGGESTION_MESSAGE_PLACEHOLDER: 'אני רוצה להציע שיפור לתמונת מצב',
    EXPORT_EMAIL: "שליחת מייל עם קובץ Excel",
    EXPORT_TO_EXCEL: 'ייצא לExcel',
    EXPORT_TO_EXCEL_SUCCESS: 'הודעה באימייל עם קובץ Excel נשלחה בהצלחה!',
  },
  months: {
    JANUARY: 'ינואר',
    FEBRUARY: 'פברואר',
    MARCH: 'מרץ',
    APRIL: 'אפריל',
    MAY: 'מאי',
    JUNE: 'יוני',
    JULY: 'יולי',
    AUGUST: 'אוגוסט',
    SEPTEMBER: 'ספטמבר',
    OCTOBER: 'אוקטובר',
    NOVEMBER: 'נובמבר',
    DECEMBER: 'דצמבר'
  },
  footer: {
    NEED_HELP: 'צריכים/ות עזרה?',
    CONTACT_US: 'צרו קשר',
    MEDIDATE_COMP: 'אפום סטודיוס בע"מ'
  },
  paymentSuccessPage: {
    THANKS_MESSAGE: 'תשלום על %{name} התקבל בהצלחה!'
  },
  helpPopup: {
    PLAN_MANAGEMENT_TUT: '<p><span style="font-size:20px;"><strong>תכננו איך לנהל את הסטודיו: ארבעת הדרכים לנהל את הסטודיו עם מדידייט</strong></span></p>',
    CREATE_STUDIO_SCHEDULE_TUT: '<p><span style="font-size:20px;"><strong>צרו את מערכת השעות של הסטודיו</strong></span></p>',
    ADD_REMOVE_FIND_TUT: '<p><span style="font-size:20px;"><strong>הוסף/הסר/מצא תלמידים בסטודיו</strong></span></p>',
    MANAGE_STUDENTS_CLASSES_TUT: '<p><span style="font-size:20px;"><strong>ניהול שוטף של תלמידים ושיעורים</strong></span></p>',
    CREATE_STUDIO_PLANS_TUT: '<p><span style="font-size:20px;"><strong>צרו את תכניות הסטודיו (כרטיסיות ומנויים)</strong></span></p>',
    MANAGE_STUDIO_PLANS_TUT: '<p><span style="font-size:20px;"><strong>ניהול כרטיסיות ומנויים עם תלמידים</strong></span></p>',
    STUDIO_CONFIGURATION_TUT: '<p><span style="font-size:20px;"><strong>תכנון אופי הסטודיו</strong></span></p>',
    FIRST_SIGNUP_CONFIGURATION_TUT: '<p><span style="font-size:20px;"><strong>הגדרות רישום ראשוני למדידייט</strong></span></p>',
    SUMMARIES_TUT: '<p><span style="font-size:20px;"><strong>מעבר על דוחות וסיכומים</strong></span></p>',
    ADD_NEW_STUDENT_TUT: '<p><span style="font-size:20px;"><strong>הוספת תלמיד/ה לרשימת התלמידים/ות שלי</strong></span></p>',
    CREATE_NEW_SESSION_TUT: '<p><span style="font-size:20px;"><strong>יצירת שיעור חדש</strong></span></p>',
    MANAGE_ARRIVALS_TUT: '<p><span style="font-size:20px;"><strong>ניהול רישומים לשיעור</strong></span></p>',
    CREATE_NEW_PLAN_TUT: '<p><span style="font-size:20px;"><strong>יצירת תוכנית חדשה</strong></span></p>',
    ASSIGN_PLAN_TO_STUDENT_TUT: '<p><span style="font-size:20px;"><strong>ייחוס תלמידים/ות לכרטיסיות ומנויים</strong></span></p>',
    STUDENT_PAGE_TUT: '<p><span style="font-size:20px;"><strong>עמוד פרטי התלמיד</strong></span></p>',
    ONBOARDING_TUT: '<p><span style="font-size:20px;"><strong>הקמת הסטודיו, הכנסת המצב הקיים של הסטודיו למדידייט</strong></span></p>',
    VIDEO_SELECT: 'בחירת וידאו',
    VIDEO_SELECTED: 'וידאו נבחר:',
  },
  tutorials: {
    PLAN_MANAGEMENT_TUT: 'תכננו איך לנהל את הסטודיו: ארבעת הדרכים לנהל את הסטודיו עם מדידייט',
    CREATE_STUDIO_SCHEDULE_TUT: 'צרו את מערכת השעות של הסטודיו',
    ADD_REMOVE_FIND_TUT: 'הוסף/הסר/מצא תלמידים בסטודיו',
    MANAGE_STUDENTS_CLASSES_TUT: 'ניהול שוטף של תלמידים ושיעורים',
    CREATE_STUDIO_PLANS_TUT: 'צרו את תכניות הסטודיו (כרטיסיות ומנויים)',
    MANAGE_STUDIO_PLANS_TUT: 'ניהול כרטיסיות ומנויים עם תלמידים',
    CREATE_STUDIO_PLANS_TICKET_TUT: 'יצירת כרטיסיה בסטודיו',
    CREATE_STUDIO_PLANS_MEMBERSHIP_TUT: 'יצירת מנוי בסטודיו',
    MANAGE_STUDIO_PLANS_TICKET_TUT: 'ניהול כרטיסיות',
    MANAGE_STUDIO_PLANS_MEMBERSHIP_TUT: 'ניהול מנויים',
    STUDIO_CONFIGURATION_TUT: 'תכנון אופי הסטודיו',
    FIRST_SIGNUP_CONFIGURATION_TUT: 'הגדרות רישום ראשוני למדידייט',
    SUMMARIES_TUT: 'מעבר על דוחות וסיכומים',
    ADD_NEW_STUDENT_TUT: 'הוספת תלמיד/ה לרשימת התלמידים/ות שלי',
    CREATE_NEW_SESSION_TUT: 'יצירת שיעור חדש',
    MANAGE_ARRIVALS_TUT: 'ניהול רישומים לשיעור',
    CREATE_NEW_PLAN_TUT: 'יצירת תוכנית חדשה',
    ASSIGN_PLAN_TO_STUDENT_TUT: 'ייחוס תלמידים/ות לכרטיסיות ומנויים',
    STUDENT_PAGE_TUT: 'עמוד פרטי התלמיד',
    ONBOARDING_TUT: 'הקמת הסטודיו, הכנסת המצב הקיים של הסטודיו למדידייט',
    VIDEO_SELECT: 'בחירת וידאו',
    VIDEO_SELECTED: 'וידאו נבחר:',
  },
  insights: {
    INSIGHT_1_X_1_TITLE: 'תלמידים פעילים',
    INSIGHT_1_Y_1_TITLE: 'תלמידים לא פעילים',
    INSIGHT_1_X_2_TITLE: 'תלמידים עם תכנית',
    INSIGHT_1_Y_2_TITLE: 'תלמידים ללא תכנית',
    INSIGHT_1_X_3_TITLE: 'תלמידים',
    INSIGHT_1_Y_3_TITLE: 'בשנה האחרונה',
    INSIGHT_2_X_1_TITLE: 'שיעורים משולמים במלואם',
    INSIGHT_2_Y_1_TITLE: 'שיעורים משולמים חלקית',
    INSIGHT_2_X_2_TITLE: 'שיעורים עם תלמידים ללא החזר ("ביטול מאוחר")',
    INSIGHT_2_Y_2_TITLE: 'שיעורים עם תלמידים שביטלו',
    INSIGHT_2_X_3_TITLE: 'שיעורים',
    INSIGHT_2_Y_3_TITLE: 'בשנה האחרונה',
    INSIGHT_3_X_1_TITLE: 'תכניות פעילות',
    INSIGHT_3_Y_1_TITLE: 'תכניות שהסתיימו',
    INSIGHT_3_X_2_TITLE: 'מנויים לקראת סיום',
    INSIGHT_3_Y_2_TITLE: 'כרטיסיות לקראת סיום',
    INSIGHT_3_X_3_TITLE: 'תכניות',
    INSIGHT_1_SECTION: 'תלמידים',
    INSIGHT_1_SECTION_ALT: 'כל התלמידים',
    INSIGHT_1_SECTION_ALT_2: 'כל התלמידים ששילמו',
    INSIGHT_1_SECTION_ALT_3: 'לעומת טווח תאריכים קודם',
    INSIGHT_1_TYPE_1: 'תלמידים שלא הגיעו בטווח תאריכים',
    INSIGHT_1_TYPE_1_COUNTER: 'תלמידים שהגיעו בטווח תאריכים',
    INSIGHT_1_TYPE_2: 'תלמידים שאין להם מנוי או כרטיסיה פעילים בטווח תאריכים',
    INSIGHT_1_TYPE_2_COUNTER: 'תלמידים עם מנוי או כרטיסיה פעילים בטווח תאריכים',
    INSIGHT_1_TYPE_3: 'תלמידים חדשים שנרשמו לסטודיו בטווח תאריכים',
    INSIGHT_1_TYPE_3_COUNTER: 'שאר התלמידים בסטודיו בטווח תאריכים',
    INSIGHT_1_TYPE_4: 'תלמידים חדשים שהיו בשיעור נסיון בטווח תאריכים',
    INSIGHT_1_TYPE_4_COUNTER: 'שאר התלמידים שהיו בשיעורים בטווח תאריכים',
    INSIGHT_2_SECTION: 'שיעורים',
    INSIGHT_2_SECTION_ALT: 'כל השיעורים בטווח זמן',
    INSIGHT_2_TYPE_1: 'שיעורים שלא הוסדרו עד הסוף',
    INSIGHT_2_TYPE_1_COUNTER: 'שיעורים בתשלום שהוסדרו עד הסוף',
    INSIGHT_2_TYPE_2: 'שיעורים עם תלמידים ללא החזר ("ביטול מאוחר")',
    INSIGHT_2_TYPE_2_COUNTER: 'שיעורים עם תלמידים או ביטולים עם החזר',
    INSIGHT_2_TYPE_3: 'שיעורים ששולמו אבל לא הוצאה עליהם קבלה או חשבונית',
    INSIGHT_2_TYPE_3_COUNTER: 'שיעורים ששולמו',
    INSIGHT_3_SECTION: 'תכניות',
    INSIGHT_3_SECTION_ALT: 'כל התכניות',
    INSIGHT_3_SECTION_ALT_2: 'כל המנויים',
    INSIGHT_3_SECTION_ALT_3: 'כל הכרטיסיות',
    INSIGHT_3_TYPE_1: 'תכניות שלא הוסדרו עד הסוף',
    INSIGHT_3_TYPE_1_COUNTER: 'תכניות שהוסדרו עד הסוף',
    INSIGHT_3_TYPE_2: 'מנויים לקראת סיום',
    INSIGHT_3_TYPE_2_COUNTER: 'שאר המנויים',
    INSIGHT_3_TYPE_3: 'כרטיסיות לקראת סיום',
    INSIGHT_3_TYPE_3_COUNTER: 'שאר הכרטיסיות',
    INSIGHT_3_TYPE_4: 'מנויים שהסתיימו',
    INSIGHT_3_TYPE_4_COUNTER: 'מנויים פעילים',
    INSIGHT_3_TYPE_5: 'כרטיסיות שהסתיימו',
    INSIGHT_3_TYPE_5_COUNTER: 'כרטיסיות פעילות',
    INSIGHT_RATIO_0: 'יחס טוב',
    INSIGHT_RATIO_1_25: 'יחס לא טוב',
    INSIGHT_RATIO_25_50: 'יחס בסדר',
    INSIGHT_RATIO_50_75: 'יחס טוב!',
    INSIGHT_RATIO_75: 'יחס מצוין!',
  },
  calendar: {
    SHOW_CANCELED_SESSIONS: 'הצג שיעורים שבוטלו',
    SHOW_CANCELED_SESSIONS_TOOLTIP: 'הצג בלוח שנה את כל השיעורים שהסטודיו ביטל',
    ALL_SESSIONS_ARE_SETTELED: 'כל השיעורים מוסדרים בשבוע זה',
    NO_SUCH_SESSIONS: 'אין שיעורים כאלה בשבוע זה'
  },
  productExternal: {
    LEAVE_AND_REFUND: '<b>האם להסיר אותך מהשיעור ולזכות לך את הסכום?<br/>הזיכוי על פי תנאי ההחזר של מדידייט.</b>',
    LEAVE_AND_REFUND_YES: 'הוצא אותי וזכה את הסכום בבקשה',
    NO_THANKS: 'לא תודה',
    PURCHASE_DROPIN_OR_PLANS: 'רכישת כניסה חד פעמית? או הצג כרטיסיות ומנויים רלוונטיים?',
    PURCHASE_DROPIN: 'רכוש כניסה חד פעמית',
    SHOW_PLANS: 'הצג כרטיסיות ומנויים',
    USE_EXISTING_CREDIT_CARD_OR_DIFFERENT: 'השתמש בכרטיס קיים? או הכניס כרטיס אחר?',
    USE_EXISTING_CREDIT_CARD: 'השתמש בקיים',
    DIFFERENT_CREDIT_CARD: 'הכנסת כרטיס אחר',
    PAST_SESSION: 'שיעור זה כבר עבר',
    PAYMENT_WENT_THROUGH: 'תשלום עבר בהצלחה',
    PURCHASE_SUCCESSFUL: 'רכשתם בהצלחה את ה-%{className}',
    OK: 'הבנתי תודה',
    UNDERSTOOD_LEAVE_ANYWAY: 'הבנתי, לצאת בכל זאת תודה',
    ADD_TO_WAITING_LIST: 'כן, להכנס לרשימת המתנה בבקשה',
    STILL_NO_ROOM: 'עדיין אין מקום בשיעור, אבל את.ה ברשימת ההמתנה',
    IN_WAITING_LIST: 'ברשימת המתנה',
    GO_TO_FORM: 'מילוי הטופס'
  },
  countries: [
    { value: "AF", label: "אפגניסטן" },
    { value: "AX", label: "איי אלנד" },
    { value: "AL", label: "אלבניה" },
    { value: "DZ", label: "אלג'יריה" },
    { value: "AS", label: "סמואה האמריקנית" },
    { value: "AD", label: "אנדורה" },
    { value: "AO", label: "אנגולה" },
    { value: "AI", label: "אנגווילה" },
    { value: "AQ", label: "אנטארקטיקה" },
    { value: "AG", label: "אנטיגואה וברבודה" },
    { value: "AR", label: "ארגנטינה" },
    { value: "AM", label: "אַרְמֶנִיָה" },
    { value: "AW", label: "ארובה" },
    { value: "AU", label: "אוֹסטְרַלִיָה" },
    { value: "AT", label: "אוֹסְטְרֵיָה" },
    { value: "AZ", label: "אזרבייג'ן" },
    { value: "BS", label: "איי בהאמה" },
    { value: "BH", label: "בחריין" },
    { value: "BD", label: "בנגלדש" },
    { value: "BB", label: "ברבדוס" },
    { value: "BY", label: "בלארוס" },
    { value: "BE", label: "בלגיה" },
    { value: "BZ", label: "בליז" },
    { value: "BJ", label: "בנין" },
    { value: "BM", label: "ברמודה" },
    { value: "BT", label: "בהוטן" },
    { value: "BO", label: "בוליביה" },
    { value: "BQ", label: "בונייר, סינט אוסטאציוס וסבא" },
    { value: "BA", label: "בוסניה והרצגובינה" },
    { value: "BW", label: "בוצואנה" },
    { value: "BV", label: "האי בובט" },
    { value: "BR", label: "בְּרָזִיל" },
    { value: "IO", label: "הטריטוריה הבריטית באוקיאנוס ההודי" },
    { value: "BN", label: "ברוניי דארוסלאם" },
    { value: "BG", label: "בולגריה" },
    { value: "BF", label: "בורקינה פאסו" },
    { value: "BI", label: "בורונדי" },
    { value: "KH", label: "קמבודיה" },
    { value: "CM", label: "קמרון" },
    { value: "CA", label: "קנדה" },
    { value: "CV", label: "קייפ ורדה" },
    { value: "KY", label: "איי קיימן" },
    { value: "CF", label: "הרפובליקה המרכז - אפריקאית" },
    { value: "TD", label: "צ'אד" },
    { value: "CL", label: "צ'ילה" },
    { value: "CN", label: "חרסינה" },
    { value: "CX", label: "אי חג המולד" },
    { value: "CC", label: "איי קוקוס (קילינג)" },
    { value: "CO", label: "קולומביה" },
    { value: "KM", label: "קומורו" },
    { value: "CG", label: "קונגו" },
    { value: "CD", label: "קונגו, הרפובליקה הדמוקרטית של קונגו" },
    { value: "CK", label: "איי קוק" },
    { value: "CR", label: "קוסטה ריקה" },
    { value: "CI", label: "חוף השנהב" },
    { value: "HR", label: "קרואטיה" },
    { value: "CU", label: "קובה" },
    { value: "CW", label: "קוראסאו" },
    { value: "CY", label: "קַפרִיסִין" },
    { value: "CZ", label: "הרפובליקה הצ'כית" },
    { value: "DK", label: "דנמרק" },
    { value: "DJ", label: "ג'יבוטי" },
    { value: "DM", label: "דומיניקה" },
    { value: "DO", label: "הרפובליקה הדומיניקנית" },
    { value: "EC", label: "אקוודור" },
    { value: "EG", label: "מִצְרַיִם" },
    { value: "SV", label: "אל סלבדור" },
    { value: "GQ", label: "גיניאה המשוונית" },
    { value: "ER", label: "אריתריאה" },
    { value: "EE", label: "אסטוניה" },
    { value: "ET", label: "אֶתִיוֹפִּיָה" },
    { value: "FK", label: "איי פוקלנד (מלווינס)" },
    { value: "FO", label: "איי פרו" },
    { value: "FJ", label: "פיג'י" },
    { value: "FI", label: "פינלנד" },
    { value: "FR", label: "צָרְפַת" },
    { value: "GF", label: "גיאנה הצרפתית" },
    { value: "PF", label: "פולינזיה הצרפתית" },
    { value: "TF", label: "השטחים הדרומיים הצרפתיים" },
    { value: "GA", label: "גבון" },
    { value: "GM", label: "גמביה" },
    { value: "GE", label: "ג'ורג'יה" },
    { value: "DE", label: "גֶרמָנִיָה" },
    { value: "GH", label: "גאנה" },
    { value: "GI", label: "גיברלטר" },
    { value: "GR", label: "יָוָן" },
    { value: "GL", label: "גרינלנד" },
    { value: "GD", label: "גרנדה" },
    { value: "GP", label: "גוואדלופ" },
    { value: "GU", label: "גואם" },
    { value: "GT", label: "גואטמלה" },
    { value: "GG", label: "גרנזי" },
    { value: "GN", label: "גינאה" },
    { value: "GW", label: "גינאה ביסאו" },
    { value: "GY", label: "גיאנה" },
    { value: "HT", label: "האיטי" },
    { value: "HM", label: "האי הרד ואיי מקדונלד" },
    { value: "VA", label: "הכס הקדוש (מדינת הוותיקן)" },
    { value: "HN", label: "הונדורס" },
    { value: "HK", label: "הונג קונג" },
    { value: "HU", label: "הונגריה" },
    { value: "IS", label: "אִיסלַנד" },
    { value: "IN", label: "הוֹדוּ" },
    { value: "ID", label: "אִינדוֹנֵזִיָה" },
    { value: "IR", label: "איראן}, הרפובליקה האסלאמית של" },
    { value: "IQ", label: "עִירַאק" },
    { value: "IE", label: "אירלנד" },
    { value: "IM", label: "האי מאן" },
    { value: "IL", label: "ישראל" },
    { value: "IT", label: "אִיטַלִיָה" },
    { value: "JM", label: "ג'מייקה" },
    { value: "JP", label: "יפן" },
    { value: "JE", label: "ג'רזי" },
    { value: "JO", label: "יַרדֵן" },
    { value: "KZ", label: "קזחסטן" },
    { value: "KE", label: "קניה" },
    { value: "KI", label: "קיריבטי" },
    { value: "KP", label: "קוריאה}, הרפובליקה העממית הדמוקרטית של" },
    { value: "KR", label: "קוריאה}, הרפובליקה של" },
    { value: "XK", label: "קוסובו" },
    { value: "KW", label: "כווית" },
    { value: "KG", label: "קירגיזסטן" },
    { value: "LA", label: "הרפובליקה הדמוקרטית העממית של לאו" },
    { value: "LV", label: "לטביה" },
    { value: "LB", label: "לבנון" },
    { value: "LS", label: "לסוטו" },
    { value: "LR", label: "ליבריה" },
    { value: "LY", label: "ג'בהיריה הערבית הלובית" },
    { value: "LI", label: "ליכטנשטיין" },
    { value: "LT", label: "ליטא" },
    { value: "LU", label: "לוקסמבורג" },
    { value: "MO", label: "מקאו" },
    { value: "MK", label: "מקדוניה}, הרפובליקה היוגוסלבית לשעבר" },
    { value: "MG", label: "מדגסקר" },
    { value: "MW", label: "מלאווי" },
    { value: "MY", label: "מלזיה" },
    { value: "MV", label: "האיים המלדיביים" },
    { value: "ML", label: "מאלי" },
    { value: "MT", label: "מלטה" },
    { value: "MH", label: "איי מרשל" },
    { value: "MQ", label: "מרטיניק" },
    { value: "MR", label: "מאוריטניה" },
    { value: "MU", label: "מאוריציוס" },
    { value: "YT", label: "מיוט" },
    { value: "MX", label: "מקסיקו" },
    { value: "FM", label: "מיקרונזיה}, מדינות הפדרציה של" },
    { value: "MD", label: "מולדובה}, הרפובליקה של" },
    { value: "MC", label: "מונקו" },
    { value: "MN", label: "מונגוליה" },
    { value: "ME", label: "מונטנגרו" },
    { value: "MS", label: "מונטסראט" },
    { value: "MA", label: "מָרוֹקוֹ" },
    { value: "MZ", label: "מוזמביק" },
    { value: "MM", label: "מיאנמר" },
    { value: "NA", label: "נמיביה" },
    { value: "NR", label: "נאורו" },
    { value: "NP", label: "נפאל" },
    { value: "NL", label: "הולנד" },
    { value: "AN", label: "האנטילים ההולנדיים" },
    { value: "NC", label: "קלדוניה החדשה" },
    { value: "NZ", label: "ניו זילנד" },
    { value: "NI", label: "ניקרגואה" },
    { value: "NE", label: "ניז'ר" },
    { value: "NG", label: "ניגריה" },
    { value: "NU", label: "ניואה" },
    { value: "NF", label: "האי נורפולק" },
    { value: "MP", label: "איי מריאנה הצפוניים" },
    { value: "NO", label: "נורווגיה" },
    { value: "OM", label: "עומאן" },
    { value: "PK", label: "פקיסטן" },
    { value: "PW", label: "פלאו" },
    { value: "PS", label: "שטח פלסטיני}, כבוש" },
    { value: "PA", label: "פנמה" },
    { value: "PG", label: "פפואה גינאה החדשה" },
    { value: "PY", label: "פרגוואי" },
    { value: "PE", label: "פרו" },
    { value: "PH", label: "הפיליפינים" },
    { value: "PN", label: "פיטקארן" },
    { value: "PL", label: "פּוֹלִין" },
    { value: "PT", label: "פּוֹרטוּגָל" },
    { value: "PR", label: "פוארטו ריקו" },
    { value: "QA", label: "קטאר" },
    { value: "RE", label: "איחוד" },
    { value: "RO", label: "רומניה" },
    { value: "RU", label: "הפדרציה הרוסית" },
    { value: "RW", label: "רואנדה" },
    { value: "BL", label: "סנט ברתלמי" },
    { value: "SH", label: "סנט הלנה" },
    { value: "KN", label: "סנט קיטס ונוויס" },
    { value: "LC", label: "סנט לוסיה" },
    { value: "MF", label: "מרטין הקדוש" },
    { value: "PM", label: "סן פייר ומיקלון" },
    { value: "VC", label: "וינסנט הקדוש ו ה - גרנידיים" },
    { value: "WS", label: "סמואה" },
    { value: "SM", label: "סן מרינו" },
    { value: "ST", label: "סאו טומה ופרינסיפה" },
    { value: "SA", label: "ערב הסעודית" },
    { value: "SN", label: "סנגל" },
    { value: "RS", label: "סרביה" },
    { value: "CS", label: "סרביה ומונטנגרו" },
    { value: "SC", label: "סיישל" },
    { value: "SL", label: "סיירה לאונה" },
    { value: "SG", label: "סינגפור" },
    { value: "SX", label: "סנט מרטין" },
    { value: "SK", label: "סלובקיה" },
    { value: "SI", label: "סלובניה" },
    { value: "SB", label: "איי שלמה" },
    { value: "SO", label: "סומליה" },
    { value: "ZA", label: "דרום אפריקה" },
    { value: "GS", label: "דרום ג'ורג'יה ואיי הכריכים הדרומיים" },
    { value: "SS", label: "דרום סודן" },
    { value: "ES", label: "סְפָרַד" },
    { value: "LK", label: "סרי לנקה" },
    { value: "SD", label: "סודן" },
    { value: "SR", label: "סורינאם" },
    { value: "SJ", label: "סוולבארד ויאן מאיין" },
    { value: "SZ", label: "סווזילנד" },
    { value: "SE", label: "שבדיה" },
    { value: "CH", label: "שוויץ" },
    { value: "SY", label: "הרפובליקה הערבית של סוריה" },
    { value: "TW", label: "טייוואן}, מחוז סין" },
    { value: "TJ", label: "טג'יקיסטן" },
    { value: "TZ", label: "טנזניה}, הרפובליקה המאוחדת" },
    { value: "TH", label: "תאילנד" },
    { value: "TL", label: "טימור-לסטה" },
    { value: "TG", label: "ללכת" },
    { value: "TK", label: "טוקלאו" },
    { value: "TO", label: "טונגה" },
    { value: "TT", label: "טרינידד וטובגו" },
    { value: "TN", label: "תוניסיה" },
    { value: "TR", label: "טורקיה" },
    { value: "TM", label: "טורקמניסטן" },
    { value: "TC", label: "איי טורקס וקאיקוס" },
    { value: "TV", label: "טובאלו" },
    { value: "UG", label: "אוגנדה" },
    { value: "UA", label: "אוקראינה" },
    { value: "AE", label: "איחוד האמירויות הערביות" },
    { value: "GB", label: "הממלכה המאוחדת" },
    { value: "US", label: "ארצות הברית" },
    { value: "UM", label: "האיים הקטנים של ארצות הברית" },
    { value: "UY", label: "אורוגוואי" },
    { value: "UZ", label: "אוזבקיסטן" },
    { value: "VU", label: "ונואטו" },
    { value: "VE", label: "ונצואלה" },
    { value: "VN", label: "וייטנאם" },
    { value: "VG", label: "איי הבתולה}, בריטים" },
    { value: "VI", label: "איי הבתולה}, ארה\\\"ב" },
    { value: "WF", label: "וואליס ופוטונה" },
    { value: "EH", label: "סהרה המערבית" },
    { value: "YE", label: "תֵימָן" },
    { value: "ZM", label: "זמביה" },
    { value: "ZW", label: "זימבבואה" }
  ]
}
