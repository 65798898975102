export default {
  errors: {
    LOGIN_MISSING_FIELDS: 'Please make sure username and password are entered correctly',
    MOBILE_PORTRAIT_ERROR: 'Medidate Platform gives a much better experience used in landscape mode',
    OPERATION_TOO_LONG: 'Operation too long. Please try a shorter timeframe between start and end dates',
    "TITLE_REQUIRED": "Title is required",
    "COUPON_CODE_REQUIRED": "Coupon code is required",
    "INVALID_DISCOUNT_PERCENTAGE": "Discount percentage must be between 0 and 100",
    "START_DATE_REQUIRED": "Start date is required",
    "EXPIRATION_DATE_AFTER_START_DATE": "Expiration date must be after the start date",
    "COUPON_TYPE_REQUIRED": "Coupon type is required",
    "INVALID_USAGE_LIMIT": "Usage limit must be a non-negative number",
    "INVALID_USER_USAGE_LIMIT": "Per-user usage limit must be a non-negative number",
    "INVALID_USER_USAGE_LIMIT_GREATER_THAN_USAGE_LIMIT": "Per-user usage limit must be less than or equal to usage limit"
  },
  dateStaticRanges: {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    THIS_WEEK: 'This Week',
    LAST_WEEK: 'Last Week',
    THIS_MONTH: 'This Month',
    LAST_MONTH: 'Last Month'
  },
  subscription: {
    ACTIVE_SUB_MAIN_TITLE: 'Current Subscription: <b>%{title}</b>',
    LABEL_SUBSCRIPTION_TYPE: 'Subscription Type: <b>%{sub_type}</b>',
    LABEL_SUBSCRIPTION_CREATED: 'Created At: <b>%{sub_created}</b>',
    LABEL_SUBSCRIPTION_EXPIRATION: 'Valid Until: <b>%{sub_expiration}</b>',
    LABEL_SUBSCRIPTION_RENEWS: 'Renews at: <b>%{sub_expiration}</b>',
    LABEL_SUBSCRIPTION_PRICE: 'Price: <b>%{sub_price}</b>',
    LABEL_SUBSCRIPTION_MONTHLY_PRICE: 'Price: <b>%{sub_price}</b> a month',
    LABEL_SUBSCRIPTION_YEARLY_PRICE: 'Price: <b>%{sub_price}</b> a year',
    LABEL_SUBSCRIPTION_TRIAL_LEFT: '%{days} days Left',
    SUB_MAIN_TITLE: 'Your Medidate Membership is Over',
    SUB_TITLE1: 'Purchase Medidate Membership',
    SUB_TITLE2: 'Medidate Membership Subscription Complete',
    SUB_SUBTITLE: 'Hi %{firstName},',
    TRIAL_SUB_DESCRIPTION1: 'Your Medidate Trial Period is over.',
    TRIAL_SUB_DESCRIPTION2: 'Please purchase a membership to use the full features of Medidate',
    MEMBERSHIP_SUB_DESCRIPTION1: 'Your Medidate Membership &nbsp"<b>%{oldTitle}</b>"&nbsp is over.',
    MEMBERSHIP_SUB_DESCRIPTION2: 'Please purchase a new membership to use the full features of Medidate',
    MEMBERSHIP_SUB_DESCRIPTION3: 'Your Medidate Membership - &nbsp<b>%{oldTitle}</b>&nbsp is about to be over.',
    MEMBERSHIP_SUB_DESCRIPTION4: 'You may purchase a new membership here',
    MEMBERSHIP_TITLE1: 'Purchase %{newTitle1}',
    MEMBERSHIP_TITLE2: 'Purchase %{newTitle2}',
    MEMBERSHIP_DESCRIPTION1: 'On a %{newTitle1} you will be charged %{price1} a month',
    MEMBERSHIP_DESCRIPTION2: 'On a %{newTitle2} you will be charged %{price2} a year',
    MONTHLY_PRICE1: 'Monthly Price: <b>%{price1}</b>',
    MONTHLY_PRICE2: 'Monthly Price: <b>%{price2}</b>',
    YEARLY_PRICE: 'Yearly Price: <del>%{price1_old}</del> <b>%{price1}</b>',
    YEARLY_PRICE2: 'Yearly Price: <b>%{price1}</b>',
    MONTHLY_PRICE_WITH_VAT: 'Monthly Price Including VAT: <b>%{price}</b>',
    YEARLY_PRICE_WITH_VAT: 'Yearly Price Including VAT: <del>%{price_old}</del> <b>%{price}</b>',
    YEARLY_PRICE2_WITH_VAT: 'Yearly Price Including VAT: <b>%{price}</b>',
    MONTHLY_PERIOD: '<b>Monthly Payment</b>',
    MONTHLY_PERIOD1: 'Months: <b>%{months1}</b>',
    MONTHLY_PERIOD2: 'Months: <b>%{months2}</b>',
    YEARLY_PERIOD: '<b>Yearly Payment</b>',
    MEMBERSHIP_DESCRIPTION2: 'On a %{newTitle2} you will be charged %{price2} a month for %{months2} months',
    YEARLY: 'Yearly',
    HALF_YEAR: 'Half Year',
    MONTHLY_TITLE: '<b>Monthly Subscription</b>',
    YEARLY_TITLE: '<b>Yearly Subscription</b>',
    YEARLY_TITLE_REGULAR: 'Yearly Subscription',
    SUB_TYPE_TRIAL: 'Trial',
    SUB_TYPE_MONTHLY: 'Monthly',
    SUB_TYPE_YEARLY: 'Yearly',
    EQUALS_YEARLY: 'Equivalent of Yearly Payment:',
    THANK_YOU: 'Thank You!',
    TRIAL_LEFT: 'Trial Membership has %{days} days Left, click here to purchase a full membership',
    MEMBERSHIP_LEFT: 'Your Membership has %{days} days Left, click here to purchase a new membership',
    SUBSCRIBED_SUCCESS: 'Thank You for Subscribing, %{firstName}!',
    SUBSCRIBED_SIDEBAR: 'Subscribed to Medidate',
    PAYMENT_IFRAME: '<iframe src=callback_url width=540 height=450></iframe>',
    DISCOUNTED: '%{discount_percent} OFF',
    AFFILIATES_DISCOUNTED: 'Affiliates Bonus: EXTRA 1 FREE Month',
    SPECIAL_DISCOUNTED: '%{discount_percent} OFF for Yoga Association Studios',
    ADDITIONAL_DISCOUNTED: '%{discount_percent} Additional Discount by promo code',
    FAVOURITE_DISCOUNTED: 'A Special Price for a Special Person :)',
    CANCELLABLE: 'Cancellable',
    CANCEL_RENEWAL: 'Cancel Subscription Renewal',
    SUBSCRIPTION_YEARLY_SUGGEST: 'Yearly Payment, Save %{discount}%',
    SUBSCRIPTION_RENEWS: '*All subscriptions will auto-renew, you may cancel the renewal from Settings.'
  },
  common: {
    MONTHS: 'Months',
    WEEKS: 'Weeks',
    YES: 'Yes',
    NO: 'No',
    CANCEL: 'Cancel',
    CANCELED: 'Canceled',
    CLOSE: 'Close',
    THIS_WEEK: 'This Week',
    TODAY: 'Today',
    PREVIOUS: 'Previous',
    NEXT: 'Next',
    FINISH: 'Finish',
    ACCEPT: 'I Accept the Terms of the Studio',
    ACCEPT_2: 'I Accept the Terms and Agreement',
    MARK: 'Mark',
    UPDATE: 'Update',
    OR: 'Or',
    FROM: 'From',
    TO: 'To',
    PLEASE_SELECT: 'Please Select',
    SHARE_PROFILE: 'Share Profile',
    RESEND_INVITE: 'Resend Invite',
    RESEND_TERMS: 'Resend Studio Terms',
    OPEN_TERMS: 'Terms Confirmed',
    RESEND_FORM: 'Send Form Again',
    RESENDED_FORM: 'Form Sent Again',
    FORM_SENT: 'Form sent',
    OF_USER: 'by %{teacherName}',
    BY: '<font size="0.5">%{teacherName}</font>',
    TOTAL: '<b>Total</b>',
    TOTAL_REGULAR: 'Total',
    NEVER: 'Never',
    CHECK_ALL: 'Check All',
    DOWNLOAD: 'Download',
    RECEIVED_BY: '<b>Received By</b>',
    RECEIVED_BY_TOOLTIP: 'The personnel that accepted the payment for this product (class/session/workshop and so on)',
    EXPAND: 'Expand',
    MINIMIZE: 'Minimize',
    INSTALLMENTS: 'Enable Payments',
    INSTALLMENTS_TOOLTIP: 'Relevant for Credit Card payments only - Allow the student to choose from a regular payment to a number of installments the studio allows for this product',
    REGULAR_PAYMENT: 'Regular Payment',
    UP_TO_3_PAYMENT: 'Up to 3 payments',
    UP_TO_6_PAYMENT: 'Up to 6 payments',
    UP_TO_9_PAYMENT: 'Up to 9 payments',
    UP_TO_12_PAYMENT: 'Up to 12 payments',
    PLEASE_SAVE_FIRST: 'You have unsaved changes, please save first and then you can proceed with this operation',
    CREATED_AT: 'Created on',
    UPDATED_ON: 'Updated on',
    ADDED_ON: 'Added on',
    ADDED_BY: 'by',
    MANUALLY_PUNCHED: 'Manually punched: %{manually_punched}',
    FROZEN_ON_DATE: 'Frozen on',
    UNFREEZE_DATE: 'Unfreeze on',
    NOT_SET: 'Not Set',
    GOT_IT: 'Got it! Thanks',
    CLICK_HERE: 'Click Here',
    DATE: 'Date',
    PURCHASE: 'Purchase',
    BUY: 'Buy',
    PRICE: 'Paid Amount',
    PAY_TO: '<h1>For Studio <b>%{name}</b></h1>',
    CONNECTED_AS: 'Connected as <b>%{name}</b>',
    CONFIRM_PAYMENT: 'Confirm Payment',
    DASHBOARD_TITLE: 'Medidate',
    DASHBOARD_DESCRIPTION: 'Medidate Studio Management Software helps teachers and studio owners to manage their students, payments, classes, subscriptions, accounting automations and more..',
    DASHBOARD_URL: 'https://www.medidatewith.me/',
    EXTERNAL_LINKS: 'External Links',
    MEDIDATE_ADMIN_OPTIONS: 'Medidate Admin Options',
    MEDIDATE_ADMIN_CONNECT_GROW_USER_TO_MEDIDATE: 'Connect existing Grow user to Medidate',
    SIGNIN: 'Sign In',
    CONTINUE: 'Continue',
    CONTINUE_NEW_WINDOW: 'Continue in new Window',
    OPEN_NEW_WINDOW: 'Open in new Tab',
    CONTACT: 'Contact',
    CONTACT_TIP: 'Leave a message of any kind, we will get back to you soon',
    PHONE: 'Phone Call',
    WHATSAPP: 'Whatsapp',
    SEARCH_COUNTRY: 'Search Country',
    BANK_CODE: "Bank Code",
    BANK_BRANCH: "Bank Branch",
    BANK_ACCOUNT: "Bank Account",
    CONTACT_WHATSAPP: "Contact by Whatsapp",
    SORT: "Sort",
    FILTER: "Filter",
    ASCENDING: "Ascending",
    DESCENDING: "Descending",
    SERIAL: "S.N:",
    COMING_SOON: "Coming Soon",
    REFRESH: "Refresh",
    FULL_NAME: "Full Name",
    COMPANY_ID: "Company ID/ Your ID Number",
    PHONE_NUMBER: "Phone Number",
    MEDIDATE_OBJECT_ID: 'Enter objectId of Medidate user',
    GROW_USER_ID: 'Enter userId of Grow user',
    SAVE: "Save",
    LOADING: "Loading...",
    SAVED_SUCCESSFULLY: "Coupon saved successfully",
    ERROR_OCCURRED: "An error occurred. Please try again.",
  },
  paymentCoupon: {
    COUPON: "Coupon",
    TITLE: "Coupon Title",
    ACTIVATE_COUPON: "Activate Coupon",
    ACTIVATE_COUPON_TOOLTIP: "Enable this to apply a coupon to the product",
    COUPON_CODE: "Coupon Code",
    COUPON_CODE_TOOLTIP: "Enter the coupon code here",
    COUPON_TYPE: "Product",
    COUPON_TYPE_TOOLTIP: "Choose the type of product you want to create a coupon for",
    FINAL_PRICE: "Price After Discount",
    FINAL_PRICE_TOOLTIP: "Price After Discount for coupon owners",
    DISCOUNT_PERCENTAGE: "Discount Percentage",
    DISCOUNT_PERCENTAGE_TOOLTIP: "Enter the discount percentage for this coupon",
    COUPON_ACTIVATED: "Coupon Activated",
    COUPON_DEACTIVATED: "Coupon Deactivated",
    COUPON_PAUSED: "Coupon Paused",
    START_DATE: 'Start Date (Optional)',
    START_DATE_TOOLTIP: 'Choose coupon start date if you want it to have one, remove start date to make it active until you deactivate it manually',
    EXPIRATION_DATE: 'Expiration Date (Optional)',
    EXPIRATION_DATE_TOOLTIP: 'Choose coupon expiration date if you want it to have one, remove expiration date to make it active until you deactivate it manually',
    SELECT_EXPIRATION_DATE: 'Choose Expiration Date',
    REMOVE_EXPIRATION_DATE: 'Remove Expiration Date',
    SELECT_START_DATE: 'Choose Start Date',
    REMOVE_START_DATE: 'Remove Start Date',
    TICKETS: 'Tickets',
    MEMBERSHIPS: 'Memberships',
    PRODUCTS: 'Products',
    SESSIONS: 'Sessions',
    TICKET: 'Ticket',
    MEMBERSHIP: 'Membership',
    PRODUCT: 'Product',
    SESSION: 'Session',
    COUPONS_LIST: 'Coupons',
    CREATE_COUPON: 'Create New Coupon',
    EDIT_COUPON: 'Edit Coupon',
    SAVE_COUPON: 'Save Coupon',
    SAVE_COUPON_SUCCESS: 'Coupon saved successfully',
    SELECT_COUPON_TYPE: 'Select Coupon Type',
    MORE_COUPON: 'All Coupons',
    MORE_COUPON_TOOLTIP: 'Manage All Coupons and Promotions.\nMultiple Coupons for each product are allowed',
    DISCOUNTED: '(%{discount_percent}% OFF Coupon)',
    COUPON_CHECKBOX_TOOLTIP: "I have a Coupon Code",
    COUPON_USED: 'Coupon Used %{coupon_used} times',
    PLACEHOLDER: "Enter promo code",
    APPLY_BUTTON: "Apply Promo Code",
    VALID_CODE: "Valid promo code applied!",
    INVALID_CODE: "Invalid promo code.",
    SUCCESS_ALERT: "Promo code applied successfully!",
    ERROR_ALERT: "Invalid promo code. Please try again.",
    VALIDATION_ERROR: "An error occurred while validating the promo code.",
    COUPON_NOT_EXISTS: "This coupon does not exist.",
    COUPON_NOT_ACTIVE: "This coupon is not active.",
    COUPON_NOT_DISCOUNT: "This coupon does not provide a discount.",
    COUPON_ACTIVATED_SUCCESS: "Coupon activated successfully!",
    COUPON_USAGE_LIMIT: "Usage Limit (Optional)",
    COUPON_USAGE_LIMIT_TOOLTIP: "Maximum number of times this coupon can be used in total",
    COUPON_USER_USAGE_LIMIT: "Per-User Usage Limit (Optional)",
    COUPON_USER_USAGE_LIMIT_TOOLTIP: "Maximum number of times a single user can use this coupon",
    COUPON_NO_LIMIT: "No limit"
},
  instrcutionsTypes: {
    OVERVIEW: 'Short Overview',
    SIGNUP: 'First Signup',
    SETTINGS: 'Studio Settings',
    PLANS_CREATION: 'Plans and Memberships',
    SCHED: 'Weekly Schedule',
    ADD_REMOVE_STUDENTS: 'Add/Remove/Find Students',
    STUDENTS: 'Manage Students & Classes',
    STUDENT_INFO: 'Student\'s Info Page',
    PLANS: 'Manage Plans',
    ONBOARDING_STUDENTS: 'Onboarding and Import Students'
  },
  paymentTypes: {
    CASH: 'Cash',
    CHECK: 'Check',
    BANK_TRANSFER: 'Bank Transfer',
    OTHER: 'Other',
    CREDIT_CARD: 'Credit Card',
    TICKET: 'Ticket',
    MEMBERSHIP: 'Membership',
    TRIAL: 'Trial Lesson',
    PARTIAL: 'Partial Paid',
    PAYMENTS: 'Payments',
    AUTO_PAYMENTS: 'Auto Payments',
    PAYMENTS_DONE: 'Payments Done',
    BIT: 'Bit',
    PENDING_FIRST_PAYMENTS: 'Pending first payment...',
    TRIAL_PRICE: 'Trial Price'
  },
  paymentPaidTypes: {
    CASH: 'Cash',
    CHECK: 'Check',
    BANK_TRANSFER: 'Bank Transfer',
    OTHER: 'Other'
  },
  accountingTypes: {
    ICOUNT: 'iCount',
    GROW_INVOICE: 'Grow Invoices'
  },
  bankNames: {
    4: 'בנק יהב לעובדי המדינה',
    9: 'בנק הדואר',
    10: 'בנק לאומי',
    11: 'בנק דיסקונט',
    12: 'בנק הפועלים',
    13: 'בנק אגוד לישראל',
    14: 'בנק אוצר החייל',
    17: 'בנק מרכנתיל דסקונט',
    20: 'בנק מזרחי-טפחות',
    22: 'סיטיבנק',
    23: 'HSBC',
    26: 'UBANK',
    31: 'הבין לאומי הראשון',
    34: 'בנק ערבי ישראלי',
    39: 'בנק אוף אינדיה',
    46: 'בנק מסד',
    52: 'בנק פאגי',
    54: 'בנק ירושלים',
    68: 'דקסיה ישראל',
    77: 'בנק לאומי למשכנתאות',
    90: 'בנק דיסקונט למשכנתאות',
    91: 'משכן בנהפ למשכנתאות',
    92: 'הבין לאומי למשכנתאות'
  },
  sortOptions: {
    CREATED: "Sort by Added on",
    UPDATED: "Sort by Updated at",
    NAME: "Sort by Name",
    TITLE: "Sort by Title",
    EMAIL: "Sort by Email",
    PHONE: "Sort by Phone",
    DATE: "Sort by Date",
    EXPIRATION_DATE: "Sort by Expiration Date",
    PAID: "Sort by Paid",
    HIDDEN: "Sort by Hidden from Clients",
    PRICE: "Sort by Price",
    FROZEN: "Sort by Frozen",
    FREQUENT: "Sort by Frequent"
  },
  filterOptions: {
    PAID: "Paid",
    NOT_PAID: "Not Paid",
    HAVE_MEMBERSHIP: "Has Memberhips",
    NO_MEMBERSHIP: "No Memberhips",
    HAVE_TICKET: "Has Punch Tickets",
    NO_TICKET: "No Punch Tickets",
    HAVE_PLAN: "Has Active Plan",
    NO_PLAN: "No Active Plan",
    ACTIVE: "Active",
    NOT_ACTIVE: "Not Active",
    NEEDS_RECEIPT: "Paid Requires Receipt",
    HAVE_RECEIPT: "Has Receipt",
  },
  nav: {
    HOME: 'Home',
    PROFILE: 'Settings',
    STUDENTS: 'Students and Clients',
    PLANS: 'Plans and Memberships',
    PRODUCTS: 'Products',
    COUPONS: 'Coupons',
    INSIGHTS: 'Insights',
    SUMMARIES: 'Summary and Reports',
    ACCOUNTING: 'Book Keeping',
    BILLING: 'Payments',
    AFFILIATES: 'Our Affiliation Program',
    LOGOUT: 'Logout',
    MESSAGES: 'Messages',
    SUPPORT: 'Support',
    TUTORIALS: 'Tutorials',
    AUTOMATIONS: 'Automations'
  },
  clientStatus: {
    NEW: 'New',
    INTERESTED: 'Interested',
    NOT_INTERESTED: 'Not Interested',
    ACTIVE: 'Active',
    NO_ACTIVE: 'Not Active',
    ON_HOLD: 'On Hold',
    REACH_OUT: 'Reach out Again',
    NO_STATUS: 'No Status',
  },
  studentStatus: {
    NO_STATUS: 'No Status',
    CANCELLED_REFUNDED: 'Cancelled and got a refund',
    CANCELLED_NOT_REFUNDED: 'Cancelled and did not get a refund',
    ARRIVED: 'Arrived',
    DID_NOT_ARRIVE: 'Did not Arrive',
  },
  modal: {
    OK: 'Ok',
    CANCEL: 'Cancel'
  },
  breadcrumbs: {
    DASHBOARD: 'Dashboard',
    HOME: 'Home',
    PROFILE: 'Settings',
    STUDENTS: 'Students',
    MESSAGES: 'Messages',
    PLANS: 'Plans and Memberships',
    PRODUCTS: 'Products',
    COUPONS: 'Coupons',
    INSIGHTS: 'Insights',
    LIST: 'List',
    SESSIONS: 'Sessions',
    EDIT_SESSION: 'Edit Session',
    BILLING: 'Payments',
    BILLING_SETUP: 'Billing Details',
    TICKET_EDIT: 'Edit Ticket',
    MEMBERSHIP_EDIT: 'Edit Memberships',
    PRODUCT_EDIT: 'Edit Product',
    COUPON_EDIT: 'Edit Coupon',
    AUTOMATIONS: 'Automations',
    REPORTS: 'Summary and Reports',
    TUTORIALS: 'Tutorials'
  },
  notifications: {
    NEW_NOTIFICATIONS: 'New Notifications',
    NO_NEW_NOTIFICATIONS: 'No New Notifications',
    READ_NOTIFICATIONS: 'Read Notifications',
    MARK_READ_NOTIFICATIONS: 'Mark All as Read',
    NOTIFICATIONS: 'Notifications'
  },
  login: {
    TITLE: 'Studios and Teachers Login',
    SUBTITLE: 'Sign in to Medidate',
    AFFILIATED: 'Invited by:',
    LOGIN_BUTTON: 'Login',
    FORGOT_PASSWORD: 'Forgot Password?',
    REGISTER_TITLE: 'Studios and Teachers Sign up',
    REGISTER_STUDENT_TITLE: 'Student Sign up',
    REGISTER_AFFILIATE_TITLE: 'Medidate Affiliation Sign up',
    REGISTER_STUDENT_SUCCESS: 'Signed Up Successfully!',
    REGISTER_SUBTITLE: 'New to Medidate? Sign up now!',
    REGISTER_AFFILIATOR_SUCCESS_REGISTER_SUBTITLE: 'A mail was sent to you with your login information for Medidate',
    REGISTER_STUDENT_SUCCESS_REGISTER_SUBTITLE: 'A mail was sent to you with your login information for the Medidate App, that allows you to sign into classes',
    REGISTER_STUDENT_SUCCESS_REGISTER_NOTE: '*if you cant find the email sent to you check the Promotion / Social / Updates or Spam Folder',
    REGISTER_BUTTON: 'Sign Up',
    PLACEHOLDER_EMAIL: 'Email',
    PLACEHOLDER_PASSWORD: 'Password',
    REGISTER_TEXT_SMALL: 'Not a user?',
    LANGUAGE_TOGGLE: 'Hebrew',
    MEDIDATE_PAID_NOTICE: '<p><span style="font-size:15px;color:white;"><strong>Medidate is completely FREE to use and try until the %{subscriptionFirstDay} <a href="%{link}" style="color:blue" target="_blank">Price</a></strong></span></p>',
    CONTINUE_AFFILIATION: 'Continue to my Affiliation Page',
    CONTINUE_PURCHASE: 'Continue browsing Session or Product',
    CONTINUE_REGISTER: 'We\'ve sent you an email with login details, continue the registration later through the email or continue now through a new window'

  },
  forms: {
    FORM_LOGIN_TITLE: 'In order to view this page you need to login to Medidate',
    FORM_RESTRICTED_ACCESS: 'Resticted Access',
    FORM_LOGIN_WITH_DIFFERENT_USER: 'Login with a different user',
    AFFILIATES_SUBTITLE: 'Please sign the following aggrement so we may legaly transfer your profits from our Affiliation Program.',
    HEALTH_TITLE: 'Health Declaration',
    HEALTH_SUBTITLE: 'The Studio\'s workout is suitable for any age and level of strength, fitness and flexibility. However, there are a number of medical conditions that require slightly different training and special attention from the teacher and practitioner. Therefore, please know the teacher in your basic medical history.',
    HEALTH_BLOOD: 'Blood Pressure',
    HEALTH_SUGAR: 'Diabetes',
    HEALTH_HEAD: 'Headaches, dizziness, weakness',
    HEALTH_BREATH: 'Asthma or respiratory problems',
    HEALTH_BALANCE: 'Equilibrium problems',
    HEALTH_NECK: 'Neck, neck and shoulder problems',
    HEALTH_JOINTS: 'Joint problems',
    HEALTH_BACK: 'Spine problems (disk hacks, scoliosis, etc.)',
    HEALTH_DIGESTION: 'Digestive problems',
    HEALTH_EARS: 'Ear problem',
    HEALTH_EYES: 'Glaucoma or other eye problems',
    HEALTH_CHRONIC: 'Some chronic illness (active or dormant)',
    HEALTH_SURGERY: 'Surgical surgery',
    HEALTH_SMOKING: 'Smoking',
    HEALTH_PREGNANT: 'Are you pregnant?',
    HEALTH_PREGNANT_NUM: 'How many births did you go through?',
    HEALTH_MANS: 'Are there menstrual problems?',
    HEALTH_BEFORE_TERMS: 'This information is needed for the studio to adjust your practice and to asses if any medical forms are needed for you to participate in the classes. **If you\'re menstruating you should avoid certain positions and practices, please consult your teacher before class begins.',
    HEALTH_TERMS: 'I declare that it is clear to me that all of the above are essential for the type and nature of the treatment / practice and I have answered the questions honestly and in good faith.',
    HEALTH_SUBMIT: 'I read and confirm',
    HEALTH_WOMEN: 'For Women:',
    HEALTH_GENERAL_COMMENT: 'General Note',
    EDIT_STUDIO_TERMS: 'Edit Studio Terms',
    EDIT_STUDIO_TERMS_HINT: 'Your Studio Terms and Conditions Here',
    HEALTH_SEND_FORM: 'Send Health Statement Form',
    HEALTH_SEND_TO_STUDENT: 'Send Health Statement Form to student\'s email?',
    HEALTH_SIGNATURE: 'Signature',
    HEALTH_CLEAR_SIGNATURE: 'Clear Signature',
    HEALTH_FIRST_NAME: 'First Name',
    HEALTH_LAST_NAME: 'Last Name',
    HEALTH_SOCIAL_ID: 'Social Security Number',
    HEALTH_ALERT_TERMS: 'Please agree to the terms and declaration',
    HEALTH_ALERT_SGINATURE: 'Please add your signature on the bottom',
    HEALTH_ALERT_NAME: 'Please enter your First and Last Name to the form',
    HEALTH_ALERT_SOCIAL_ID: 'Please enter your ID Number',
    HEALTH_ALERT_BIRTH_DATE: 'Please enter your birth date',
    HEALTH_BIRTHDATE: 'Birth Date',
    PAY_FOR: '<h1>Purchase %{className}: <b>%{name}</b></h1>',
    SESSION_FOR: '<h1>%{className}: <b>%{name}</b></h1>',
    CORONA_TITLE: 'Corona Virus Statement',
    CORONA_COUGHING: 'I declare that I am not coughing',
    CORONA_TEMPRATURE: 'I declare that my temprature is not above 38 celsius degrees, nor did I have the stated temprature in the last week',
    CORONA_CLOSE_CONTACT: 'I declare that I wasn\'t in close contact with anyone that is/was Corona Virus ill in the past two weeks',
    CORONA_SEND_FORM: 'Send Corona Form to student\'s email?',
    AGREE_TO_ALL_STATEMENTS: 'I Agree to All Statements Above',
    SUBSCRIBE_STUDENT_STUDIO_EMAILS: 'Not in Studio Mailing List',
    UNSUBSCRIBE_STUDENT_STUDIO_EMAILS: 'In Studio Mailing List',
    UNSUBSCRIBED_STUDENT_STUDIO_EMAILS: 'Removed from Studio Mailing List',
    SUBSCRIBED_STUDENT_STUDIO_EMAILS: 'Added to Studio Mailing List',
    AFFILIATES_TERMS_TITLE: 'Medidate Affiliation Program Terms and Conditions'
  },
  register: {
    TITLE: 'Sign Up',
    SUBTITLE: 'Create a new Medidate account',
    AFFILIATES_SUBTITLE: 'Create a new Medidate Affiliator account',
    PLACEHOLDER_PASSWORD: 'Password',
    PLACEHOLDER_REPEAT_PASSWORD: 'Repeat Password',
    PLACEHOLDER_FIRSTNAME: 'First Name',
    PLACEHOLDER_LASTNAME: 'Last Name',
    PLACEHOLDER_NAME: 'Full Name',
    PLACEHOLDER_EMAIL: 'Email',
    SIGNUP_BUTTON: 'Create Account',
    AGREED_TO_TERMS: 'I agree to the <a href="%{termsLink}" target="_blank">terms and conditions</a> & <a href="%{privacyLink}" target="_blank">privacy policy</a>',
    PROMOTION_CONSENT: 'I agree to receive promotional Emails',
    LABEL_PHONE_NUMBER: 'Phone Number',
    LABEL_STUDENT_PHONE_NUMBER: 'Phone Number',
    ACCOUNT_EXISTS_ERROR_MESSAGE: 'Account already exists, please enter through the Login Page',
    BUSINESS_DETAILS_TITLE: 'Business Details',
    BUSINESS_DETAILS: 'Tell us more about your Business',
    ALERT_ENTER_NAME: 'Please enter your first name',
    ALERT_ENTER_FIRST_NAME: 'Please enter your first name',
    ALERT_ENTER_LAST_NAME: 'Please enter your last name',
    ALERT_ENTER_EMAIL: 'Please enter a valid email',
    ALERT_ENTER_PASSWORD: 'Please enter a password',
    ALERT_REENTERED_PASSWORD_NOT_SAME: 'Re-entered password does not match original passowrd',
    ALERT_REENTER_PASSWORD: 'Please reenter a password',
    ALERT_APPROVE_TERMS: 'Please approve terms of use',
    ALERT_SELECT_OPTION: 'Please select an option',
    ALERT_PHONE_NUMBER: 'Please enter your phone number',
    ALERT_PHONE_COUNTRY: 'Please choose Phone number country code',
    ALERT_BANK_DETAILS: 'Please Enter Bank Details',
    ALERT_BANK_NUMBER: 'Please Enter Bank Code',
    ALERT_BANK_BRANCH_NUMBER: 'Please Enter Bank Branch Number',
    ALERT_BANK_ACCCOUNT_NUMBER: 'Please Enter Bank Account Number',
    ALERT_SUBJECT_PLACEHOLDER: 'I need help, please get back to me',
    ALERT_SUBJECT: 'Please Enter your subject',
    ALERT_MESSAGE: 'Please Enter your message',

  },
  wizard: {
    STEP_ONE_TITLE: 'Welcome, %{name}!',
    LABEL_QUALIFICATION: 'Does this Studio manage multiple teachers?',
    NEXT_STEP_BUTTON: 'Next Step',
    LABEL_DESCRIPTION: 'Description',
    LABEL_PROFILE_PHOTO: 'Add Profile Picture or Logo',
    LABEL_TEACHING_STYLES: 'Teaching Styles',
    LABEL_YOGA: 'Yoga',
    LABEL_MEDITATION: 'Meditation',
    PLACEHOLDER_YOGA: 'Choose yoga styles',
    PLACEHOLDER_MEDITATION: 'Choose meditation styles',
    ALL_SET_TITLE: 'First let\'s walk through a few setups to get you started',
    ALL_SET_TEXT: 'No worries you will always be able to update your settings later on',
    GO_TO_DASHBOARD: 'Let\'s Go...',
    LABEL_COUNTRY: 'Country',
    LABEL_CITY: 'Business Address',
    LABEL_BANK_COUNTRY: 'Where is your business located? (country)',
    ALERT_MUST_HAVE_STUDIO_NAME: 'Please enter the name of the studio',
    ALERT_MUST_HAVE_BANK_COUNTRY: 'Please enter your business country',
    PROFILE_STEP_1_TITLE: 'So tell us about your studio a bit',
    PROFILE_STEP_1_SUB_TITLE: 'If you have a logo or a nice photo related to you or your studio it really goes a long way in the medidate app',
    PROFILE_STEP_2_TITLE: 'Are you planning to have your students signup to classes through the Medidate App?',
    PROFILE_STEP_2_SUB_TITLE: '<div>If so, we suggest you turn ON the send invites to students so they get the email to dowanlod the app.<br/>Browse through the other options see what\'s relevant.<br/>Also&nbsp;have a look at the <a target="_blank" href="%{baseUrl}/#/pages/forms/health/%{userId}/%{userId}/%{locale}"><span style="color:#9141bc;">Health Declaration Form</span></a> and see if it suits your studio.</div>',
    PROFILE_STEP_3_TITLE: 'What are the things would you like to get notified about?',
    PROFILE_STEP_3_SUB_TITLE: 'Note that if you are signed up as the studio to the Medidate App you will get these notifications to the app if you\'d rather not get your email clugged up.<br/>What email notifications are relevant?<br/>Would you like to know if someone tried to cancel class but was in the cancelation range?<br/>Would you like to know if someone tried to signup to class but wasn\'t in the registration range?',
    PROFILE_STEP_4_TITLE: 'One last thing...',
    PROFILE_STEP_4_SUB_TITLE: 'Would you like your students, inside the app, see other signed up students in class?<br/>If not, make sure the Hidden is ON (marked green in the switch)',
    PROFILE_STEP_5_TITLE: 'What\'s the Studio\'s Cancelation and Registration policy?',
    PROFILE_STEP_5_SUB_TITLE: 'Should signing to classes through the app be possible only if the student has a valid studio plan (Ticket or Membership)?<br/>Do you want students to fill the health or studio terms form before they can signup through the app?<br/>How long before class begins can a student cancel?<br/>How long before class begins can students start signing into class?',
    MEDIDATE_TUTORIAL: 'Walkthrough & Tutorials',
  },
  teacherTypes: {
    NONE: 'No Qualification',
    PRACTITIONER: 'Practitioner',
    INSTRUCTOR: 'Instructor',
    TEACHER: 'Teacher',
    STUDIO: 'Studio'
  },
  answerTypes: {
    YES: 'Yes',
    NO: 'No'
  },
  repetitionOptions: {
    ONCE: 'Once',
    DAILY: 'Daily (option not supported)',
    WEEKLY: 'Weekly (occurs once a week)',
    MONTHLY: 'Monthly (occurs once a month)'
  },
  timeFormatOptions: {
    HOURS_24: '24 Hours',
    AM_PM: 'AM/PM'
  },
  sessionTypes: {
    MEDITATION: 'Meditation',
    YOGA: 'Yoga',
    TAI_CHI: 'Tai Chi',
    MUSIC: 'Music',
    PILATES: 'Pilates'
  },
  priceTypes: {
    FREE: 'Free',
    PRICE_RANGE: 'Price for Dropin',
    MEDIDATE_PRICING: 'Price for Dropin'
  },
  businessTypes: {
    STUDIO: 'Studio - Teachers | Students | Classes',
    CENTER: 'Center - Teachers | Students | Classes',
    CLINIC: 'Clinic - Therapists | Patients | Sessions',
    BUSINESS: 'Business - Sellers | Clients | Items',
    SPORTS_CLUB: 'Sports Club - Trainers | Trainees | Practices'
  },
  home: {
    MY_STUDENTS_TITLE: 'Studio Students <b>(%{count})</b>',
    MY_STUDENTS_TITLE_NO_COUNT: 'Studio Students',
    MY_ARCHIVED_STUDENTS_TITLE: 'Potential Clients <b>(%{archived_count})</b>',
    MY_ARCHIVED_STUDENTS_TITLE_NO_COUNT: 'Potential Clients',
    MY_WEEKY_SCHEDULE_TITLE: 'Weekly Schedule',
    STUDIO_WEEKY_SCHEDULE_TITLE: '<b>%{studio}</b>: Weekly Schedule',
    SHOW_ALL_STUDENTS: 'Show All Students in Studio',
    SHOW_ALL_ARCHIVED: 'Show All Potential Clients',
    STUDIO_MONTHLY_ACTIVITY: 'Monthly Activity',
    POWERED_BY: 'Powered by Medidate'
  },
  forgotPassword: {
    TEXT: 'Type your email address and we will send an email with instructions',
    SUBMIT_BUTTON: 'Submit',
    SUCCESS_MESSAGE: 'We\'ve sent you an email with a reset password link, please check your email inbox.'
  },
  form: {
    ON_LEAVE_PROMT: 'Your changes are not saved yet. Are you sure you want to leave this page?',
    DATE: 'Date',
    MONTH: 'Month',
    YEAR: 'Year',
    OPTIONAL: 'Optional'
  },
  product: {
    LABEL: 'Product',
    PRICE: 'Price',
    PRICE_VAT: 'Price (Including VAT)',
    PAYMENT_METHOD: 'Payment Method',
    PAYMENT_ITERATIONS: 'x %{count} monthly iterations of %{monthlyPrice} a month',
    PAYMENT_ITERATIONS_TIP: '* Price is an accumulated sum of the number of monthly iterations for a membership and it\'s monthly price'
  },
  studentPlans: {
    PLAN_LABEL: 'Plan',
    MODAL_TITLE: '%{name} Plans',
    PLAN_NAME: 'Plan Name',
    ENTRIES: 'Entries',
    ENTRIES_USED: 'Entries Used',
    ENTRIES_LEFT: 'Entries Left',
    EXPIRATION_DATE: 'Expiration Date',
    EXPIRED_AT: 'Expires at',
    PAID: 'Paid',
    PLAN_PAID: 'Plan Paid',
    TICKETS: 'Active Tickets',
    MEMBERSHIPS: 'Active Memberships',
    FROZEN_MEMBERSHIPS: 'Frozen Memberships',
    FROZEN_TICKETS: 'Frozen Tickets',
    EXPIRED_MEMBERSHIPS: 'Expired Memberships',
    EXPIRED_TICKETS: 'Expired Tickets',
    NO_PLANS: 'No Plans founds for student',
    SHOW_ACTIVE_TICKETS: 'Only Show Active Plan',
    FROZEN_STATUS: 'Frozen Status',
    REMOVE_STATUS_PAID_WARNING: 'Please remove status Paid from this plan before removing it.'
  },
  profile: {
    TITLE: 'Profile Details',
    OTHER_TITLE: '%{other_user} Profile Details',
    SAVE_BUTTON: 'Save Changes',
    FINISH_AND_SAVE: 'Done',
    Hide: 'Hide',
    RESET_PASSWORD: 'Reset Password',
    STUDIO_TERMS: 'Studio Terms',
    GENDER: 'Gender',
    MALE: 'Male',
    FEMALE: 'Female',
    LANGUAGE: 'Language',
    TIME: 'Time Format',
    PAYMENTS: 'Payments',
    PRIVACY: 'Privacy',
    CLASSES_PRIVACY: 'Classes Privacy',
    CLASSES_PREFERENCES: 'Classes Preferences',
    SUSPEND_PAYME_PAYMENTS: 'Suspend PayMe Payments',
    SUSPEND_PAYME_PAYMENTS_TOOLTIP: 'This will suspend the payments option in your system. Clients are not be able to pay through credit card while suspended.',
    SUSPEND_GROW_PAYMENTS: 'Suspend Grow Payments',
    SUSPEND_GROW_PAYMENTS_TOOLTIP: 'This will suspend the payments option in your system. Clients are not be able to pay through credit card while suspended.',
    ALLOW_BIT_PAYMENTS: 'Allow payments through the Medidate app with Bit (Relevant only to studios using the payments system)',
    ALLOW_BIT_PAYMENTS_TOOLTIP: 'If registered to the payment system, this option allows students to purchase products through the app using Bit',
    ALLOW_APPLE_PAYMENTS: 'Allow payments through the Medidate app with Apple Pay (Relevant only to studios using the payments system)',
    ALLOW_APPLE_PAYMENTS_TOOLTIP: 'If registered to the payment system, this option allows students to purchase products through the app using Apple Pay',
    HIDE_FREE_AMOUNT_PAYMENTS: 'Hide Free Amount Payments from students through the Medidate app',
    HIDE_FREE_AMOUNT_PAYMENTS_TOOLTIP: 'If registered to the payment system, this option prevents students from paying you through your profile in the app',
    HIDE_DROPIN_AMOUNT_PAYMENTS: 'Hide the Price of a a session from students through the Medidate app',
    HIDE_DROPIN_AMOUNT_PAYMENTS_TOOLTIP: 'This option prevents students from seeing the Dropin Price of a session through the app',
    BIRTH_DATE: 'Birth date',
    SHOW_BIRTH_DATE: 'Show Birthdate',
    VAT_EXEMPT_DEALER: 'VAT exempt dealer',
    VAT_NOT_EXEMPT_DEALER: 'Not VAT exempt dealer',
    SEND_BIRTH_DATE: 'Notify on Birthday',
    HAS_BIRTH_DAY: 'Student is Celebrating a Birthday',
    ELIGIBLE_TRIAL_SESSION: 'Eligible for Trial Session',
    FREE_MONTH: 'You\'ll be rewarded for each studio that came through you and started working with Medidate, and they\'ll receive an extra 2 free weeks on top of the 30 day trial period',
    QUALIFICATIONS: 'Teacher or Studio?',
    SAVE_SUCCESS_ALERT: 'Profile changes saved!',
    INVITATION_SENT_ALERT: 'Invitation was sent',
    TERMS_SENT_ALERT: 'Terms was sent',
    FORM_SENT_ALERT: 'Saved Health Statement',
    FORM_APPROVED: 'Thank you for approving the terms',
    LABEL_BUSINESS_TYPE_TITLE: 'Business Type:',
    STUDIO_EDIT_TITLE: 'Teachers in Studio',
    LABEL_STUDIO_TITLE: 'Studio Name',
    IS_STUDIO: 'This studio has multiple teachers',
    ASSOCIATED_TEACHERS_TITLE: 'Studio Teachers <b>(%{count})</b>',
    OWNER: 'Owner',
    PLACEHOLDER_ADD_TEACHER: "Type an email address to add a new teacher",
    PLACEHOLDER_FILTER_USERS: "Filter students",
    PLACEHOLDER_FIND_USERS: "Find Students in the Studio",
    FILTER_BY_STATUS: "Filter by Status:",
    REMOVE_TEACHER: 'Remove from studio',
    LABEL_ADD_TEACHER: "New teacher",
    YOUR_TEACHERS: "Your studio's teachers",
    YOUR_TEACHERS_TOOLTIP: 'Add teachers that teach in your studio. After this you can assign the respected teacher as the teacher of the class/session/workshop/retreat. The calendar will show the teacher of the class',
    INVITE_USER: 'Invite %{email} to Medidate',
    ADD_USER: 'Add %{email} to Medidate',
    ADD_TEACHER_NO_RESULT_TEXT: 'No Medidate users found. Type an email address to invite a new user.',
    USER_FILTER_NO_RESULT_TEXT: 'No students found',
    USER_ADDED_ALERT: "%{name} Successfully added %{email} to Medidate",
    USER_ADDED_INVITED_ALERT: "%{name} Successfully invited %{email} to Medidate",
    USER_REGISTERED_ALERT: "Success, Please check your email",
    EMAIL_STUDIO_NOTIFICATION_TITLE: 'Studio Notifications Preferences',
    EMAIL_STUDENT_NOTIFICATION_TITLE: 'Student\'s Notifications Preferences',
    CHAT_SETTINGS_TITLE: 'Chat Settings',
    WHATSAPP_NOTIFICATION_TITLE: 'Whatsapp Notifications Preferences',
    WHATSAPP_NOTIFICATION_TITLE_TOOLTIP: 'Send Selected Notifications also through Whatsapp',
    SESSION_EMAIL_INVITE: 'Get an email when I\'m invited to a session/class',
    PLAN_EMAIL_ENDING: 'Have students receive emails when their plan is about to end',
    PLAN_EMAIL_RENEWING_STUDIO: 'Receive emails when Student\'s plan is about to renew',
    PLAN_EMAIL_RENEWED_STUDIO: 'Receive emails when Student\'s plan renewed',
    PLAN_ENDED_STUDIO: 'Receive emails when student\'s plans end. This option is applicable if students receiving this email is On',
    PLAN_EMAIL_RENEWING: 'Have students receive emails when their plan is about to renew',
    PLAN_EMAIL_RENEWED: 'Have students receive emails when their plan renewed',
    SEND_EMAIL_INVITES: 'Send invitations to the Medidate app when adding students to the studio',
    SEND_EMAIL_INVITE_WITH_HEALTH_DECLARATION: 'Send the email invitations with health declaration',
    SEND_EMAIL_INVITE_WITH_TERMS: 'Send the email invitations with the studio\'s terms',
    ALLOW_STUDENTS_PRIVATE_CHAT: 'Allow private chat messages',
    ALLOW_STUDENTS_SESSION_CHAT: 'Allow classes chatrooms',
    ALLOW_STUDENTS_GENERAL_CHAT: 'Allow all studio chatroom with all students',
    ALLOW_STUDENTS_STUDIO_TEACHERS_CHAT: 'Allow teachers to send chat messages in teachers chatroom',
    PUSH_SESSION_STARTING: 'Send push notification when session about to begin',
    PUSH_BIRTHDAY: 'Send student push notification with regards on their birthday',
    TOGETHER_EMAIL_INVITE: 'Get an email when I\'m invited to practice together',
    SESSION_EMAIL_JOINED: 'Get an email when someone joined my session/class',
    SESSION_EMAIL_LEFT: 'Get an email when someone left my session/class',
    SESSION_EMAIL_ENTERED_WAITING_LIST: 'Get an email when someone enters a session\'s waiting list',
    SESSION_EMAIL_JOIN_POLICY: 'Get an email when someone tried to signup to a session not within policy range',
    SESSION_EMAIL_LEAVE_POLICY: 'Get an email when someone tried to cancel registration no within policy range',
    SESSION_EMAIL_AUTO_PUNCH_FAILED: 'Get an email if there was no option to auto punch a frequent student in class',
    HIDE_BUSINESS_ACTIVITY: 'Hide students attendance from others in Medidate',
    HIDE_BUSINESS_ACTIVITY_TOOLTIP: 'Hide from students using the app who is signed up for class',
    HIDE_BUSINESS_ACTIVITY_LABEL: 'Hide Business activity',
    HIDE_SESSION_ATTENDERS_COUNT: 'Hide number of attendees in session from others in Medidate',
    HIDE_SESSION_ATTENDERS_COUNT_TOOLTIP: 'Hide from students using the app how many are signed up for class',
    HIDE_SESSION_ATTENDERS_COUNT_LABEL: 'Hide Attendees Count',
    ONCE_A_DAY_SESSION_ENTRY: 'Allow students to sign into class only once per day through the Medidate App',
    ONCE_A_DAY_SESSION_ENTRY_TOOLTIP: 'This will prevent students from signing into classes more then once a day through the app',
    OVERRIDE_PAYMENT: 'Allow students to sign into class freely via the Medidate App even without a valid plan to the studio',
    OVERRIDE_PAYMENT_TOOLTIP: 'This will enable students to sign into paid classes without a valid plan or a payment through the app',
    REQUIRES_HEALTH: 'Requires Health Statement to be filled to sign into class',
    REQUIRES_HEALTH_TOOLTIP: 'This will require the students to fill the health statment form inside the app while trying to sign into a class',
    REQUIRES_TERMS_AGREEMENT: 'Requires studio terms to be agreed to sign into class',
    REQUIRES_TERMS_AGREEMENT_TOOLTIP: 'This will require the students to agree to the studio\'s terms form inside the app while trying to sign into a class',
    SIGN_FUTURE_FREQUENT_SESSIONS: 'Frequent Students - Auto-Punch Memberships (not tickets) to future classes 5 weeks ahead',
    SIGN_FUTURE_FREQUENT_SESSIONS_TOOLTIP: 'The system will auto-punch 6 days before a class starts for frequent students (both tickets and subscriptions).\nIf you want the system to auto-punch 1 month ahead, please enable this option (relevant only for memberships and not tickets)',
    REFUND_MIN_HOURS: 'Student may cancel attendance freely until class is <b><font size="4">%{hours}</font></b> hours from starting',
    REFUND_POLICY_LABEL: 'Studio Cancelation/Registeration Policy & Terms',
    REFUND_POLICY_LABEL_TOOLTIP: 'Set the amount of hours before class that a student can unsign themselves to a class or sign in through the app',
    PAYMENTS_HISTORY_LABEL: 'Medidate Billing Invoices',
    PAYMENTS_HISTORY_LABEL_TOOLTIP: 'View the history and download invoices for your Medidate Subscription',
    MY_ACCOUNT_LABEL: 'My Medidate Account',
    MY_ACCOUNT_LABEL_TOOLTIP: 'Medidate Subscription details and history of previous subscriptions',
    SUBSCRIPTION_LABEL: 'Current Subscription',
    SUBSCRIPTION_LABEL_TOOLTIP: 'Current Subscription to Medidate and it\'s details',
    PAYMENTS_HISTORY_SUB_TITLE: 'Payments to Medidate',
    REMOVE_CONFIRM: 'Are you sure you want to remove %{name} from your studio?',
    USER_INVITE_MODAL_TITLE: "Invite new student to Medidate",
    USER_ADD_MODAL_TITLE: 'Add New Student to the Studio',
    LEAD_ADD_MODAL_TITLE: 'Add New Potential Client to the Studio',
    INVITE_USER_MODAL_BTN: "Send invitation",
    ADD_LEAD_MODAL_BTN: 'Add Potential Client',
    ADD_USER_MODAL_BTN: 'Add Student',
    ADD_USER_MODAL_ALT: 'To add a student which is not yet in your Studio',
    ADD_USER_MODAL_ALT_2: 'this will insert the newly added student to the %{product_type} at the end of the process',
    SESSION: 'session',
    PLAN: 'plan',
    PRODUCT: 'product',
    GENERAL_PREFERENCES: "General Preferences",
    RECEIPTS_INVOICES: "Accounting",
    RECEIPTS_INVOICES_ICOUNT: "iCount",
    RECEIPTS_INVOICES_GREEN: "Green Invoice",
    RECEIPTS_INVOICES_CONNECT: "Connect my %{accounting} Account",
    RECEIPTS_INVOICES_CONNECTED: "%{accounting} Account is Connected - %{accountingUserId}",
    RECEIPTS_INVOICES_SYNC_STUDENTS: "Sync my Medidate Students with my %{accounting}",
    RECEIPTS_INVOICES_SYNC_STUDENTS_TIP: "Export your students from Medidate into your %{accounting} account and create new clients (if needed) according to your Medidate students and their details",
    RECEIPTS_INVOICES_SYNC_LASTS: "Last sync of Students with %{accounting} - %{syncDate}",
    RECEIPTS_INVOICES_SYNC_STUDENTS_COMPLETE: "Synced Medidate Students with %{accounting} successfully",
    RECEIPTS_INVOICES_CONNECT_CONFIRM: "Connect to %{accounting}",
    RECEIPTS_INVOICES_SYNC_STUDENTS_CONFIRM: "Sync Students",
    RECEIPTS_INVOICES: "Create Receipt or Invoice",
    RECEIPTS_INVOICES_NEW_RECEIPT: "Send Receipt",
    RECEIPTS_INVOICES_OPEN_RECEIPT: "Open Receipt or Invoice",
    RECEIPTS_INVOICES_NEW_INVOICE: "Send Invoice",
    RECEIPTS_INVOICES_NEW_RECEIPT_PREVIEW: "Send Receipt to %{student}",
    RECEIPTS_INVOICES_NEW_INVOICE_PREVIEW: "Send Invoice to %{student}",
    RECEIPTS_INVOICES_USERNAME: "Username",
    RECEIPTS_INVOICES_COMPANY_ID: "Company identifier",
    RECEIPTS_INVOICES_PASSWORD: "Password",
    RECEIPTS_INVOICES_ADDITIONAL: "Additional Info Required before Sending:",
    RECEIPTS_INVOICES_ADDITIONAL_BANK: "Check Bank Code",
    RECEIPTS_INVOICES_ADDITIONAL_BRANCH: "Check Bank Branch",
    RECEIPTS_INVOICES_ADDITIONAL_ACCOUNT: "Check Bank Account",
    RECEIPTS_INVOICES_NEW_RECEIPT_CREATED: "Receipt was Created and Sent to %{student}!",
    RECEIPTS_INVOICES_NEW_INVOICE_CREATED: "Invoice was Created and Sent to %{student}!",
    VAT_EXEMPT_MESSAGE: "Are you a VAT exmpet dealer?",
    ACCOUNTING_LIMIT_REACHED_TITLE:'Unable to create a document for a price over 20,000₪',
    ACCOUNTING_LIMIT_REACHED_MESSAGE:'Please go the Grow dashboard and create the document there',
    SHEET_DROPZONE_BOTTON: "Import Students",
    SHEET_DROPZONE_TITLE: "Drag Students Import File Here (XLSX,CSV Only)",
    SHEET_DROPZONE_LOADING: "Importing Students to Medidate...",
    SHEET_DROPZONE_COMPLETE: "%{students} Students Imported Successfully!",
    SHEET_DROPZONE_ERROR: "Import Fail, Wrong Formatting or 0 data in document..",
    SHEET_DROPZONE_EXAMPLE: "Download an Example Import Excel file",
    POLICY_REFUND_MESSAGE_PART_1: '<b>Allow cancelation until</b>',
    POLICY_REFUND_MESSAGE_PART_2: '<b>hours before class begins in application (value 0 = Cancel Freely)</b>',
    POLICY_REGISTER_MESSAGE_PART_1: '<b>START signup</b>',
    POLICY_REGISTER_MESSAGE_PART_2: '<b>hours before class/session/workshop begins in application (value 0 = Register Freely)</b>',
    POLICY_END_REGISTER_MESSAGE_PART_1: '<b>END signup</b>',
    POLICY_END_REGISTER_MESSAGE_PART_2: '<b>hours before class/session/workshop begins in application (value 0 = Register Freely)</b>',
    REGISTRATION_LINK: 'Signup Link for Students:',
    REGISTRATION_LINK_TOOLTIP: 'This link allows student to sign into your studio themselves.<br/><br/>The link will open a Signup form for the student, immediately after they will receive an email prompting to download the Medidate App with personal credentials where they can  start interacting with the studio.<br/><br/>The link can be copied and sent through any means you find fit: Message, Whatsapp, Facebook or as a button on your personal website.',
    ERROR_SAVING: 'Error while saving changes',
    COPY_PAYMENT_LINK: 'Copy Payment Link',
    MEDIDATE_SUBSCRIPTION_CREATED: 'New Medidate subscription created successfully',
    INVITE_STUDENT_TO_THE_APP: 'Invite student to the Medidate app',
    DONT_ALLOW_CANCELATION_POLICY_RANGE: 'Don\'t allow students to leave class attendance when they are within cancelation policy range even though they don\'t get a refund when doing so',
    DONT_ALLOW_CANCELATION_POLICY_RANGE_TOOLTIP: 'This option is in case you do not want to allow students the opportunity to singout of a class within policy range even though when they signout they do not get their payment or entry back. ** This option isn\'t ideal because it\'s usually a good idea to let them leave to make room for other students who can make it.',
    ALLOW_TEACHER_EDIT: 'Allow teachers to modify class attendance list in classes they teach',
    ALLOW_TEACHER_EDIT_TOOLTIP: 'Enable teachers to add or remove students in classes they are assigned to through the app',
    ALLOW_TEACHER_SENSITIVE_INFO: 'Give teachers access to students info',
    ALLOW_TEACHER_SENSITIVE_INFO_TOOLTIP: 'Allow your teachers to view sensitive students information like health condition and declaration.',
    CHOOSE_BUSINESS_COUNTRY: 'Please choose the country where your business operates',
    CANCELLED_MEMBERSHIP_SUCCESS: 'Successfully Cancelled future payments for this plan',
    LEARN_MORE: 'Learn More',
    B2C_COMMISSION_SPLIT_TITLE: 'Medidate Transaction Fee Distribution',
    B2C_COMMISSION_SPLIT_MESSAGE: 'Medidate requests a 1% fee, which is usually taken from end customers (your clients) and, from experience, this fee is mostly unnoticeable. If you wish to change this distribution, adjust it as you see fit.',
    B2C_COMMISSION_SPLIT_DESC: 'According to your desision Medidate will add the percent to the final price at client checkout.\nSo if a product costs 100, and you\'ve deceided, for example, that the client percent is 0.75% and yours is 0.25%, the price of the product at checkout will be a total of 100.75.',
    MY_BUSINESS: 'My Business',
    MY_CLIENTS: 'My Students',
    CONNECT_GROW_USER_TO_MEDIDATE_TITLE: 'Connect Existing Grow user to Medidate by entering the user\'s Medidate objectId and the user\'s Grow userId',
    CONNECT_GROW_USER_TO_MEDIDATE_USER_TITLE: '1. Contact Grow and ask to be in Medidate\'s market place.\n2. You will be provided with a User ID.\n3. Copy the User ID.\n4. Paste in the field below',
    SNOOZE_STUDENT_TITLE: 'Snooze Students which didnt interact with the studio for a defined period in weeks',
    SNOOZE_STUDENT_SUBTITLE: 'Send an alert to students who didnt interact with the studio a defined period in weeks.',
    SNOOZE_STUDENT_MESSAGE_PART_1: '<b>Send an Alert to students after</b>',
    SNOOZE_STUDENT_MESSAGE_PART_2: '<b>Weeks </b>',
    SNOOZE_STUDENT_MESSAGE_PART_3: '<b>if student was absent from classes for the defined period of time (and is not signed up for future classes)</b>',
    NO_INTERACTION_MESSAGE_TITLE: 'Title of Message',
    NO_INTERACTION_MESSAGE_TITLE_PREFIX: 'Hey @studnet:',
    NO_INTERACTION_MESSAGE_TITLE_PLACEHOLDER: 'Hey @studnet, we miss you!',
    NO_INTERACTION_MESSAGE_TITLE_ACTUAL: 'Actual Title will be:',
    NO_INTERACTION_MESSAGE_DESCRIPTION: 'Description in Message',
    NO_INTERACTION_MESSAGE_DESCRIPTION_PLACEHOLDER: 'We noticed you haven\'t been to our classes for a couple of weeks.\nOur studio is waiting for you!\nYours @studio',
    NO_INTERACTION_MESSAGE_DESCRIPTION_ACTUAL: 'Actual Message will be:',
    NO_INTERACTION_MESSAGE_TAGS: 'Add tags to the message to be included in the message',
    NO_INTERACTION_MESSAGE_TAGS_TIP: 'You can add tags to the message to be included in the message as the actual values. For example: @student, @studio',
    NO_INTERACTION_MESSAGE_TAGS_PLACEHOLDER: 'Add tags to the message to be included in the message',
  },
  whatsapp: {
    CONNECTED_NOTIFICATIONS_WHATSAPP: 'WhatsApp Notifications ON',
    DISCONNECTED_NOTIFICATIONS_WHATSAPP: 'WhatsApp Notifications OFF',
    SCAN_QR: "Scan QR Code with your WhatsApp application",
    HOW_SCAN_QR: "How to scan the code (explanation)",
    CONNECTED_WHATSAPP: "Connected to WhatsApp Successfully..",
    DISCONNECTED_WHATSAPP: "Disonnected from WhatsApp Successfully..",
    CONNECT_WHATSAPP: 'Connect to WhatsApp',
    DISCONNECT_WHATSAPP: 'Disconnect from WhatsApp',
    CONNECT_WHATSAPP_TOOLTIP: 'Connect to your WhatsApp account to use more automations and better communication with your students',
    DISCONNECT_WHATSAPP_TOOLTIP: 'Discoonnect from your WhatsApp account to stop sending automated notifications to the whatsapp of your students',
    WA_SESSION_EMAIL_INVITE: 'Get a whatsapp when I\'m invited to a session/class',
    WA_PLAN_EMAIL_ENDING: 'Have students receive whatsapp notification when their plan is about to end',
    WA_PLAN_EMAIL_RENEWING_STUDIO: 'Receive whatsapp notification when Student\'s plan is about to renew',
    WA_PLAN_EMAIL_RENEWED_STUDIO: 'Receive whatsapp notification when Student\'s plan renewed',
    WA_PLAN_EMAIL_RENEWING: 'Have students receive whatsapp notification when their plan is about to renew',
    WA_PLAN_EMAIL_RENEWED: 'Have students receive whatsapp notification when their plan renewed',
    WA_SEND_EMAIL_INVITES: 'Send whatsapp invitations to the Medidate app when adding students to the studio',
    WA_SEND_EMAIL_INVITE_WITH_HEALTH_DECLARATION: 'Send the whatsapp invitations with health declaration',
    WA_SEND_EMAIL_INVITE_WITH_TERMS: 'Send the whatsapp invitations with the studio\'s terms',
    WA_PUSH_SESSION_STARTING: 'Send whatsapp notification when session about to begin',
    WA_PUSH_BIRTHDAY: 'Send whatsapp notification with regards on students birthday',
    WA_TOGETHER_EMAIL_INVITE: 'Get a whatsapp when I\'m invited to practice together',
    WA_SESSION_EMAIL_JOINED: 'Get a whatsapp when someone joined my session/class',
    WA_SESSION_EMAIL_LEFT: 'Get a whatsapp when someone left my session/class',
    WA_SESSION_EMAIL_ENTERED_WAITING_LIST: 'Get a whatsapp when someone enters a session\'s waiting list',
    WA_SESSION_EMAIL_JOIN_POLICY: 'Get a whatsapp when someone tried to signup to a session not within policy range',
    WA_SESSION_EMAIL_LEAVE_POLICY: 'Get a whatsapp when someone tried to cancel registration no within policy range',
    WA_SESSION_EMAIL_AUTO_PUNCH_FAILED: 'Get a whatsapp if there was no option to auto punch a frequent student in class',

  },
  userPage: {
    TITLE: 'User Info',
    USER_PAGE_TITLE: '%{name}\'s Page',
    ATTENDED_SESSIONS: 'Attended Sessions',
    SESSIONS_ATTENDED: '%{name} attended <b>(%{sessionsCount})</b> sessions during the selected date range',
    SESSIONS_UNREFUNDED: '%{name} left <b>(%{sessionsCount})</b> sessions while in cancelation policy range inside selected date range',
    SESSIONS_TAUGHT: '%{name} taught <b>(%{sessionsCount})</b> sessions during the selected date range in your studio',
    HEALTH_CONDITION: 'Health Condition',
    HEALTH_STATEMENT: 'Health Statement',
    RESEND_INVITATION_CONFIRM: 'This will invite student to Medidate through email. proceed?',
    RESEND_TERMS_CONFIRM: 'This will resend the Studio Terms to this student through email. proceed?',
    ATTENDING_SESSIONS_CARD_TITLE: 'Sessions %{name} has attended',
    UNREFUNDED_SESSIONS_CARD_TITLE: 'Sessions which %{name} cancelled late',
    TAUGHT_SESSIONS_CARD_TITLE: 'Taught Sessions by %{name} in my Studio',
    STUDENT_PLANS_CARD_TITLE: '%{name}\'s Plans (Tickets & Memberships)',
    SAVE_STARTING_DATE_FOR_STUDENT: 'Save starting date for this specific student',
    SAVE_STARTING_DATE_FOR_STUDENT_TOOLTIP: 'Every time you open this student\'s info page this starting date will used'
  },
  affiliation: {
    AFFILIATE_LINK_TITLE: 'Medidate Affilates Program',
    AFFILIATE_LINK: 'Invite your colleagues and other business owners to use Medidate and get rewarded on each new subscriber',
    AFFILIATE_LINK_TOOLTIP: 'Invite your fellow teachers/studio owners to use Medidate using your signup affiliate link.<br/><br/>For each studio or teacher that starts a Medidate subscription through your affiliation link, you will receive <b>200₪</b> (for a monthly Medidate Membership) or <b>300₪</b> (for a yearly Medidate Membership) and <b>the new subscriber</b> that just signedup through your link gets <b>extra 14 days</b> free of charge to his 30 days trial already granted.',
    AFFILIATE_POPUP: 'Invite your colleagues and other business owners to use Medidate and for each business that starts a Medidate subscription you will receive <b>₪200-₪300</b> in cash and <b>the new subscriber</b> that just signedup through your link gets <b>extra 14 days</b> free of charge to his 30 days trial already granted! (You can locate your affiliate link later in your Profile)',
    AFFILIATE_LINK_LONG: 'You will receive<br/><br/> <b>₪200-₪300</b> in cash and <b><br/><br/>the new subscriber</b> that just signedup through your link gets <b>extra 14 days</b> free of charge to his 30 days trial already granted.<br/><br/>The link can be copied and sent through any means you find fit: Message, Whatsapp, Facebook or as a button on your personal website.',
    AFFILIATE_TOTAL_FINISHED: 'Total Affiliations Completed',
    AFFILIATE_TOTAL_FINISHED_TIP: 'Affiliation links that were used and the user who used them is already subscribed to Medidate',
    AFFILIATE_TOTAL_PENDING: 'Total Affiliations Pending',
    AFFILIATE_TOTAL_PENDING_TIP: 'Affiliation links that were used but the user who used them didnt subscribe to Medidate yet',
    AFFILIATE_TOTAL: 'Total Affiliations',
    AFFILIATE_SUMMARY_TITLE: 'Affiliations Summary',
    AFFILIATE_VERIFY: 'Confirm Affiliation Terms and Conditions',
    AFFILIATE_WARNING: 'Attention: We can not transfer your earnings until you approve our Affiliation Program Terms and Conditions',
    AFFILIATE_WARNING_SUBTITLE: '*Your affiliations will still be collected and waiting for you to approve the terms',
    AFFILIATE_BANK_TITLE: "Send my Profites to:",
    AFFILIATE_USER_REGISTERED: "I Already have a Medidate Account, Login",
    AFFILIATE_USER_REGISTERING_FROM_INVITE_NOTE: "*No CC needed, You get 30 Days for FREE + 14 Days Extra thanks to %{studio_name}",
    AFFILIATE_USER_REGISTERING_FROM_INVITE_NO_REWARD_NOTE: "*No CC needed, You get 30 Days for FREE",
    AFFILIATE_STEPS_TITLE: "Here's how it works:",
    AFFILIATE_STEPS_1: "Sign up for our affiliate program.",
    AFFILIATE_STEPS_2: "Get a unique referral link to share.",
    AFFILIATE_STEPS_3: "Invite others to sign up using your link.",
    AFFILIATE_STEPS_4: "Earn rewards once the referred person subscribes to our paid plans.",
    AFFILIATE_STEPS_TERMS_SHORT: "The reward amount depends on the subscription plan:",
    AFFILIATE_STEPS_FOOTER_1: "If the referred user signs up for a monthly plan, you'll receive a standard reward.",
    AFFILIATE_STEPS_FOOTER_2: "For yearly subscriptions, the reward is bigger, offering you more benefits.",
  },
  session: {
    SESSION_EDIT_TITLE: 'Edit %{title}',
    SESSION_ADD_TITLE: 'Add Session',
    ADD_NEW_BUTTON: 'Add New Session',
    CHECK_UNPAID_SESSIONS: 'Mark Unsetteled Sessions',
    CHECK_UNPAID_SESSIONS_TOOLTIP: 'This option adds a red flag icon on sessions who are not fully setteled yet. Meaning classes with one or more attendees with the the word "No" under "Paid" in the attendees list',
    MARK_UNREFUNDED_SESSIONS: 'Mark Sessions with late cancelation',
    MARK_UNREFUNDED_SESSIONS_TOOLTIP: 'This option adds a red flag icon on sessions that have students that left class late (out of policy)',
    LABEL_TITLE: 'Session Title',
    LABEL_TITLE_TOOLTIP: 'The title or name of the Class/Session/Workshop/Retreat that best describes the nature of this event. Example: \“Ashtanga Yoga Class for Beginners\"',
    LABEL_DESCRIPTION: 'Session Description',
    LABEL_DESCRIPTION_TOOLTIP: 'Add more detailed information regarding this class/session/workshop',
    LABEL_ADDRESS: 'Session Address',
    LABEL_ADDRESS_TOOLTIP: 'Enter this session’s address. Please write the address and then choose from the dropdown list of addresses so the marker on the map will show the exact location of the address',
    LABEL_DATE: 'Session Date',
    LABEL_DATE_TOOLTIP: 'The date that this class will take place',
    LABEL_TIME: 'Begins At',
    LABEL_TIME_TOOLTIP: 'The time that this class begins. If you want to students to know the duration of the class simply add that information in the description of the class',
    LABEL_END_TIME: 'Ends At (optional)',
    LABEL_END_TIME_TOOLTIP: 'The date that this class ends.',
    LABEL_END_TIME_PLACEHOLDER: 'Ends At?',
    LABEL_REPETITION: 'Session Repetition',
    LABEL_REPETITION_TOOLTIP: 'Define the repetition of this class, if it’s one time/daily/weekly/monthly and this class will be in your calendar 4 weeks ahead. It will take approximately 20min until all classes show in the future calendar',
    LABEL_OCCURANCE_DAYS: 'Daily Occurance',
    HOUR: '%{number} Hour',
    HOURS: '%{number} Hours',
    WEEK: '%{number} Week',
    WEEKS: '%{number} Weeks',
    MONTH: '%{number} Month',
    MONTHS: '%{number} Months',
    ADD_PICTURE: 'Add Session Picture',
    LABEL_TEACHER: 'Session Teacher',
    SAVE_BUTTON: 'Save Session',
    IN_WAITING: 'On Waiting List',
    EXIT_WAITING: 'Leave Waiting List',
    SEND_INFO_TO_TEACHER: 'Send Info to Teacher',
    SEND_INFO_TO_TEACHER_TOOLTIP: 'Sends an email to the teacher with all the info about the attendees of this class',
    LABEL_SESSION_TYPE: 'Session Type',
    LABEL_SESSION_TYPE_TOOLTIP: 'The type of class that is being taught in this session: Yoga, Meditation, Tai Chi, Pilates and so on',
    LABEL_STYLE: 'Styles',
    LABEL_STYLE_TOOLTIP: 'The style of class such as: Ashtanga, Vinyasa, Women and so on',
    LABEL_SESSION_COLOR: 'Session Color',
    LABEL_LIMIT: 'Attendees Limit',
    LABEL_LIMIT_TOOLTIP: 'If the studio has a limit to the capacity of practicing students enter the limit value here. For students who are signing up through the app, if this limit is reached they will be prompt to enter the waiting list. If a spot is available for class the students in the waiting list will be informed and they can fill that spot',
    LABEL_TRIAL_PRICING: 'Trial Pricing',
    LABEL_TRIAL_PRICING_TOOLTIP: 'The pricing of this class/session that will appear in the class details inside the app for students which are eligible for a Trial Session (0 means FREE Entrance).',
    LABEL_PRICING: 'Pricing',
    LABEL_PRICING_TOOLTIP: 'The pricing of this class/session/workshop/retreat that will appear in the class details inside the app.<br/><br/>Free: if the class is free<br/><br/>Price: The max price is the full price to pay for a dropin',
    LABEL_PAID: 'Marked as Paid',
    LABEL_UNPAID: 'Marked as Unpaid',
    LABEL_PAID_ADDITIONAL: 'Additional payment info',
    LABEL_PARTIAL_PAID_AMOUNT: 'Amount Paid',
    LABEL_OPTIONAL_PAID_AMOUNT: 'Amount Paid (Optional)',
    LABEL_CHECKS_NUMBER: 'Number of Checks',
    LABEL_SINGLE_CHECK_NUMBER: 'Check Number',
    LABEL_SINGLE_CHECK_DATE: 'Date',
    LABEL_ATTENDING_STUDENTS: 'Attending Students <b>(%{count})</b>',
    LABEL_ATTENDING_STUDENTS_TOOLTIP: 'Students that are signed up for this class/session/workshop.<br/><br/><b>Note</b> - A note about the student and this specific class. Example: He strained himself this class.<br/><br/><b>Paid</b> - Did the student pay for this class. Dropin, or entered with a Ticket or Membership.<br/><br/><b>Frequent</b> - Marks the student as a frequent student to this class and all future instances of this class thus automatically signing this student to all future instances of this class',
    LABEL_UNREFUNDED_STUDENTS: 'Non-Refunded Attendees <b>(%{unrefunded_count})</b>',
    LABEL_UNREFUNDED_STUDENTS_NO_COUNT: 'Non-Refunded Attendees',
    LABEL_UNREFUNDED_STUDENTS_TOOLTIP: 'Students that left this class/session/workshop.<br/><br/><b>Without getting a refund</b> because he or she are within the studio\'s cancelation policy range',
    LABEL_WAITING_STUDENTS: 'Students in Waiting List <b>(%{waiting_count})</b>',
    LABEL_STATUS_STUDENTS: 'Attendees Status <b>(%{student_status_count})</b>',
    LABEL_STATUS_STUDENTS_NO_COUNT: 'Attendees Status',
    LABEL_STATUS_STUDENTS_TOOLTIP: 'Students that had any interaction with this class/session/workshop.<br/><br/><b>If it\'s Leaving With or Without getting a refund or arriving as expected</b><br/><br/>All statuses are filled according to the student situation and can be changed manually by the teacher/studio owner.<br/><br/>You can NOT add or remove students from this table, only change it\'s status for logging and statistics',
    LABEL_PRICE: 'Price',
    LABEL_MAX_PRICE: 'Max Price',
    LABEL_MIN_PRICE: 'Min Price',
    LABEL_ALLOW_DROPINS: 'Allow Dropin Payments',
    LABEL_ALLOW_DROPINS_TOOLTIP: 'Allow students to purchase their spot in class through the app',
    LABEL_NEEDS_TEACHER_APPROVAL: 'New Students Need Approval',
    LABEL_NEEDS_TEACHER_APPROVAL_TOOLTIP: 'New students has to contact the studio before buying Dropin',
    LABEL_PRIVATE_SESSION: 'This is a Private Session',
    LABEL_PRIVATE_SESSION_TOOLTIP: 'Set session as private. Meaning, the students inside the app won’t be exposed to this class in the calendar of the app. Only signed up students to this class will see the class in their calendar inside the Medidate app',
    LABEL_MINIMUM_REQ: 'Set Minimum Requirements for this class to take place',
    LABEL_MINIMUM_REQ_TOOLTIP: 'Set this class with minimal requirements for it to take place. Example: Medidate will notify the teacher 3 hours before class if there are at least 3 signed up students. If not, it is the Teacher\’s/Studios\’s final decision whether or not to cancel the class which is achievable inside the class/session. Cancelation will send a notification to the student\’s app about the class being canceled. *Medidate will not auto-cancel this session',
    LABEL_MINIMUM_REQ_MESSAGE_PART_1: '<b>Notify me</b>',
    LABEL_MINIMUM_REQ_MESSAGE_PART_2: '<b>hours before class if there are less than</b>',
    LABEL_MINIMUM_REQ_MESSAGE_PART_3: '<b>students signed up for this class</b>',
    LABEL_MINIMUM_REQ_HOURS: 'Minimum hours before session %{count}',
    LABEL_ZOOM_SESSION: 'Online Class',
    LABEL_ZOOM_SESSION_TOOLTIP: 'Set this class as an Online class (for example Zoom) and paste the link to the class in the textbox. Students that are signed up to class will get a notification 15 minutes before class begins with the link to the class. Also, students will see the link inside the session page in the app as well.',
    LABEL_ZOOM_SESSION_LINK: '<b>Enter the link (Zoom link) to your online session</b>',
    LABEL_ZOOM_SESSION_LINK_PLACEHOLDER: 'https://zoom.us/j/765XXXXXX',
    LABEL_ZOOM_SESSION_LINK_PASSWORD: '<b>Password (if exists)</b>',
    LABEL_ZOOM_SESSION_LINK_ALL: 'Same Link for all online sessions',
    LABEL_ZOOM_SESSION_LINK_ALL_TOOLTIP: 'This will copy the link to future instances of this online class',
    LABEL_MINIMUM_REQ_ATTENDERS: 'Minimum attendees in session %{count}',
    LABEL_POLICY: 'Set Cancelation and Registration Policy for this class',
    LABEL_POLICY_TOOLTIP: 'Set a cancelation and registration policy for this specific class',
    LABEL_TRIAL_SESSION: 'Allow Trial Entrance',
    LABEL_TRIAL_SESSION_TOOLTIP: 'Allow new students which are eligible for a Trial Session to use it while entering this class',
    LABEL_PHONE_NUMBER: 'Phone Number',
    PLACEHOLDER_FILTER_SESSIONS: "Filter sessions",
    FILTER_UNPAID: "Only Show Unpaid Students",
    FILTER_PAID_WITHOUT_RECEIPT: "Only Show Paid without a sent Receipt or Invoice",
    PAYMENT_OPTIONS_TITLE1: "Student Needs to Pay?",
    PAYMENT_OPTIONS_TITLE2: "Student Already Paid?",
    PAYMENT_CASH_BODY: "Send a payment request for this product. Your student will receive an email with the payment details and an option to pay with credit card.",
    PAYMENT_REQUEST_PRODUCT_PRICE: "Price <b>%{price}%{currency}</b>",
    PAYMENT_REQUEST_TEACHER_TOTAL: "Studio Receives <b>%{teacherPrice}%{currency}</b>",
    PAYMENT_REQUEST_STUDENT_TOTAL: "Student'\s total price including Medidate Fee <b>%{studentPrice}%{currency}</b>",

    PAYMENT_REQUEST_CTA: "Send students a payment request for %{price}%{currency}",
    PAYMENT_CASH_CTA: "Mark student as paid in cash %{price}%{currency}",
    LABEL_TRIAL: 'Mark <b>%{productName}</b> as a Trial Lesson for <b>%{studentName}</b>:',
    LABEL_PARTIAL_PAID_HOW: 'Mark <b>%{productName}</b> as Partially Paid by <b>%{studentName}</b> Using:',
    LABEL_PAID_HOW: 'Mark <b>%{productName}</b> as Paid by <b>%{studentName}</b> Using:',
    LABEL_NOT_PAID: 'Send <b>%{studentName}</b> a Payment Request for <b>%{productName}</b>:',
    PAID_MODAL_TITLE: '%{name} Paid Status',

    PAYMENT_PAID_ALREADY: "<b>This student has already paid</b> <span style=color:purple;>%{price}%{currency}</span> <b>using</b> <span style=color:purple;>%{paymenMethod}</span>",
    PAYMENT_CASH_CANCEL_BODY: 'Mark student as unpaid?',
    PAYMENT_TICKET_BODY: 'Punch student’s ticket for this session with <b>%{planName}</b>?',
    PAYMENT_TICKET_CANCEL_BODY: 'Unpunch student’s ticket for this session - <b>%{planName}</b>?',
    PAYMENT_MEMBERSHIP_BODY: 'Sign student’s membership for this session with - <b>%{planName}</b>?',
    PAYMENT_MEMBERSHIP_CANCEL_BODY: 'Unpunch student’s membership for this session - <b>%{planName}</b>?',
    PUNCH: 'Punch',
    UNPUNCH: 'Unpunch',
    SIGN: 'Sign',
    UNSIGN: 'Unsign',
    PAY: 'Pay',
    JOIN_SIMPLE: 'Join',
    SIGNIN_SIMPLE: 'Enter',
    JOIN: 'Join Session',
    JOIN_DISCOUNT: 'Join Session (Discounted)',
    LEAVE: 'Leave Session',
    ATTENDERS: '%{number} Attendees',
    PENDING_PAYMENTS_TOOLTIP: 'Pending Payments',
    ATTENDERS_TOOLTIP: '%{count} attendees',
    WAITING_TOOLTIP: '%{count} wait',
    FUKYOU: '%{count} wai',
    JOINED: 'Joined Session successfully!',
    SESSION_CANCEL_CONFIRM: 'Sure you want to cancel this session?\n1. Please make sure that the paid status for all students in this session are set to "NO".\n2. Students in class will be notified about the canceleation if whatsapp updates are On.\n3. If you\'re canceling all future session please check the paid status for all future session as well.',
    CANCEL_SESSION_BUTTON: 'Cancel Session',
    NO_SESSIONS: "List is empty in this date range",
    NO_SESSIONS_DISABLED: "You didn't join any session yet",
    SESSION_DATE_OTHER_TZ: '* Different Time Zone',
    ASSOCIATED_PLANS_TITLE: 'Valid Tickets and Memberships',
    ASSOCIATED_PLANS_TITLE_TOOLTIP: 'Set the Tickets and Memberships that allow access to this class/session/workshop. If a Ticket or Membership is ON (green switch) for this class students will be able to sign in to class through the app using turned on Tickets or Memberships. Also when setting the student’s “paid” status for class, if the student has the relevant (turned on ticket) you can punch their ticket in the students table below',
    ASSOCIATED_TICKETS: 'Tickets',
    ASSOCIATED_MEMBERSHIPS: 'Memeberships',
    SAVE_SESSION_CONFIRM: 'This Session has future instances, which sessions would you like to update?',
    THIS_SESSION: 'Only This Session',
    ALL_FUTURE_SESSIONS: 'This Session and Future Sessions',
    DELETE_SESSION_CONFIRM: 'This Session has future instances, which sessions would you like to delete?',
    REACTIVATE_SESSION: 'Reactivate Session',
    SHOW_WAITING_LIST: 'Show Waiting List',
    PRIVACY_FOR_FUTURE_SESSIONS: 'Medidate will update the privacy of future instances of this class, which sessions would you like to update?',
    DUPLICATE_EXISTING_SESSION: 'Duplicate Session',
    CONFIRM_DUPLICATE_SESSION: 'Would you like to create a new session from this existing session?',
    DUPLICATED_SESSION_TITLE: 'Session was duplicated from ',
    ALERT_RELATED_PLANS: 'If you need to adjust \"Valid Tickets and Memberships\" for this duplicated session, after session is saved enter this session once again and update accordingly',
    CALENDAR_LINK: 'Studio\'s Schedule Link (Embed your Schedule in your personal website, private sessions will not appear here):',
    CALENDAR_LINK_TOOLTIP: 'This link allows student to view the schedule your studio themselves.<br/><br/>The link will open a Signup form for the student, immediately after they will receive an email prompting to download the Medidate App with personal credentials where they can start interacting with the studio.<br/><br/>The link can be copied and sent through any means you find fit: Message, Whatsapp, Facebook or as a button on your personal website.',
    EXTERNAL_PAYMENT_LINK: 'External Payment Link',
    EXTERNAL_SESSION_LINK: 'External Session Link',
    EXTERNAL_PAYMENT_LINK_TOOLTIP: 'Use this link to allow potential and existing students to signup and pay without the Medidate Client App.<br/><br/>The link can be copied and sent through any means you find fit: Message, Whatsapp, Facebook or as a button on your personal website.',
    EXTERNAL_ENTER_CLASSES: 'Allow Students to Join Classes',
    EXTERNAL_ENTER_CLASSES_TOOLTIP: 'Allow your stdents to join the classes on the using the external Calendar.',
    NOT_IN_CLASS: 'This student not in this class anymore',
    END_AFTER_START_TIME: 'End time must be after session\'s start time',
    WEEKLY_RECURRING_MESSAGE: 'Reminder: Weekly recurring sessions could take around 20 minutes until all future instances (4 weeks ahead) appear from the moment it is saved',
    CHANGE_START_DATE_WARNING: 'Session end time is before start time, do you wish us to automaticaly edit the start date as well?',
    CHANGE_END_DATE_WARNING: 'Session start time is after end time, do you wish us to automaticaly edit the end date as well?',
    ENTERED_WITH_PLAN: 'Added with valid Ticket or Membership',
    SEND_ALERT_SESSION_CHANGED: 'Send Alert to the students signed into this class about this change',
    SEND_ALERT_SESSION_CHANGED_TOOLTIP: 'Use this to send an alert to the student\'s signed into this class. Please be aware it will only alert the student\'s of this specific class and not future instances',
    SEND_EMAIL_WITH_INVOICE: 'Send document to receipient',
    SEND_EMAIL_WITH_INVOICE_TOOLTIP: 'Send a copy of this document directly to the recipient\'s email address. They will receive it immediately after the document is created.',
    FREE_SESSION_PLANS_NOTICE: 'You cannot select tickets and memberships for a free session'
  },
  paidModal: {
    PAID_MODAL_TITLE: 'How did %{name} Pay?',
    SESSION_PAID_TITLE: 'Payment for Dropin',
    SESSION_ENTER_WITH_PLAN_TITLE: 'Sign student into class with the following plan',
    SESSION_ENTERED_WITH_PLAN_TITLE: 'Student signed into class with the following plan serial number',
    PLAN_PAID_TITLE: 'Payment for Ticket or Membership',
    TICKET_PAID_TITLE: 'Payment for Ticket',
    MEMBERSHIP_PAID_TITLE: 'Payment for Membership',
    MEMBERSHIP_ONE_TIME_PAID_TITLE: 'One Time full payment for the whole membership period',
    MEMBERSHIP_MONTHLY_PAID_TITLE: 'Payment for Membership for month number %{index}',
    MEMBERSHIP_AUTO_MONTHLY_PAID_TITLE: 'Auto Payment for a month',
    MARK_FULLY_PAID: "<b>Fully Paid</b>",
    MARK_FULLY_PAID_TOOLTIP: "In case the student paid in full for this product",
    MARK_PARTIALLY_PAID: "<b>Partially Paid</b>",
    MARK_PARTIALLY_PAID_TOOLTIP: "In case the student partially paid for this product. <b>When the rest of the payment arrives please update this to Fully Paid</b>",
    MARK_TRIAL_SESSION: "<b>Trial Session</b>",
    MARK_TRIAL_SESSION_TOOLTIP: "Mark this class as a Trial Class for this student",
    SEND_PAYMENT_REQUEST_BOLD: "<b>Send a Payment Request</b>",
    SEND_PAYMENT_REQUEST: "Send a Payment Request",
    GENERAL_STUDENT_NOTE: "General Note",
    REFUND_CREDIT_CARD_PAYMENT: "Would you like to refund the credit card payment?",
    PAYMENT_REQUEST_SENT: "Payment Request sent successfuly.<br/><br/>Here is the Link: %{link}",
    PAYMENT_REQUEST_URL: "Payment Link: %{link}",
    PAYMENT_REQUEST_URL_TOOLTIP: "You can copy and send this link to your student",
    COPIED_LINK: 'Copied link',
    PARTIAL_AMOUNT_RECEIVED: 'Partial Amount Received So Far',
    PAYMENT_RECEIVED_USING: 'Payment Received Using',
    SAVE_CC: 'Save Credit Card for future usage.',
    SELECT_HOW_STUDENT_PAID: 'Please select how the student paid',
    PAYMENT_STATUS_CHANGED: 'User payment Status has changed, please try again',
    CHECK_ERROR: 'Please enter Checks details',
    REFUNDED: 'Payment Refunded',
    PUNCHED_FOR_STUDENT: 'Plan punched for student',
    UNPUNCHED_FOR_STUDENT: 'Plan unpunched for student',
    MEMBERSHIP_MONTHLY_EXCEEDED: 'Membership monthly entery exceeded, cant sign in user with this membership',
    MEMBERSHIP_WEEKLY_EXCEEDED: 'Membership weekly entery exceeded, cant sign in user with this membership',
    MEMBERSHIP_MONTHLY_FREQUENT_EXCEEDED: 'Cannot punch this membership because the student is signed up for fixed classes in the current month, please add another membership or remove the student from the fixed classes',
    MEMBERSHIP_WEEKLY_FREQUENT_EXCEEDED: 'Cannot punch this membership because the student is signed up for fixed classes in the current week, please add another membership or remove the student from the fixed classes',
    UNKNOWN_ERROR: 'Unknown Error',
    OPEN_LINK_IN_NEW_WINDOW: 'Open link in new window',
    SAVE: 'Save',
    PHONE_NUMBER_NEEDS_FIXING: 'The Student\'s phone number does not exist or not valid.\nPlease insert the Student\'s phone number and try again.'
  },
  paidModalMembership: {
    PAID_MODAL_TITLE: 'How will this Membership be paid off?',
    ONE_TIME_FULL_PAYMENT: 'One time full payment for the whole membership period',
    MANUALLY_PAYMENTS: 'Monthly payments manually handled by the studio',
    AUTO_PAYMENTS: 'Monthly automatic recurring payments through credit card',
    MARK_FULLY_PAID: "Mark as fully Paid",
    PAYMENT_FOR_MONTH: 'Payment for month number: %{index} : ',

    MARK_ONE_TIME_FULL_PAYMENT_TOOLTIP: "Student paid a one time payment for the whole membership period.",
    MARK_MANUALLY_PAYMENTS_TOOLTIP: "Collect monthly payments from the student for the membership.",
    MARK_AUTO_PAYMENTS_TOOLTIP: "Start automatic monthly payments through credit card. Each month the student's credit card will be charged automatically until the monthly period of the membership ends.",

    SEND_PAYMENT_REQUEST_BOLD: "<b>Send a Payment Request</b>",
    SEND_PAYMENT_REQUEST: "Send a Payment Request",
    GENERAL_STUDENT_NOTE: "General Note"
  },
  studentsTable: {
    LEGEND: 'Legend:',
    FREQUENT: 'Frequent',
    FREQUENT_WITH_PLAN: 'Frequent with valid Plan',
    FREQUENT_NO_PLAN: 'Frequent but no valid Plan',
    FROZEN_TIP: 'Plan is Frozen',
    FREEZE: 'Freeze',
    UNFREEZE: 'Unfreeze',
    FROZEN: 'Frozen',
    ADD_BUTTON: 'Add Student',
    ADD_BUTTON_FROM_FOLLOWERS: 'Add a student from my students list',
    ADD_TO_SESSION: 'Add Student to Session',
    ADD_TO_PLAN: 'Add Student to Plan',
    ADD_TO_PRODUCT: 'Add Student to Product',
    REGULAR_REMOVE_STUDENT: 'Remove Regularly',
    NO_REFUND_REMOVE_STUDENT: 'Remove with No Refund',
    LABEL_NOTE: 'Student Note',
    LABEL_NOTE_DESC: '<b>Student Note:</b> %{note}',
    LABEL_STUDENT_NOTE: 'Note for %{name}',
    LABEL_STUDENT_NAME: 'Student Name',
    NEW_TEACHER_LABEL: 'Add Teacher',
    NEW_TEACHER_STUDIO_LABEL: 'Add Teacher to Studio',
    NEW_STUDENT_LABEL: 'Add Student',
    CLOSE_NEW_STUDENT_LABEL: 'Close Add New Student',
    NEW_STUDENT_PLACEHOLDER: 'Type an email address to add new student',
    ADD_STUDENT_PLACEHOLDER: 'Existing student name or email',
    NO_STUDENTS_SESSION: "No students enrolled to this session yet",
    NO_STUDENTS_IN_WAITING_LIST: "No students are in the waiting list yet",
    NO_STUDENTS_PLAN: "No students have purchased this plan yet",
    NO_STUDENTS_PRODUCT: "No students purchased this product yet",
    NO_STUDENTS: "You don't have any student yet",
    SEARCH_FOR_STUDENTS: "Use the search bar to find students in the studio",
    EXPIRATION_DATE: 'Expiration Date',
    ENTRIES: 'Entries',
    ENTRIES_USED: 'Entries Used',
    ENTRIES_LEFT: 'Entries Left',
    LABEL_PAID: 'Paid',
    STUDENT_STATUS: 'Arrival Status',
    STUDENT_REMOVE_CONFIRM_MSG: 'Are you sure you want to remove %{name}?',
    REINSTATE_STUDENT_FROM_ARCHIVE_CONFIRM_MSG: 'Make %{name} an active student in the studio?',
    FREQUENT_STUDENT_ADD_CONFIRM_MSG: 'Add %{name} to frequent students list?',
    FREQUENT_STUDENT_REMOVE_CONFIRM_MSG: 'Remove %{name} from frequent students list? This will remove student from all future instances of this session where the student is singed as an attender.',
    STUDENT_STATUS_CHANGE_CONFIRM_MSG: 'Changing the status of %{name} does not change the actual condition of the student in this session and was only meant to Log the ststus condition.',
    STUDENT_STATUS_CHANGE_DISABLED_CONFIRM_MSG: 'Changing the status of %{name} is NOT possible because this student is already in the %{status} state according to his actual attendance to the session.',
    REMOVE_STUDENT_IN_POLICY_RANGE: '<b>Please pay attention!</b> Right now we\'re outside of the allowed cancelation policy range defined by the studio. Do you want to remove the student without a refund of entry/membership/payment for this class? Or remove the student with a refund?',
    STUDENT_ALREADY_EXISTS: 'Student Already Exists',
    STUDENT_CANT_ADD_YOURSELF: 'Can\'t add yourself',
    SHOW_NO_ACTIVE_PLAN_STUDENTS: 'Show only students with no active plan',
    NOTES: 'Notes: ',
    INFO: 'Open Student\'s Info Page',
    INFO_TEACHER: 'Open Teacher\'s Info Page',
    TICKET: 'Show Student\'s Plans (Tickets & Memberships)',
    CHAT: 'Open Chat Page',
    REMOVE: 'Remove from the list',
    REMOVE_AND_ADD: 'Remove from the list and add to Students List',
    HEALTH: 'Health Statement Form',
    ADDED_STUDENT: 'Student was Added',
    ADDED_LEAD: 'Potential Client Added',
    SENT_ALERT: 'Sent Vacancy Alert',
    LEAD_STATUS: 'Status: %{status}',
    CONTACT_LEAD: 'Contact %{name}',
    CANCELLED: 'Future Payments Canceled',
    PLAN_EXPIRED: 'Plan Expired',
    SAVE_FILTER: 'Save Filter for Future Usages',
    SAVE_FILTER_TIP: 'Save your selected filters for next usages (will affect all of the tables with same information inside: Students, Session or Plans)',
  },
  sessionsTable: {
    ADD_BUTTON: 'Add Student',
    LABEL_NOTE: 'Session Note',
    LABEL_STUDENT_NAME: 'Student Name',
    LABEL_SESSION_NAME: 'Session',
    NEW_STUDENT_LABEL: 'Add Student',
    CLOSE_NEW_STUDENT_LABEL: 'Close Add New Student',
    NEW_STUDENT_PLACEHOLDER: 'Type an email address to add new student',
    NO_STUDENTS_SESSION: "No students enrolled to this session yet",
    NO_STUDENTS_PLAN: "No students have purchased this plan yet",
    NO_STUDENTS: "You don't have any student yet",
    EXPIRATION_DATE: 'Expiration Date',
    ENTRIES: 'Entries',
    ENTRIES_USED: 'Entries Used',
    ENTRIES_LEFT: 'Entries Left',
    LABEL_PAID: 'Paid',
    STUDENT_REMOVE_CONFIRM_MSG: 'Are you sure you want to remove %{name}?',
    STUDENT_REMOVE_UNREFUNDED_CONFIRM_MSG: 'Are you sure you want to remove %{name} from this class state of unrefunded in this class?',
    FIRST_REMOVE_PAID_STATE: 'First remove the paid status %{name} for this session before removing the student from this state in the class?'
  },
  billing: {
    BILLING_TITLE: 'Payments',
    PAYMENTS_REGISRTATION_TITLE: '<b>Accept Creditcard Payments</b>',
    ALREADY_REGISTERED_TO_PAYMENT: '<b>You are already registered to the payment system (PayMe)</b>',
    PAYMENTS_REGISRTATION_INFO: '<p>Accept credit card payments, this allows students to purchase studio Tickets/Memberships/Products through the app and you can also send payment requests via Medidate to your students.</p><p>&nbsp;</p><p><strong>Payment Fees:</strong></p><p>1. Transaction Fee&nbsp;(The Business Fee) - 1% + 1₪ per transaction&nbsp;</p><p>2. Medidate Fee (Your Client Fee) - 1% on top of the product\'s price, so for example a Ticket costs 100₪ the student will pay 101₪ (This Fee is from the student, and they are aware of it). In the Settings Page you can adjust a \"split\" between you and your client, for example: 0.5% from your client and 0.5% from your business.</p><p>&nbsp;</p><p><strong>**Note - In <u>Settings→Payments</u></strong> you can adjust the 1% (Medidate Fee) split between the student among you, for example: 0.5% for the student and 0.5% for your business.</p><p>From extensive experience with your clients, it\'s rare that this causes discomfort.</p><p>Alternatively, you can incorporate the entire 1% into the overall product price on your end. For instance, if a product costs 100₪ and you take the entire 1% for the student, simply reflect this in the product price of 101₪. This is also a viable approach.</p>',
    PAYMENTS_REGISRTATION_INFO_COMMISION: '*You can adjust the 1% (Medidate Fee) split between the student among you, for example: 0.5% for the student and 0.5% for your business.',
    PAYMENTS_REGISRTATION_INFO_COMMISION2: '*You can incorporate the entire 1% into the overall product price on your end. For instance, if a product costs 100₪ and you take the entire 1% for the student, simply reflect this in the product price of 101₪. This is also a viable approach.',
    PAYMENTS_REGISRTATION_INFO_VAT: '*The prices displayed are before VAT',
    PAYMENTS_REGISRTATION_USA_INFO: '<p>Accept credit card payments, this allows students to purchase studio Tickets/Memberships/Products through the app and you can also send payment requests via Medidate to your students.</p><p><strong>Payment Fees:</strong></p><p>1. Consumer Transaction Fee is&nbsp;(Business fee) - 2.5% + $0.3 per transaction (The Studio\'s Fee)</p><p>2. OR Corporate Transaction Fee is&nbsp;(Business fee)- 2.9% + $0.3 per transaction (The Studio\'s Fee)</p><p>3. Medidate Fee (Your client fee)- 1.2% on top of the product\'s price, so for example a Ticket costs $100 the student will pay $101.2 (This Fee is from your client and they are aware of it at checkout). In the Settings Page you can adjust a \"split\" between you and your client, for example: 0.5% from your client and 0.5% from your business.</p>',
    MUST_REGISTER_MESSAGE: '<p><span style="font-size:20px;"><strong>Enroll with Medidate to Accept Creditcard Payments</strong></span></p><p>In order to accept creditcard payments from your students you must enroll with Medidate.</p><p>We collect a 2% commision from each transaction, where the student pays a small commision as well on top of your product price.</p>',
    REGISTER_BUTTON: 'Set Up Billing',
    ICOUNT_TUTORIAL: 'iCount Tutorial',
    PAYME_TUTORIAL: 'PayMe Tutorial',
    PAYME_AGREEMENT: 'Agreement Form',
    PAYME_REGISTER: 'I\'m ready, Register to Payments',
    iCOUNT_REGISTER: 'I\'m ready, Register to iCount',
    OPEN_PAYME: 'Open PayMe (Payment Processor Dashboard)',
    REGISTER_TO_GROW_PAYMENTS: 'Register to Grow Payments System',
    PHONE_INPUT_ERROR: 'Phone number must start with 05 and have exactly 10 digits.',
    FULLNAME_INPUT_ERROR: 'Full name must be at least two words, each with at least two letters, separated by a single space.',
    COMPANYID_INPUT_ERROR: 'Company ID must be exactly 9 digits.',
    USERID_INPUT_ERROR: 'ID must be of numbers and characters',
    IS_REQUIRED: 'is required',
    PROS: 'Pros',
    CONS: 'Cons',
    GROW_TITLE: 'Grow (Meshulam)',
    GROW_DESCRIPTION: 'The Grow (Meshulam) payment system is user-friendly and includes auto receipts/invoices system.',
    GROW_PROS_1: 'Intuitive and easy-to-use system',
    GROW_PROS_2: 'Includes HanaHacash, no additional payment required',
    GROW_PROS_3: 'Accessible customer service, also available via WhatsApp',
    GROW_CONS_1: 'Cannot process payments from tourists, only Israeli citizens',
    GROW_EXISTING_USER: 'Connect Existing Grow User',
    GROW_TUTORIAL: 'Grow Tutorial',
    PAYME_TITLE: 'PayMe',
    PAYME_DESCRIPTION: 'A payment system that allows processing payments for tourists as well (First open all tutorials and start by connect to iCount and only then to PayMe)',
    PAYME_PROS_1: 'Can process payments from tourists abroad',
    PAYME_CONS_1: 'Requires connection to the additional iCount system for account management',
    PAYME_CONS_2: 'Requires an additional monthly payment for the iCount system',
    PAYME_CONS_3: 'Customer service available only via email, not highly accessible',
    CONNECT: 'Connect',
    OPEN: 'Open',
    REGISTER_TO_PAYME_POPUP_MESSAGE: 'We recommend first registering to the iCount service and only then to the payments service Payme. Please open the tutorials on the side and follow the steps.',
    REGISTER_TO_ICOUNT: 'Signup to iCount',
    REGISTER_TO_PAYME: 'Connect to PayMe',
    CONNECTED: 'Connected',
    PHONE_NUMBER_NEEDS_FIXING: 'Phone number is not valid.\nPlease enter the phone number and try again.'
  },
  splashMerchantTypes: {
    SOLE_PROP: 'Sole Proprietorship',
    CORPORATION: 'Corporation',
    LLC: 'LLC',
    PARTNERSHIP: 'Partnership',
    ASSOCIATION: 'Association',
    NON_PROFIT: 'Non Profit',
    GOVERNMENTAL: 'Governmental'
  },
  billingRegister: {
    TITLE: 'Your Billing Details',
    REDIRECTING: 'Redirecting to External Page...',
    REDIRECTING_INVOICE: 'Preparing document...',
    EXPLAIN_COMMISSION_PAYME: '<p><span style="font-size:20px;"><strong>You will receive your earnings through Medidate every 9th of each following month</strong></span></p>',
    LABEL_SOCIAL_ID: 'ID Number',
    LABEL_SOCIAL_ID_ISSUED: 'ID Issued Date',
    PLACEHOLDER_PHONE: 'Phone Number',
    PLACEHOLDER_COUNTRY: 'Country',
    PLACEHOLDER_CITY: 'Business Address',
    PLACEHOLDER_STREET: 'Street',
    PLACEHOLDER_STREET_NUMBER: 'Street Number',
    LABEL_WEBSITE: 'Website',
    LABEL_DESCRIPTION: 'Business Description',
    LABLE_SELLECR_INC: 'Business Type',
    LABEL_SELLER_INC_CODE: 'Business ID',
    LABLE_SELLECR_BANK_CODE: 'Bank Name',
    LABEL_SELLER_BANK_BRANCH: 'Bank Branch Nr.',
    LABEL_SELLER_BANK_ACCOUNT_NR: 'Bank Account Nr.',
    NEXT_STEP: 'Next Step',
    PAYME_PAPERS_EXPLANATION: 'As required by the Israeli law, we need to validate that you are a certified to receive payment from clients. In order to do so, we would like to ask you to submit 3 documents which prove your identity.',
    LABEL_FILE_SOCIAL_ID: 'Social ID Photo',
    LABEL_FILE_CHEQUE: 'Proof of bank account ownership photo',
    LABEL_FILE_CORPORATE: 'Incorporation document photo',
    SEND_DOCUMENTS_BTN: 'Send Documents',
    LABEL_BUSINESS_NAME: 'Business Name',
    SUBMIT_BTN: 'Submit',
    LABEL_ADDRESS: 'Address',
    LABEL_CITY: 'Business Address',
    LABLE_STATE: 'State',
    LABEL_ZIP: 'ZIP',
    LABEL_EIN: 'EIN (Federal tax id number)',
    LABEL_MERCHANT_TYPE: 'Business Type',
    LABEL_ROUTING: 'Bank Account Routing Nr.',
    LABEL_SPLASH_USERNAME: 'Splash Username',
    LABEL_SOCIAL_SECURITY_NUMBER: 'Social Securiry Nr.',
    PAYMENT_DASHBOARD_LABEL: 'Go to My Payment Dashboard',
    PAYMENT_DETAILS: 'Payment Details:',
    PAYMENT_REGISTERED_LABEL: 'Payments Registration Completed!',
    PAYMENT_REGISTERED: '<br>You’ve successfully registered with our payment service, allowing you to accept credit card payments from your students.<br>An email will be sent shortly to your inbox with credentials (username, password) allowing access to your payments dashboard.<br>Through the payments dashboard you will be able to monitor all transactions through Medidate.<br>Next time you choose “Billing and Payments” from the menu you will automatically be forwarded to the payments dashboard. <br>Thank you for choosing to manage your business with Medidate, we hope you find it easy and useful!<br><br>Namaste,<br>The Medidate Team<br>',
    SPLASH_USERNAME_EXPLANATION: 'In order to see your balance and withdraw funds, we would need to register you with Splash, our Payment provider. Detailed email about the process will be sent to your after the registration',
    TOC_TOGGLE: 'I accept Splash Playmets <a target="_blank" href="https://portal.splashpayments.com/terms">Terms and conditions</a>'
  },
  paymeIncTypes: {
    PERSON: 'Self Employed',
    BUSINESS: 'Company/Business'
  },
  plans: {
    MY_PLANS: 'My Plans',
    TICKETS_TABLE_TITLE: 'Tickets',
    TICKETS_ARCHIVED_TABLE_TITLE: 'Archived Tickets',
    MEMBERSHIPS_TABLE_TITLE: 'Memberships',
    MEMBERSHIPS_ARCHIVED_TABLE_TITLE: 'Archived Memberships',
    TICKET_NO_RESULTS: "You don't have any tickets yet.",
    MEMBERSHIP_NO_RESULTS: "You don't have any memberships yet.",
    NO_RESULTS_CTA: "Create first plan",
    PLAN_NAME: 'Title',
    PLAN_ENTRY_NR: 'Nr. of Entries',
    PLAN_ENTRY_NR_TOT: 'Nr. of Possible Entries',
    PLAN_ENTRY_PUNCHED: 'Punched',
    PLAN_ASSOCIATED_SESSIONS: 'Sessions',
    PLAN_STUDENTS: 'Students',
    PLAN_EXPIRATION_PREIOD: 'Expiration Period',
    PLAN_EXPIRATION_PREIOD_VALUE: '%{value} Weeks',
    PLAN_EXPIRATION_PREIOD_MONTH_VALUE: '%{value} Months',
    PLAN_PRICE: 'Price',
    PLAN_COLOR: 'Color',
    UNNAMED_PLAN: 'Unnamed Plan',
    PLAN_ACTIVATE: 'Activate Plan',
    PLAN_DEACTIVATE: 'Hide from Students',
    PLAN_DEACTIVATED: 'INVISIBLE TO STUDENTS',
    PLAN_ACTIVATED: 'VISIBLE TO STUDENTS',
    PLAN_ARCHIVE: 'Move Plan to Archive',
    PLAN_UNARCHIVE: 'Restore Plan from Archive',
    PLAN_ARCHIVED: 'Plan Archived',
    PLAN_UNARCHIVED: 'Plan Unarchived',
    EDIT_PLAN_BTN: 'Edit Plan',
    MORE_BTN: ' More ',
    STUDENTS_MODAL_TITLE: 'Students in %{title} plan <b>%{count}</b>',
    MUST_REGISTER_MESSAGE: '<p><span style="font-size:20px;"><strong>Setup Plans and Memberships for your Studio</strong></span></p><p>In order to set up plans and memberships, you must enroll with Medidate.</p><p>We collect a 2% commision from each transaction, where the student pays a small commision as well on top of your product price.</p>',
    RUNNING_TICKETS: 'Running Tickets',
    FROZEN_TICKETS: 'Frozen Tickets',
    EXPIRED_TICKETS: 'Expired Tickets',
    TICKETS_ISSUED: 'Tickets Issued',
    REMOVE_PLAN_CONFIRM_MSG: 'Are you sure you want to remove %{planName}?',
    REMOVE_PLAN: 'Remove Plan',
    CANCEL_PLAN_PAYMENTS: 'Cancel Future Payments',
    STUDENTS: 'Students',
    SUMMARY: 'Plans Status',
    SUMMARY_FINISHED_TICKETS_PUNCHES_LEFT: 'Finished Tickets with Punches Left <b>(%{count})</b>',
    SUMMARY_FINISHED_TICKETS_NO_PUNCHES_LEFT: 'Finished Tickets with No Punches Left <b>(%{count})</b>',
    SUMMARY_FROZEN_TICKETS: 'Frozen Tickets <b>(%{count})</b>',
    SUMMARY_FROZEN_MEMBERSHIPS: 'Frozen Memberships <b>(%{count})</b>',
    SUMMARY_UNPAID_TICKETS: 'Unpaid Tickets <b>(%{count})</b>',
    SUMMARY_UNPAID_MEMBERSHIPS: 'Unpaid Memberships <b>(%{count})</b>',
    SET_STUDENT_PLAN_TITLE: 'Add <b>%{name}</b> to a Plan:',
    SET_STUDENT_PLAN: 'Select Plan',
    SET_STUDENT_PLAN_FINISH: 'Added Student to Plan',
    UNREFUNDED_SESSIONS: 'Unrefdunded Sessions due to cancelation policy',
    REFUND: 'Refund',
    CONFIRM_REFUND: 'Student signed out of class within cancelation policy range, are you sure you want to refund this student (payment or ticket entry) ?',
    SEND_PAYMENT_REQUEST_TITLE: "Opening a Renewable Membership Requires the Student's Credit Card",
    SEND_PAYMENT_REQUEST: "Send a payment request for this plan.<br/>Your student will receive an email with the payment details and an option to pay with credit card.<br/><br/>*after they pay they will be added to this plan automatically",
    CANCEL_PAYMENTS: 'This will cancel any future payments for this membership and adjust the membership\'s expiration date accoding to the number of payments that were received.<br/>Do you wish to proceed?'
  },
  planEdit: {
    TITLE_EDIT: 'Edit %{planType}: %{title}',
    TITLE_ADD: 'New %{planType}',
    LABEL_TITLE: 'Title',
    LABEL_TITLE_TOOLTIP: 'The name of the Ticket which easily explains the nature of the ticket. Example:\”10 Entries Ticket Good for 3 Months\"',
    LABEL_MEMBERSHIP_TITLE_TOOLTIP: 'The name of the Membership which easily explains the nature of the membership. Example:\”2 Entries a week membership for 3 months\"',
    LABEL_ENTRIES_NUMBER: 'Number of entries',
    LABEL_ENTRIES_NUMBER_TOOLTIP: 'The number of entries the ticket is good for',
    LABEL_PURCHASE_LIMIT: 'Purchase limit',
    LABEL_PURCHASE_LIMIT_TOOLTIP: 'The number of times a specific individual can purchase this product',
    LABEL_PURCHASE_LIMIT_PLACEHOLDER: 'No limit',
    LABEL_EXPIRATION_PERIOD: 'Expiration Period (** Take notice if in Weeks or Months',
    LABEL_EXPIRATION_PERIOD_TOOLTIP: 'Expiration period of the ticket. Meaning, this ticket will allow access as long as it doesn’t expire and that the ticket still has entries available for the student. When opening a ticket to a student, the ticket will immediately have an expiration date according to the chosen expiration period which can be modified if needed',
    LABEL_MEMBERSHIP_EXPIRATION_PERIOD_TOOLTIP: 'Expiration period of the membership. Meaning, this membership will allow access as long as it doesn’t expire. When opening a membership to a student, the membership will immediately have an expiration date according to the chosen expiration period which can be modified if needed. You may choose Months or 4 week at a time, if needed you can always modify the expiration date of the newly opened membership',
    LABEL_PRICE: 'Price',
    LABEL_PRICE_TOOLTIP: 'The full price for this ticket',
    LABEL_MEMBERSHIP_PRICE_TOOLTIP: 'The monthly price of a membership. Example: If a 3 month membership costs a total of $150 then you should enter $50',
    LABEL_MONTHLY_PRICE: 'Monthly Price',
    LABEL_WEEKLY_ENTRIES: 'Weekly Entries Limit',
    LABEL_WEEKLY_ENTRIES_TOOLTIP: 'Limit the number of weekly entries this Membership allows access too. If set to 2, the stduent will be able to sign up to 2 classes in a given week and will restrict further signing in that same week',
    LABEL_WEEKLY_ENTRIES_PLACEHOLDER: 'Unlimited Weekly Entries',
    LABEL_MONTHLY_ENTRIES: 'Monthly Entries Limit',
    LABEL_MONTHLY_ENTRIES_TOOLTIP: 'Limitation of the subscription to a certain number of entries per month. In other words, if it\'s set to 4, then the student will be able to register with the subscription for 4 lessons per month. "Month" is defined according to whether the subscription is in months or weeks. If in months, then jumps of 30 days, and if in weeks, jumps of 4 weeks. By default, the limitation is according to the moment the subscription is purchased. For example, if I purchased a subscription on February 15th and it\'s valid until March 15th, then I will have 4 entries within this range. If you want the limitation to be according to the calendar month, in other words, in the case described before, if 4 entries are possible in February and another 4 in March, you will mark the option "Limit by Calendar Month" below.',
    LABEL_MONTHLY_ENTRIES_PLACEHOLDER: 'Unlimited Monthly Entries',
    LABEL_MONTHLY_ENTRIES_CALENDAR: 'Limit by calendar month',
    LABEL_MONTHLY_ENTRIES_CALENDAR_TOOLTIP: 'This option defines the limit of the number of monthly entries per calendar month. For example, let\'s say I set a limit of 4 entries per month, and a student purchases a subscription on February 15th which is valid until March 15th. Then, until February 28th, there will be 4 possible entries, and at the beginning of March, the student will have another 4 entries available.<br>On the other hand, if this option is turned off, between February 15th and March 15th, the student will have a total of 4 possible entries.',
    ENTRIES: 'Entries',
    SAVE_BTN: 'Save Changes',
    LABEL_COLOR: 'Color',
    NO_SESSION: "You don't have any upcoming sessions",
    SESSION_IN_PLAN: 'Add to Plan',
    SESSION_DATE: 'Session Date',
    SESSION_TITLE: 'Session Title',
    PLAN_SESSIONS_TITLE: 'Plan Sessions',
    PLAN_SESSIONS_TITLE_TOOLTIP: 'The future classes that this plan grants access too',
    PLAN_NOT_EDITABLE_NOTE: 'This Plan has students assigned to it so you can\'t edit it. You can only update "Enable Payments"',
    PLAN_NO_NEED_FOR_DROPIN: 'Don\'t create a Plan for a Dropin, you can set the paid status inside the class',
    LABEL_FREEZ_WEEK_LIMIT: 'Freeze User Plan and Unfreeze Automatically After:',
    LABEL_UNFREEZE_MANUALLY: 'Unfreeze User Plan Now?',
    LABEL_UNFROZEN_AUTO: 'Plan will be Unfrozen Automatically at <b>%{unfrozen_date}</b>',
    ALLOW_PAYMENTS: 'Allow in Payments',
    AUTO_RENEWS_MANAGE_ERROR: 'Automatic Payments cannot be managed manually',
    AUTO_RENEWS: 'Monthly Automatic Payments',
    AUTO_RENEWS_TOGGLE: 'Renews Automatically',
    AUTO_RENEWS_TOOLTIP: 'Plan will automatically renew after the expiration period is over (student will be charged)',
    AUTO_RENEWS_STUDIO_PAYMENTS: 'Available only when registered to accept credit card payments',
    AUTO_RENEWS_EXPIRATION_PERIOD: '4 Weeks with every renewal',
    START_DATE: 'Start Date',
    ALLOW_PAYMENTS_TOOLTIP: 'Allow the student to purchase this product in a number of installments you wish to enable. This will allow the student to choose between a regular payments to the number of payments you define here.',
    PAYMENTS_IN_MEMBERSHIP: '** Allow payments applies ONLY to ONE WHOLE payment for the membership using credit card'
  },
  products: {
    PRODUCTS_TABLE_TITLE: 'Studio Products',
    PRODUCT_ACTIVATED: 'Add on  the shelf',
    PRODUCT_DEACTIVATED: 'Remove from the shelf',
    REMOVE_PRODUCT_CONFIRM_MSG: 'Delete product?',
    PRODUCT_NAME: 'Name',
    PRODUCT_PRICE: 'Price',
    EDIT_PRODUCT_BTN: 'Edit',
    REMOVE_PRODUCT: 'Delete',
    PRODUCT_ACTIVATE: 'Put on the shelf',
    PRODUCT_DEACTIVATE: 'Remove from the shelf',
    PRODUCT_NO_RESULTS: 'No products were added yet',
    NO_RESULTS_CTA: 'Creat your first product',
    STUDENTS: 'Students',
    MORE_BTN: ' More ',
    STUDENTS_MODAL_TITLE: 'Number of Students who bought %{title} product <b>%{count}</b>',
    PRODUCT_SOLD_COUNT: 'Sold Amount',
    NEED_TO_BE_A_SELLER: 'You need to be registered to the payment system in order to manage students in products',
    NEED_TO_BE_A_SELLER_URL: 'In order to assign products to students you need to register to the payment system. You can register <a target="_blank" href="https://medidate.paymeservice.com/system/kyc/signup/646e1760a49aee0014c5f7af">here</a> '
  },
  productEdit: {
    TITLE_EDIT: 'Edit %{productType}: %{title}',
    TITLE_ADD: 'New %{productType}',
    LABEL_TITLE: 'Title',
    LABEL_TITLE_TOOLTIP: 'The name of the Product which easily explains the nature of the product.',
    LABEL_PRICE: 'Price',
    LABEL_PRICE_TOOLTIP: 'Product Price',
    PRODUCT_NOT_EDITABLE_NOTE: 'You are unable to edit this product because it was already purchased',
    SAVE_BTN: 'Save',
    LABEL_COLOR: 'Color',
    PRODUCY_NOT_EDITABLE_NOTE: 'Unable to edit this product because students have already purchased it',
    ALERT_ADD_IMAGE: 'Please add an image to the product',
    ALERT_ADD_TITLE: 'Please enter product title',
    ALERT_ADD_PRICE: 'Please add a price to this product'

  },
  messages: {
    TITLE: 'Messages',
    CHAT_YOU: 'You',
    NO_SESSION_RESULTS: 'You have no upcoming sessions',
    NAV_ALL_STUDENTS: 'All Students',
    NAV_PRIVATE_CHATS: 'Private Chats',
    NAV_SESSION_CHATS: 'Session Chats',
    NAV_STUDIO_TEACHERS: 'Studio Teachers',
    PLACEHOLDER_MESSAGE: 'Type a message',
    CHAT_USER_NO_REUSLTS: 'No users found',
    USERS_SEARCH_PLACEHOLDER: 'Type an email address',
    USERS_SEARCH_NO_RESULTS: 'No users found',
    FOLLOWERS_SUFFIX: 'Students',
    SUBJECT: 'Subject',
    MESSAGE: 'Your Message',
    MESSAGE_SENT: 'Thank you! We will contact you ASAP..',
  },
  reports: {
    TOTAL_STUDENTS: 'Total Students',
    TOTAL_STUDENTS_TOOLTIP: 'The total students of this studio',
    TOTAL_CLASSES: 'Total Classes',
    TOTAL_CLASSES_TOOLTIP: 'The total amount of classes/sessions that took place in the chosen month, canceled classes not included.',
    TOTAL_CANCELED_CLASSES: 'Total Canceled Classes',
    TOTAL_CANCELED_CLASSES_TOOLTIP: 'The total amount of canceled classes/sessions in the chosen month',
    TOTAL_ACTIVE_CLASSES: 'Total Active Classes',
    TOTAL_ACTIVE_CLASSES_TOOLTIP: 'The total amount of active classes/sessions in the chosen month',
    TOTAL_ATTENDERS: 'Total Attendees',
    TOTAL_ATTENDERS_TOOLTIP: 'The total attendance during the chosen month',
    TOTAL_UNIQUE_ATTENDERS: 'Total Unique Attendees',
    TOTAL_UNIQUE_ATTENDERS_TOOLTIP: 'The total amount of students who practiced in the chosen month. Meaning the total attendance is summed out of the practice of these students',
    SESSION_ATTENDERS: 'Attendees by Sessions',
    SESSION_ATTENDERS_TOOLTIP: 'Showing attendance according to the classes that took place in the chosen month',
    SESSION: 'Session',
    SHOW: 'Show',
    STUDIO_ACTIVITY: 'Studio Activity',
    PAYMENTS_ACTIVITY: 'Payments Activity',
    TEACHERS_ACTIVITY: 'Teachers Activity',
    TEACHERS_ACTIVITY_TOOLTIP: 'Showing attendance according to the teachers who taught in the chosen month',
    TEACHERS_ACTIVITY_NUMBERS: '<b>%{name}</b> taught <b>(%{sessionsCount})</b> classes with a total of <b>(%{attendersCount})</b> attendees',
    STUDENTS_ACTIVITY: 'Students Activity',
    STUDENTS_ACTIVITY_TOOLTIP: 'Showing all student activity in the chosen month',
    STUDENTS_ACTIVITY_MAINTENANCE: 'Students Activity Feature will return soon',
    STUDENTS_ACTIVITY_MAINTENANCE_TOOLTIP: 'This feature needs some maintenance and will return as soom as possible',
    RECIEVED_BY: 'Amount Received By',
    RECIEVED_BY_TOOLTIP: 'Showing the amounts that were received by the teachers and studio in the chosen month',
    TOTAL_AMOUNT_RECEIVED: 'Total Amount Received',
    TOTAL_AMOUNT_RECEIVED_TOOLTIP: 'The total amount that was accepted in the chosen month. This does not include amount received from Memberships that are paid using credit card, this will be included as well soon',
    TOTAL_TRIAL_SESSIONS: 'Total Trial Sessions',
    TOTAL_TRIAL_SESSIONS_TOOLTIP: 'The number of students who were in a trial class/session in the chosen month',
    AMOUNT_RECIEVED_FOR_SESSIONS: 'Amount Received for Sessions',
    AMOUNT_RECIEVED_FOR_SESSIONS_TOOLTIP: 'The amount received from dropin payments only',
    AMOUNT_RECIEVED_FOR_TRIAL_SESSIONS: 'Amount Received for Trial Sessions',
    AMOUNT_RECIEVED_FOR_TRIAL_SESSIONS_TOOLTIP: 'The amount received from trial classes/sessions in the chosen month',
    AMOUNT_RECIEVED_FOR_TICKETS: 'Amount Received for Tockets',
    AMOUNT_RECIEVED_FOR_TICKETS_TOOLTIP: 'The amount received from tickets in the chosen month. The amount will also include payments for Tickets opened in prior months that only this month were marked as \"paid for\". Therefor do not assume a perfect correlation between newly opened tickets this month and the total amount received from tickets.',
    AMOUNT_RECIEVED_FOR_MEMBERSHIPS: 'Amount Received for Memberships',
    AMOUNT_RECIEVED_FOR_MEMBERSHIPS_TOOLTIP: 'The amount received from memberships in the chosen month. The amount will also include payments for Tickets opened in prior months that only this month were marked as \"paid for\". Therefor do not assume a perfect correlation between newly opened memberships this month and the total amount received from memberships. Also ongoing memberships that still have payments for this chosen month will also be included in the total amount from memberships.',
    AMOUNT_RECIEVED_FOR_PRODUCTS: 'Amount Received for Products',
    AMOUNT_RECIEVED_FOR_PRODUCTS_TOOLTIP: 'The amount received from products in the chosen month',
    AMOUNT_RECIEVED_FOR_FREE_AMOUNT: 'Amount Received from donations or arbitrary amounts',
    AMOUNT_RECIEVED_FOR_FREE_AMOUNT_TOOLTIP: 'The amount received from donations or arbitrary amounts in the chosen month',
    NUMBER_OF_NEW_TICKETS: 'Number of New Tickets',
    NUMBER_OF_NEW_TICKETS_TOOLTIP: 'The number of tickets that were given in the chosen month',
    NUMBER_OF_NEW_MEMBERSHIPS: 'Number of New Memberhips',
    NUMBER_OF_NEW_MEMBERSHIPS_TOOLTIP: 'The number of memberships that started in the chosen month',
    EXPIRES_ON: 'expires on',
    BEST_PERFORMING_SESSIONS: 'Best Performing Sessions',
    BEST_PERFORMING_SESSIONS_TOOLTIP: 'Showing the highest performing classes attendance wise in the chosen month. Calculated according to 80% attendance and above from the highest attendance in the chosen month',
    LEAST_PERFORMING_SESSIONS: 'Least Performing Sessions',
    LEAST_PERFORMING_SESSIONS_TOOLTIP: 'Showing the lowest performing classes attendance wise in the chosen month. Calculated according to 20% attendance and lower from the highest attendance in the chosen month',
    LABEL_PAID: 'Paid',
    PRINT: 'Print',
    ACCURACY_DISCLAIMER: 'Reports and Summaries are meant to give an overall status picture for a chosen month and the accuracy could vary for numerous reasons',

    SHOW_DEBT_STUDENTS: 'Show students that are in debt (for a class/session or plan)',
    SHOW_DEBT_STUDENTS_TOOLTIP: 'Filtering the students that attended in the chosen month and showing those who are in debt for a class ticket or membership',
    SHOW_TRIAL_STUDENTS: 'Show students that had a trial class during chosen period',
    SHOW_TRIAL_STUDENTS_TOOLTIP: 'Showing only students who came for the first time and had a trial session during the chosen time period',
    SHOW_UNREFUNDED_STUDENTS: 'Show students left class during cacenlation policy without a refund',
    SHOW_UNREFUNDED_STUDENTS_TOOLTIP: 'Showing only students who left class during cancelation policy and did not get a refund for that class',
    SHOW_DORMANT_STUDENTS: 'Show dormant students',
    SHOW_DORMANT_STUDENTS_TOOLTIP: 'Filtering the students that attended in the chosen month and showing those who are dormant, meaning haven’t arrived to class in a few weeks (according to the weeks number you choose)',
    SHOW_NEW_DORMANT_STUDENTS: 'Show new dormant students',
    SHOW_NEW_DORMANT_STUDENTS_TOOLTIP: 'Filtering the students that attended in the chosen month and showing those who were in a trial class/session and dormant, meaning haven’t arrived to class in a few weeks (according to the weeks number you choose',

    SHOW_DEBT_STUDENTS_TITILE: 'Show only the students that haven\'t paid in full for all classes/sessions/workshops/plans',
    SHOW_DORMANT_STUDENTS_TITILE: 'Show only students that haven\'t arrived for class in the chosen period (in weeks)',
    SHOW_NEW_DORMANT_STUDENTS_TITILE: 'Show only NEW (Trial Session) students that haven\'t arrived for class in the chosen period (in weeks)',
    STUDENT_ACTIVITY_ROW_TITLE: '<b>%{name}</b> attended <b>(%{number})</b> classes <%{email}>',
    UNREFUNDED_SESSION: 'Unrefunded Session',
    DATE_RANGE: 'Date Range',
    DATE_RANGE_ALERT: 'Date range must be a maximum of 90 Days',
    INSIGHTS_PROMOTION: 'Our "Insights" feature showcases vital business data via a user-friendly interface, as simple as always.\nIt condenses key metrics, enabling informed decisions and streamlined operations within your studio.\nYou can downloa and send each insight as an email at the end of loading.',
    INSIGHTS_BUTTON: 'Show me my Insights',
    INSIGHTS_INSIGHTS: 'Insights',
    INSIGHTS_SUGGESTIONS: 'Suggestions',
    INSIGHTS_SUGGESTIONS_TOOLTIP: 'Suggestions for the Insights feature, you may use @ sign to autofill some of the options during your explanation',
    INSIGHTS_SUMMARY: 'Summary',
    INSIGHTS_SUMMARY_TYPE: 'Summary Type',
    INSIGHTS_SUMMARY_CRITERIA: 'Summary Criteria',
    SUGGESTION_SUBTITLE: 'Insight feature is intended to get relevant information about your studio\'s performance and health\nThe current version is a beta version and we would love to hear your suggestions for improvement!\n\nSo please suggest an Insight to be added in future versions, this could be added faster then you think :)',
    SUGGESTION_SUBJECT: 'Insight Suggestion Subject',
    SUGGESTION_SUBJECT_TOOLTIP: 'The subject of your suggestion, you may use @ sign to autofill some of the options during your explanation',
    SUGGESTION_SUBJECT_PLACEHOLDER: 'Insight Suggestion about Sessions',
    SUGGESTION_MESSAGE: 'Insight Suggestion Description',
    SUGGESTION_MESSAGE_TOOLTIP: 'The description of your suggestion, you may use @ sign to autofill some of the options during your explanation',
    SUGGESTION_MESSAGE_PLACEHOLDER: 'I would like to suggest an improvement for the Insights feature',
    EXPORT_EMAIL: "Send Excel file by email",
    EXPORT_TO_EXCEL: 'Export to Excel',
    EXPORT_TO_EXCEL_SUCCESS: 'Email with Excel file was sent successfully!'
  },
  months: {
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARCH: 'March',
    APRIL: 'APRIL',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December'
  },
  footer: {
    NEED_HELP: 'Need Help?',
    CONTACT_US: 'Contact Us',
    MEDIDATE_COMP: 'Appum Studios LTD'
  },
  paymentSuccessPage: {
    THANKS_MESSAGE: 'Payment for %{name} Received Successfully!'
  },
  helpPopup: {
    PLAN_MANAGEMENT_TUT: '<p><span style="font-size:20px;"><strong>Plan how to manage you studio : The 4 ways to use the Medidate Platform</strong></span></p>',
    CREATE_STUDIO_SCHEDULE_TUT: '<p><span style="font-size:20px;"><strong>Create the Studio\'s schedule</strong></span></p>',
    ADD_REMOVE_FIND_TUT: '<p><span style="font-size:20px;"><strong>Add/Remove/Find students in the Studio</strong></span></p>',
    MANAGE_STUDENTS_CLASSES_TUT: '<p><span style="font-size:20px;"><strong>Manage students and classes</strong></span></p>',
    CREATE_STUDIO_PLANS_TUT: '<p><span style="font-size:20px;"><strong>Create Studio Plans (Tickets & Memberships)</strong></span></p>',
    MANAGE_STUDIO_PLANS_TUT: '<p><span style="font-size:20px;"><strong>Manage Studio Plans for Students</strong></span></p>',
    STUDIO_CONFIGURATION_TUT: '<p><span style="font-size:20px;"><strong>Studio configuration and planning</strong></span></p>',
    FIRST_SIGNUP_CONFIGURATION_TUT: '<p><span style="font-size:20px;"><strong>First signup configuration</strong></span></p>',
    SUMMARIES_TUT: '<p><span style="font-size:20px;"><strong>Summaries and Reports walkthrough</strong></span></p>',
    ADD_NEW_STUDENT_TUT: '<p><span style="font-size:20px;"><strong>Add Student To Studio Tutorial</strong></span></p>',
    CREATE_NEW_SESSION_TUT: '<p><span style="font-size:20px;"><strong>Create a New Sessin Tutorial</strong></span></p>',
    MANAGE_ARRIVALS_TUT: '<p><span style="font-size:20px;"><strong>Manage Class Arrivals Tutorial</strong></span></p>',
    CREATE_NEW_PLAN_TUT: '<p><span style="font-size:20px;"><strong>Create a New Plan Tutorial</strong></span></p>',
    ASSIGN_PLAN_TO_STUDENT_TUT: '<p><span style="font-size:20px;"><strong>Assign Plan to Student Tutorial</strong></span></p>',
    STUDENT_PAGE_TUT: '<p><span style="font-size:20px;"><strong>Student Info Page</strong></span></p>',
    ONBOARDING_TUT: '<p><span style="font-size:20px;"><strong>Onboarding, set the current studio status into Medidate</strong></span></p>',
    VIDEO_SELECT: 'Select Video',
    VIDEO_SELECTED: 'Selected Video:',
  },
  tutorials: {
    PLAN_MANAGEMENT_TUT: 'Plan how to manage you studio : The 4 ways to use the Medidate Platform',
    CREATE_STUDIO_SCHEDULE_TUT: 'Create the Studio\'s schedule',
    ADD_REMOVE_FIND_TUT: 'Add/Remove/Find students in the Studio',
    MANAGE_STUDENTS_CLASSES_TUT: 'Manage students and classes',
    CREATE_STUDIO_PLANS_TUT: 'Create Studio Plans (Tickets & Memberships)',
    MANAGE_STUDIO_PLANS_TUT: 'Manage Studio Plans for Students',
    CREATE_STUDIO_PLANS_TICKET_TUT: 'Create Studio Punch Ticket',
    CREATE_STUDIO_PLANS_MEMBERSHIP_TUT: 'Create Studio Membership',
    MANAGE_STUDIO_PLANS_TICKET_TUT: 'Manage Studio Punch Ticket',
    MANAGE_STUDIO_PLANS_MEMBERSHIP_TUT: 'Manage Studio Membership',
    STUDIO_CONFIGURATION_TUT: 'Studio configuration and planning',
    FIRST_SIGNUP_CONFIGURATION_TUT: 'First signup configuration',
    SUMMARIES_TUT: 'Summaries and Reports walkthrough',
    ADD_NEW_STUDENT_TUT: 'Add Student To Studio Tutorial',
    CREATE_NEW_SESSION_TUT: 'Create a New Sessin Tutorial',
    MANAGE_ARRIVALS_TUT: 'Manage Class Arrivals Tutorial',
    CREATE_NEW_PLAN_TUT: 'Create a New Plan Tutorial',
    ASSIGN_PLAN_TO_STUDENT_TUT: 'Assign Plan to Student Tutorial',
    STUDENT_PAGE_TUT: 'Student Info Page',
    ONBOARDING_TUT: 'Onboarding, set the current studio status into Medidate',
    VIDEO_SELECT: 'Select Video',
    VIDEO_SELECTED: 'Selected Video:',
  },
  insights: {
    INSIGHT_1_X_1_TITLE: 'Active Students',
    INSIGHT_1_Y_1_TITLE: 'Dormant Students',
    INSIGHT_1_X_2_TITLE: 'Students with Plan',
    INSIGHT_1_Y_2_TITLE: 'Students without Plan',
    INSIGHT_1_X_3_TITLE: 'New Students',
    INSIGHT_1_Y_3_TITLE: 'Last Year',
    INSIGHT_2_X_1_TITLE: 'Paid Sessions',
    INSIGHT_2_Y_1_TITLE: 'Unsattled Sessions',
    INSIGHT_2_X_2_TITLE: 'Sessions with non-refundable (late cancellation students)',
    INSIGHT_2_Y_2_TITLE: 'Sessions with canceled students',
    INSIGHT_2_X_3_TITLE: 'Sessions',
    INSIGHT_2_Y_3_TITLE: 'Last Year',
    INSIGHT_3_X_1_TITLE: 'Active Plans',
    INSIGHT_3_Y_1_TITLE: 'Expired Plans',
    INSIGHT_3_X_2_TITLE: 'Memberships nearing completion',
    INSIGHT_3_Y_2_TITLE: 'Punch Tickets nearing completion',
    INSIGHT_3_X_3_TITLE: 'Plans',
    INSIGHT_3_Y_3_TITLE: 'Last Year',
    INSIGHT_1_SECTION: 'Students',
    INSIGHT_1_SECTION_ALT: 'All Students',
    INSIGHT_1_SECTION_ALT_2: 'All Paying Students',
    INSIGHT_1_SECTION_ALT_3: 'Compared to previous period',
    INSIGHT_1_TYPE_1: 'Students who did not arrive within the date range',
    INSIGHT_1_TYPE_1_COUNTER: 'Students arrived within the date range',
    INSIGHT_1_TYPE_2: 'Students who do not have an active plan within the date range',
    INSIGHT_1_TYPE_2_COUNTER: 'Students with active plan within the date range',
    INSIGHT_1_TYPE_3: 'New students enrolled in the studio in a date range',
    INSIGHT_1_TYPE_3_COUNTER: 'Rest of Students',
    INSIGHT_1_TYPE_4: 'New students who were in a trial class within a date range',
    INSIGHT_1_TYPE_4_COUNTER: 'Students who were in a Paid class within a date range',
    INSIGHT_2_SECTION: 'Sessions',
    INSIGHT_2_SECTION_ALT: 'All Sessions within a date range',
    INSIGHT_2_TYPE_1: 'Sessions that have not been settled yet',
    INSIGHT_2_TYPE_1_COUNTER: 'Paid Sessions that have been settled',
    INSIGHT_2_TYPE_2: 'Sessions with non-refundable (late cancellation students)',
    INSIGHT_2_TYPE_2_COUNTER: 'Sessions with students',
    INSIGHT_2_TYPE_3: 'Sessions paid but no receipt or invoice issued for them',
    INSIGHT_2_TYPE_3_COUNTER: 'Paid Sessions with receipt or invoice issued',
    INSIGHT_3_SECTION: 'Plans',
    INSIGHT_3_SECTION_ALT: 'All Plans',
    INSIGHT_3_SECTION_ALT_2: 'All Memberships',
    INSIGHT_3_SECTION_ALT_3: 'All Punch Tickets',
    INSIGHT_3_TYPE_1: 'Plans that have not been settled yet',
    INSIGHT_3_TYPE_1_COUNTER: 'Plans that have been settled',
    INSIGHT_3_TYPE_2: 'Memberships nearing completion',
    INSIGHT_3_TYPE_2_COUNTER: 'Active Memberships',
    INSIGHT_3_TYPE_3: 'Punch Tickets nearing completion',
    INSIGHT_3_TYPE_3_COUNTER: 'Active Punch Tickets',
    INSIGHT_3_TYPE_4: 'Expired Memberships',
    INSIGHT_3_TYPE_4_COUNTER: 'Active Memberships',
    INSIGHT_3_TYPE_5: 'Finished Punch Tickets',
    INSIGHT_3_TYPE_5_COUNTER: 'Active Punch Tickets',
    INSIGHT_RATIO_0: 'Ratio is Good',
    INSIGHT_RATIO_1_25: 'Ratio is Bad',
    INSIGHT_RATIO_25_50: 'Ratio is Not Good',
    INSIGHT_RATIO_50_75: 'Ratio is Very Good',
    INSIGHT_RATIO_75: 'Ratio is Excellent',
  },
  calendar: {
    SHOW_CANCELED_SESSIONS: 'Show canceled classes and sessions',
    SHOW_CANCELED_SESSIONS_TOOLTIP: 'Show canceled classes and sessions on the calendar',
    ALL_SESSIONS_ARE_SETTELED: 'All sessions are setteled this week',
    NO_SUCH_SESSIONS: 'No such sessions this week'
  },
  productExternal: {
    LEAVE_AND_REFUND: '<b>Would you like to leave this session and refund the amount?<br/>You will receive a refund based on Medidate\'s Refund Policy.</b>',
    LEAVE_AND_REFUND_YES: 'Leave and Refund please',
    NO_THANKS: 'No thanks',
    PURCHASE_DROPIN_OR_PLANS: 'Purchase dropin? Or show relevant plans(memberships/entry tickets)?',
    PURCHASE_DROPIN: 'Purchase Dropin',
    SHOW_PLANS: 'Show Plans',
    USE_EXISTING_CREDIT_CARD_OR_DIFFERENT: 'Use Existing credit card? or use a Different card?',
    USE_EXISTING_CREDIT_CARD: 'Use existing',
    DIFFERENT_CREDIT_CARD: 'Use different card',
    PAST_SESSION: 'This session has passed',
    PAYMENT_WENT_THROUGH: 'Payment went through',
    PURCHASE_SUCCESSFUL: 'Successfuly purchased this %{className}',
    OK: 'Ok',
    UNDERSTOOD_LEAVE_ANYWAY: 'I understand, leave anyway',
    ADD_TO_WAITING_LIST: 'Yes, enter waiting list please',
    STILL_NO_ROOM: 'Still no room in this class, and you\'re already in the waiting list',
    IN_WAITING_LIST: 'You\'re currently in the waiting list',
    GO_TO_FORM: 'Go to form'
  },
  countries: [
    { value: "AF", label: "Afghanistan" },
    { value: "AX", label: "Aland Islands" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Algeria" },
    { value: "AS", label: "American Samoa" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarctica" },
    { value: "AG", label: "Antigua and Barbuda" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaijan" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Bahrain" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Belarus" },
    { value: "BE", label: "Belgium" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BT", label: "Bhutan" },
    { value: "BO", label: "Bolivia" },
    { value: "BQ", label: "Bonaire}, Sint Eustatius and Saba" },
    { value: "BA", label: "Bosnia and Herzegovina" },
    { value: "BW", label: "Botswana" },
    { value: "BV", label: "Bouvet Island" },
    { value: "BR", label: "Brazil" },
    { value: "IO", label: "British Indian Ocean Territory" },
    { value: "BN", label: "Brunei Darussalam" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "KH", label: "Cambodia" },
    { value: "CM", label: "Cameroon" },
    { value: "CA", label: "Canada" },
    { value: "CV", label: "Cape Verde" },
    { value: "KY", label: "Cayman Islands" },
    { value: "CF", label: "Central African Republic" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Christmas Island" },
    { value: "CC", label: "Cocos (Keeling) Islands" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoros" },
    { value: "CG", label: "Congo" },
    { value: "CD", label: "Congo}, Democratic Republic of the Congo" },
    { value: "CK", label: "Cook Islands" },
    { value: "CR", label: "Costa Rica" },
    { value: "CI", label: "Cote D'Ivoire" },
    { value: "HR", label: "Croatia" },
    { value: "CU", label: "Cuba" },
    { value: "CW", label: "Curacao" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Czech Republic" },
    { value: "DK", label: "Denmark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominican Republic" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egypt" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Equatorial Guinea" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "ET", label: "Ethiopia" },
    { value: "FK", label: "Falkland Islands (Malvinas)" },
    { value: "FO", label: "Faroe Islands" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finland" },
    { value: "FR", label: "France" },
    { value: "GF", label: "French Guiana" },
    { value: "PF", label: "French Polynesia" },
    { value: "TF", label: "French Southern Territories" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Germany" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Greece" },
    { value: "GL", label: "Greenland" },
    { value: "GD", label: "Grenada" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GG", label: "Guernsey" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haiti" },
    { value: "HM", label: "Heard Island and McDonald Islands" },
    { value: "VA", label: "Holy See (Vatican City State)" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hungary" },
    { value: "IS", label: "Iceland" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IR", label: "Iran}, Islamic Republic of" },
    { value: "IQ", label: "Iraq" },
    { value: "IE", label: "Ireland" },
    { value: "IM", label: "Isle of Man" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italy" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japan" },
    { value: "JE", label: "Jersey" },
    { value: "JO", label: "Jordan" },
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "KP", label: "Korea}, Democratic People's Republic of" },
    { value: "KR", label: "Korea}, Republic of" },
    { value: "XK", label: "Kosovo" },
    { value: "KW", label: "Kuwait" },
    { value: "KG", label: "Kyrgyzstan" },
    { value: "LA", label: "Lao People's Democratic Republic" },
    { value: "LV", label: "Latvia" },
    { value: "LB", label: "Lebanon" },
    { value: "LS", label: "Lesotho" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libyan Arab Jamahiriya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "MO", label: "Macao" },
    { value: "MK", label: "Macedonia}, the Former Yugoslav Republic of" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malaysia" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MQ", label: "Martinique" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexico" },
    { value: "FM", label: "Micronesia}, Federated States of" },
    { value: "MD", label: "Moldova}, Republic of" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolia" },
    { value: "ME", label: "Montenegro" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Morocco" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Myanmar" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Netherlands" },
    { value: "AN", label: "Netherlands Antilles" },
    { value: "NC", label: "New Caledonia" },
    { value: "NZ", label: "New Zealand" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Niger" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk Island" },
    { value: "MP", label: "Northern Mariana Islands" },
    { value: "NO", label: "Norway" },
    { value: "OM", label: "Oman" },
    { value: "PK", label: "Pakistan" },
    { value: "PW", label: "Palau" },
    { value: "PS", label: "Palestinian Territory}, Occupied" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua New Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PH", label: "Philippines" },
    { value: "PN", label: "Pitcairn" },
    { value: "PL", label: "Poland" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Qatar" },
    { value: "RE", label: "Reunion" },
    { value: "RO", label: "Romania" },
    { value: "RU", label: "Russian Federation" },
    { value: "RW", label: "Rwanda" },
    { value: "BL", label: "Saint Barthelemy" },
    { value: "SH", label: "Saint Helena" },
    { value: "KN", label: "Saint Kitts and Nevis" },
    { value: "LC", label: "Saint Lucia" },
    { value: "MF", label: "Saint Martin" },
    { value: "PM", label: "Saint Pierre and Miquelon" },
    { value: "VC", label: "Saint Vincent and the Grenadines" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome and Principe" },
    { value: "SA", label: "Saudi Arabia" },
    { value: "SN", label: "Senegal" },
    { value: "RS", label: "Serbia" },
    { value: "CS", label: "Serbia and Montenegro" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapore" },
    { value: "SX", label: "St Martin" },
    { value: "SK", label: "Slovakia" },
    { value: "SI", label: "Slovenia" },
    { value: "SB", label: "Solomon Islands" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "South Africa" },
    { value: "GS", label: "South Georgia and the South Sandwich Islands" },
    { value: "SS", label: "South Sudan" },
    { value: "ES", label: "Spain" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Suriname" },
    { value: "SJ", label: "Svalbard and Jan Mayen" },
    { value: "SZ", label: "Swaziland" },
    { value: "SE", label: "Sweden" },
    { value: "CH", label: "Switzerland" },
    { value: "SY", label: "Syrian Arab Republic" },
    { value: "TW", label: "Taiwan}, Province of China" },
    { value: "TJ", label: "Tajikistan" },
    { value: "TZ", label: "Tanzania}, United Republic of" },
    { value: "TH", label: "Thailand" },
    { value: "TL", label: "Timor-Leste" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad and Tobago" },
    { value: "TN", label: "Tunisia" },
    { value: "TR", label: "Turkey" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TC", label: "Turks and Caicos Islands" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukraine" },
    { value: "AE", label: "United Arab Emirates" },
    { value: "GB", label: "United Kingdom" },
    { value: "US", label: "United States" },
    { value: "UM", label: "United States Minor Outlying Islands" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Viet Nam" },
    { value: "VG", label: "Virgin Islands}, British" },
    { value: "VI", label: "Virgin Islands}, U.s." },
    { value: "WF", label: "Wallis and Futuna" },
    { value: "EH", label: "Western Sahara" },
    { value: "YE", label: "Yemen" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" }
  ]
}